import { Injectable } from '@angular/core';
import { QuestionAnswer, QuestionOption, ResponseContainer } from '../modal/questionnaire.models';
import { workInProgress } from './routing.models';
import { Auth0User } from './user.models';
@Injectable()
export class DAO {
    public DOTList: ResponseContainer | any;
    public MenuSavedDOTList: any;
    public auth0User1: Auth0User;
    public PolicyTemplate: string = "";
    public PolicySubTemplate: string = "";
    public IsAddDOT: boolean = false;
    public isFromKarma: boolean = false;

    public initialProductQuestionnaireAnswers: any;

    public IsValidUW: boolean = true;
    public loginUserDetail: any;
    public DOTLookupStatusMsg: any[] = [];
    public IsValidationCheck = false;
    public isDOTValid = true;

    public LoginModal: any = {};
    public RegistrationModal: RegistrationModal = new RegistrationModal();
    public SubmissionList: Array<workInProgress> = [];
    public vehicleModel: VehicleModel = new VehicleModel(); //Vehicle Screen
    public initialvehicleModel: VehicleModel = new VehicleModel();
    public userCompany: string = 'BSB';
    public userMail: string = '';
    public userAuthtoken: string = '';
    public userFullName: string;
    public toastValidation: string = 'Please fill required fields highlighted in red.';
    public businessAddressValidation: string = 'Business Owner email address is required, please add Email Contact';
    public deletedIndex: number = 0;
    public deletedItem: any = {};
    public PowerUnitsSize: string = '1';
    public isTrailer: boolean = false;
    public finalSubmitPage: string = ''
    public navigatePage: string = ''
    public ProductModel: ProductModel = new ProductModel(); //Product Scren
    public applicationInfoModel: ApplicationInfoModel = new ApplicationInfoModel();   //Application Screen 
    public initialProductModel: ProductModel = new ProductModel();
    public AddProductAnotherLocation: any = [];
    public AddAnotherInsured: any = [];
    public AddAnotherWaiver: any = [];
    public PremiseStateValidation: string = '';
    public AddAnotherInsuredForCommercialAuto: any = [];
    public AddAnotherWaiverForCommercialAuto: any = [];
    public initialAddProductAnotherLocation: any = [];
    public initialAddAnotherInsured: any = [];
    public initialAddAnotherWaiver: any = [];
    public initialAddAnotherInsuredForCommercialAuto: any = [];
    public initialAddAnotherWaiverForCommercialAuto: any = [];    

    public isClickFromMenu: boolean = false;
    public isSubmitForm: boolean = false;
    public ClickedPageFromMenuPage: string = ''
    public clickedPage: string = '';
    public IsPolicyDateNotified: boolean = false;
    public ExistsDOTSubmitData: any;
    public IsAccountDOTBreadcrumpClicked: boolean = false;
    public IsUWBreadcrumpClicked: boolean = false;
    public IsLossHistoryBreadcrumpClicked: boolean = false;
    public IsVehicleBreadcrumpClicked: boolean = false;
    public IsDriverBreadcrumpClicked: boolean = false;
    public IsProductBreadcrumpClicked: boolean = false;
    public IsApplicationBreadcrumpClicked: boolean = false;
    public IsWIPCall: boolean = false;
    public IsDOTExistsInWIP: boolean = false;
    public IsSavedFromMenu: boolean = false;
    public NotAllowedToSubmit: boolean = false;
    public isEmptyListFromMenu: boolean = false;
    public IsBackButtonClicked: boolean = false;
    public IsVehicleOnInItCall: boolean = false;
    public editAllUnits: EditAllUnits = new EditAllUnits();
    public VehicleBulkEditRadiusCheckbox: boolean = false;
    public VehicleBulkEditCompCoverageCheckbox: boolean = false;
    public VehicleBulkEditCollCoverageCheckbox: boolean = false;
    public VehicleBulkEditGaragingCheckbox: boolean = false;
    public VehicleBulkEditCustomPartsCheckbox: boolean = false;
    public VehicleBulkEditInsuredLossPayeeCheckbox: boolean = false;
    public IsEditRadiusSaved: boolean = false;
    public IsEditCompDeductibleSaved: boolean = false;
    public IsEditCollDeductibleSaved: boolean = false;
    public IsEditGaragingSaved: boolean = false;
    public IsEditCustomPartsSaved: boolean = false;
    public IsEditinsuredLossPayeeSaved: boolean = false;
    public ValidateEditAllUnitsRadius: boolean = false;
    public tempEditAllUnits: EditAllUnits = new EditAllUnits();
    public WIPstatusDropdown: Array<dropdown> = new Array<dropdown>();
    public AgencySubmissionList: any = [];
    public UserManagement: UserManagement = new UserManagement();
    public UserRole: string = "";
    public ClaimEmptyModel: any = [];
    public IsInternalUser: boolean = false;
    public EndorsementList: any = [];
    public EndorsementTargetList: any = [];
    public BSBAgencyList: any = [];
    public IsEndorsementActive: boolean = false;
    public IsAccountStateFreezed: boolean = false;
    public DocumentStateList: any = [];
    public DocumentState: string = "";
    public SelectedDocumentStateList: any = [];
    public OpenDocumentStateGrid: boolean = false;
    public MaximumVehicle: number = 14;
    public MaximumTrailer: number = 40;
    public isDuplicateDOT: boolean = false;
    public userAgencyCode: string = "";
    public IsShowVehicleDraftBtn: boolean = true;
    public HideVechicleButton: boolean = false;
    public HideTrailerButton: boolean = false;
  
    public AddAnotherInsuredForCargo: any = [];
    public InitialAddAnotherInsuredForCargo: any = [];
    public showingGlTab: boolean = false;
    public MenuList: Array<{ id: number, text: string, isActive: boolean, cssClass: string, arrowCss: string, }> =
        [{ id: 1, text: 'AccountInfo', isActive: false, cssClass: '', arrowCss: '' },
        { id: 2, text: 'uw', isActive: false, cssClass: '', arrowCss: '' },
        { id: 3, text: 'losshistory', isActive: false, cssClass: '', arrowCss: '' },
        { id: 4, text: 'vehicles', isActive: false, cssClass: '', arrowCss: '' },
        { id: 5, text: 'driver', isActive: false, cssClass: '', arrowCss: '' },
        { id: 6, text: 'product', isActive: false, cssClass: '', arrowCss: '' },
        { id: 7, text: 'application', isActive: false, cssClass: '', arrowCss: '' },];
    public AddAnotherNamedIndividual: any = [];
    public initialAddAnotherNamedIndividual: any = [];
    public selectedEndorsements: any = [];
    
    constructor() {
    }
}
export class RegistrationModal {
    public givenName: string;
    public middleName: string;
    public email: string;
    public agentCode: string;
    public AgencyList: any;
    public AgencyCodeList: any[] = [];
    public surName: string;
    public securityRole: string;
    public adminEmail: string;
}
export class VehicleModel {
    public VehiclesList: Array<vehicleDetails> = new Array<vehicleDetails>();
    public trailerList: Array<vehicleDetails> = new Array<vehicleDetails>();
    public vehicleItem: vehicleDetails = new vehicleDetails();
    public vehicleDefault: vehicleDetails = new vehicleDetails();
    public vehicleIndex: number = 0;
    public trailerIndex: number = 0;
    public vehiclePage: number = 1;
}

export class vehicleDetails {
    public vehicleYear: emptyModal = new emptyModal();
    public vehicleMake: emptyModal = new emptyModal();
    public vehicleModel: emptyModal = new emptyModal();
    public vehicleVin: emptyModal = new emptyModal();
    public trailerBodyType: emptyModal = new emptyModal();
    public trailerSize: emptyModal = new emptyModal();
    public vehicleGaragingAddress: emptyModal = new emptyModal();
    public vehicleIsCorrectGaragingAddress: emptyModal = new emptyModal();
    public vehicleGarageStreetAddress: emptyModal = new emptyModal();
    public vehicleGarageCity: emptyModal = new emptyModal();
    public vehicleGarageState: emptyModal = new emptyModal();
    public vehicleGaragePostalCode: emptyModal = new emptyModal();
    public vehicleSize: emptyModal = new emptyModal();
    public vehicleUseCode: emptyModal = new emptyModal();
    public vehicleBodyType: emptyModal = new emptyModal();
    public vehicleRadiusParent: emptyModal = new emptyModal();
    public vehicleSecondaryClass: emptyModal = new emptyModal();
    public vehicleTotalMilesUpto50: emptyModal = new emptyModal();
    public vehicleTotalMilesUpto200: emptyModal = new emptyModal();
    public vehicleTravelOver200: emptyModal = new emptyModal();
    public vehicleCompOTCCoverage: emptyModal = new emptyModal();
    public vehicleCollCoverage: emptyModal = new emptyModal();
    public vehicleToZone: emptyModal = new emptyModal();
    public vehicleApplicableZonePercent: emptyModal = new emptyModal();
    public addInsuredInterestType: emptyModal = new emptyModal();
    public addInsuredName: emptyModal = new emptyModal();
    public addInsuredStreetAddress: emptyModal = new emptyModal();
    public addInsuredCity: emptyModal = new emptyModal();
    public addInsuredState: emptyModal = new emptyModal();
    public addInsuredPostalCode: emptyModal = new emptyModal();
    public addInsuredLoanNumber: emptyModal = new emptyModal();
    public vehicleSubSizeClass: emptyModal = new emptyModal();
    public vehicleComp: emptyModal = new emptyModal();
    public vehicleColl: emptyModal = new emptyModal();
    public Q_WhichCollCoverage: emptyModal = new emptyModal();
    public broadenedCollision: emptyModal = new emptyModal();
    public limitedCollision: emptyModal = new emptyModal();
    public Q_limitedCollision: emptyModal = new emptyModal();
    public vehicleStatedAmount: emptyModal = new emptyModal();
    public vehicleAddInsuredOrLossPayee: emptyModal = new emptyModal();
    public vehicleType: emptyModal = new emptyModal();
    public isReviewed: boolean = false;
    public group: string = '';
    public zoneList: any = [];
    public interestList: any = [];
    public vehicleCustomPartsParent: emptyModal = new emptyModal();
    public vehicleCustomAmount: emptyModal = new emptyModal();
    public vehicleCustomDesc: emptyModal = new emptyModal();
    public isReviewedModal: emptyModal = new emptyModal();
    public isChecked: boolean = false;
    public vinEtched: emptyModal = new emptyModal();
    public floorTrailer: emptyModal = new emptyModal();
    public farthestApplicableZonePercent: emptyModal = new emptyModal();
    public farthestToZone: emptyModal = new emptyModal();
}

export class modalValues {
    public modal: any;
    public IsHidden: boolean = false;
    public IsRequired: boolean = false;
    public placeHolder: string = '';
    public dropdown: Array<dropdown> = new Array<dropdown>();
    public isShow: boolean = false;
    public IsExistsData: boolean = false;
    public minDate: Date;
    public maxDate: Date;
    public IsOverride: boolean = false;
    public status: string = 'true';
}

export class dropdown implements QuestionOption {
    public code: string;
    public label: string;
    public sequence: number;
    public value: string;
    public helpText: string;
    public isEligible: boolean;
}

export class emptyModal implements QuestionAnswer {
    public key: string;
    public name: string;
    public answer: any;
    public rowIndex: number;    
    public hidden: boolean = false;
    public required: boolean = false;    
    public enabled: boolean = false;
    public group: string;
    public helpText: string;
    public type: string;
    public questionLabel: string;
    public minimum: string;
    public maximum: string
    public questionDefault: any;    
    public questionOptions: Array<dropdown> = new Array<dropdown>();    
    public isVehicleUpdatedbyVEH1: boolean = false;

    static initializeFrom(questionAnswer: QuestionAnswer): emptyModal{
        let instance = new emptyModal();
        if(!!questionAnswer){
            Object.keys(questionAnswer).forEach(property => {
                instance[property] = questionAnswer[property];
            })
        }
        return instance;
    }
}

export class ProductModel {
    public productDetails: ProductDetails = new ProductDetails();
    public productIndex: number = 0;
    public productList: any = [];
    public IsGLShow: boolean = true;
    public PremiseStateDefaultValue: string = '';
    public umPdDedDefaultValue: string = '';
    public uimBiDefaultValue: string = '';
    public PremiseLocationDefaultValue: string = '';
    public IsCargoShow: boolean = true;
}
export class ProductDetails {
    public umUimParent: emptyModal = new emptyModal();
    public glIncluded: emptyModal = new emptyModal();
    public insurableInterestType: emptyModal = new emptyModal();
    public insurableInterestName: emptyModal = new emptyModal();
    public insurableInterestStreet: emptyModal = new emptyModal();
    public insurableInterestCity: emptyModal = new emptyModal();
    public insurableInterestState: emptyModal = new emptyModal();
    public insurableInterestPostalCode: emptyModal = new emptyModal();
    public insurabledInterestLoanNumber: emptyModal = new emptyModal();
    public eachOccurrenceLImit: emptyModal = new emptyModal();
    public persAdvInj: emptyModal = new emptyModal();
    public aggregateLimit: emptyModal = new emptyModal();
    public medExpense: emptyModal = new emptyModal();
    public prmsRent: emptyModal = new emptyModal();
    public empBenefitsLiab: emptyModal = new emptyModal();
    public empBenefitsLiabNumberOfEmp: emptyModal = new emptyModal();
    public premiseLocation: emptyModal = new emptyModal();
    public additionalPremiseLocation: emptyModal = new emptyModal();
    public premiseStreet: emptyModal = new emptyModal();
    public premiseCity: emptyModal = new emptyModal();
    public premiseState: emptyModal = new emptyModal();
    public premisePostalCode: emptyModal = new emptyModal();
    public payrollAmount: emptyModal = new emptyModal();
    public additionalInsuredIncludedParent: emptyModal = new emptyModal();
    public additionalInsuredName: emptyModal = new emptyModal();
    public insurableInterestParent: emptyModal = new emptyModal();
    public autoWavierOfSubrogation: emptyModal = new emptyModal();
    public autoWavierOfSubrogationName: emptyModal = new emptyModal();
    public glWavierOfSubrogation: emptyModal = new emptyModal();
    public glWavierOfSubrogationName: emptyModal = new emptyModal();
    public classCode: emptyModal = new emptyModal();
    public nfPipLimits: emptyModal = new emptyModal();
    public nfPipDed: emptyModal = new emptyModal();
    public cargoIncluded: emptyModal = new emptyModal();
    public flatDeductible: emptyModal = new emptyModal();
    public additionalDebris: emptyModal = new emptyModal();
    public defenseCost: emptyModal = new emptyModal();
    public freightCost: emptyModal = new emptyModal();
    public pollutionCleanupAndRemoval: emptyModal = new emptyModal();
    public newlyAcquiredTerminals: emptyModal = new emptyModal();
    public cargoNameInsuredParent: emptyModal = new emptyModal();
    public cargoInsuredName: emptyModal = new emptyModal();
    public cargoInsuredStreet: emptyModal = new emptyModal();
    public cargoInsuredCity: emptyModal = new emptyModal();
    public cargoInsuredState: emptyModal = new emptyModal();
    public cargoInsuredPostalCode: emptyModal = new emptyModal();
    public pdlBuyback: emptyModal = new emptyModal();
    public workersCompensation: emptyModal = new emptyModal();
    public NF_250kWithPIPME_Child1: emptyModal = new emptyModal();
    public NF_250kWithPIPME_Child2: emptyModal = new emptyModal();
    public NF_50K_Child1: emptyModal = new emptyModal();
    public NF_50K_Child2: emptyModal = new emptyModal();
    public NF_PIPMedOptOut_Child1: emptyModal = new emptyModal();
    public NF_PIPMedOptOut_Child2: emptyModal = new emptyModal();
    public NF_NotQualify: emptyModal = new emptyModal();
    public allMembersHealthCoverageExcluded: emptyModal = new emptyModal();
    public oneormoreMembersHealthCoverageExcluded: emptyModal = new emptyModal();
    public propertyProtection: emptyModal = new emptyModal();
    public mcca: emptyModal = new emptyModal();
    public Q_pollutionBroadForm: emptyModal = new emptyModal();
    public namedIndividual: emptyModal = new emptyModal();
    public municipalLocation: emptyModal = new emptyModal();
    public municipalCityLimit: emptyModal = new emptyModal();
}
export class ApplicationInfoModel {
    public pdfDatas: any = {};
    public iftaDocumentFiles: any[] = [];
    public lossRunDocumentFiles: any[] = [];
    public applicationDetails: ApplicationDetails = new ApplicationDetails();
    public dataAgreementDocument: any = {};
}
export class ApplicationDetails {
    public motorCarrierFilingParent: emptyModal = new emptyModal();
    public motorCarrierFilingChild: emptyModal = new emptyModal();
    public underwriterNote: emptyModal = new emptyModal();
    public iftaDocumentId: emptyModal = new emptyModal();
    public lossRunDocumentId: emptyModal = new emptyModal();
    public agencyCode: emptyModal = new emptyModal();
    public branchCode: emptyModal = new emptyModal();
    public sicCode: emptyModal = new emptyModal();
    public certifyLossRuns: emptyModal = new emptyModal();
    public certifyIftas: emptyModal = new emptyModal();
}
export class EditAllUnits {
    public vehicleTotalMilesUpto50: emptyModal = new emptyModal();
    public vehicleTotalMilesUpto200: emptyModal = new emptyModal();
    public vehicleTravelOver200: emptyModal = new emptyModal();
    public vehicleToZone: emptyModal = new emptyModal();
    public vehicleApplicableZonePercent: emptyModal = new emptyModal();
    public zoneList: any = [];
    public vehicleCompOTCCoverage: emptyModal = new emptyModal();
    public vehicleComp: emptyModal = new emptyModal();
    public vehicleCollCoverage: emptyModal = new emptyModal();
    public vehicleColl: emptyModal = new emptyModal();
    public Q_WhichCollCoverage: emptyModal = new emptyModal();
    public broadenedCollision: emptyModal = new emptyModal();
    public Q_limitedCollision: emptyModal = new emptyModal();
    public limitedCollision: emptyModal = new emptyModal();
    public vehicleIsCorrectGaragingAddress: emptyModal = new emptyModal();
    public vehicleGarageStreetAddress: emptyModal = new emptyModal();
    public vehicleGarageState: emptyModal = new emptyModal();
    public vehicleGarageCity: emptyModal = new emptyModal();
    public vehicleGaragePostalCode: emptyModal = new emptyModal();
    public vehicleCustomPartsParent: emptyModal = new emptyModal();
    public vehicleAddInsuredOrLossPayee: emptyModal = new emptyModal();
    public vinEtched: emptyModal = new emptyModal();
    public interestList: any = [];
    public floorTrailer: emptyModal = new emptyModal();
    public farthestApplicableZonePercent: emptyModal = new emptyModal();
    public farthestToZone: emptyModal = new emptyModal();
}
export class UserManagement {
    public userSearch: userSearch = new userSearch();
    public UsersList: any[];
    public tempUsersList: any[];
    public SecurityRoleList: Array<dropdown> = new Array<dropdown>();
}
export class userSearch {
    public agencyCode: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public emailID: string = '';
    public inactiveUser: boolean = false;
}
export class dropDownOptions {
    public value: string = '';
    public label: string = '';
}
