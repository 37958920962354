import { Component } from '@angular/core';
import { DAO } from './modal/DAO';
import { MessageService } from 'primeng/api';
import { ConfirmationDialogService } from './services/confirmationDialog.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'BSB';
  userName: string;
  public currentPath: string = '';
  constructor(public dao: DAO, private messageService: MessageService, private confirmationDialogService: ConfirmationDialogService, public router: Router) {
  }

  ngOnInit() {
    this.currentPath = window.location.pathname;
  }
  triggerLoader(type) {
    if (type === 'hide') {
      document.getElementById("loaderForServiceCall").style.display = 'none';
      document.body.style.opacity = "1";
      document.body.style.pointerEvents = "auto";
      document.body.style.background = "none";
    } else {
      document.getElementById("loaderForServiceCall").style.display = 'block';
      document.body.style.opacity = "0.5";
      document.body.style.pointerEvents = "none";
      document.body.style.background = "rgba(51,51,51,0.1)";
    }
  }
  public addToast(type, header, body) {
    this.messageService.add({ key: 'toast', severity: type, summary: header, detail: body });
  }
  
  public displayErrorPopupifAnyHitFails() {
    this.messageService.add({ key: 'declinePopup', sticky: true, severity: 'error', summary: 'Your submission could not be transmitted at this time.  Please try again later or contact Berkley Small Business Solutions at help@berkleysmallbusiness.com for additional support.' });
  }
  public reload() {
    document.body.style.pointerEvents = "visible";
    window.location.reload();
  }
  public async duplicatePopup() {
    this.dao.isDuplicateDOT = true;
    this.messageService.add({ key: 'duplicatePopup', sticky: true, severity: 'error', summary: 'DOT# has already been submitted by your agency. Please contact Berkley Small Business Solutions for additional support.' });
  }
  public registrationMsgPopup(body, logUserOut:boolean = false) {
    this.messageService.add({ key: 'registrationMsgPopup', sticky: true, severity: 'error', summary: body, data:logUserOut });
    document.body.style.pointerEvents = "none";
  }
  public registrationSuccessPopup(body) {
    this.messageService.add({ key: 'registrationSuccessPopup', sticky: true, severity: 'success', summary: body });
    document.body.style.pointerEvents = "none";
  }
  onReject(type, logUserOut: boolean) {
    this.messageService.clear(type);
    document.body.style.pointerEvents = "visible";
    if (logUserOut){
        location.href = "/";
    }
  }

  ConfirmationHandler(response: string, messageData: any) {
    this.confirmationDialogService.updateDialogData({ response: response, data: messageData });
  }
  signOut() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      //set expiration on cookie
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    //also clear session storage
    sessionStorage.clear();
    window.location.href = '/';
  }
  
  ok(toastKeyName: string, flow : string = "") {
    this.messageService.clear(toastKeyName);
    document.body.style.pointerEvents = "visible";
    if(flow === 'Endorsement'){
      this.router.navigate(['/home'], { state: { data: 'endorsement' } });
    }
  }
}