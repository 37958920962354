import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, QueryList, ViewChildren, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DAO } from '../../modal/DAO'
import { MenuClickContext } from '../../modal/routing.models'
import _ from 'lodash';
import { ServiceLayer } from 'src/app/services/serviceLayer';
import { merge, fromEvent, Subscription, } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { QuestionAnswer, ResponseContainer } from 'src/app/modal/questionnaire.models';

declare var require: any
let jsonPathRef = require('jsonpath');
declare var jQuery: any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class menuComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() notifyParentForRollback: EventEmitter<string> = new EventEmitter<string>();
  public dotNumber: string = "";
  public accountName: string;
  public policyExpirationDate: Date;
  public policyEffectiveDate: Date
  private apiCallSubscription: Subscription;

  constructor(public dao: DAO, private messageService: MessageService, public serviceLayer: ServiceLayer) { }

  ngOnInit(): void {
    this.apiCallSubscription = this.serviceLayer.dotSubmissionDetails.subscribe(
      (dotSubmissionDetails) => {
        if (!!dotSubmissionDetails) {
          this.SetDataonInfoBar(dotSubmissionDetails)
        }
      });
  }

  ngAfterViewInit(): void {
  }

  // menu click
  public async menuClick(page) {
    let menuClickContext: MenuClickContext = new MenuClickContext();
    menuClickContext.currentPageIsValid = true;
    menuClickContext.page = page;
    this.serviceLayer.menuClickBehavior.next(menuClickContext);

    if (page == 'AccountInfo' && !this.dao.IsAccountDOTBreadcrumpClicked && this.dao.IsDOTExistsInWIP) {
      this.dao.IsBackButtonClicked = true;
    }    
    let result: any;
    let currentPageOrder = this.serviceLayer.getPageOrder(this.dao.PolicyTemplate);
    let finalSavePageOrder = this.serviceLayer.getPageOrder(this.dao.navigatePage);
    let clickedPage = this.serviceLayer.getPageOrder(page);
    if (clickedPage <= finalSavePageOrder || clickedPage < currentPageOrder) {
      result = await this.serviceLayer.menuClick(menuClickContext);
      if (result) {
        this.dao.PolicyTemplate = page;
      }
    }
    return false;
  }

  // ========= On Reject =====================
  public onReject(type) {
    this.messageService.clear(type);
    this.dao.isClickFromMenu = false;
    document.body.style.pointerEvents = "visible";
  }
  // confirm Change============
  public async confirmChange() {
    this.messageService.clear();
    document.body.style.pointerEvents = "visible";
    this.notifyParentForRollback.emit(this.dao.PolicyTemplate);
    if (this.dao.PolicyTemplate == 'AccountInfo') {
      this.dao.businessAddressValidation = '';      
    }
    if (this.dao.PolicyTemplate == 'vehicles') {
      this.dao.vehicleModel = _.cloneDeep(this.dao.initialvehicleModel)
    }
    if (this.dao.PolicyTemplate == 'product') {
      this.dao.ProductModel = _.cloneDeep(this.dao.initialProductModel)
      this.dao.AddProductAnotherLocation = _.cloneDeep(this.dao.initialAddProductAnotherLocation)
      this.dao.AddAnotherInsured = _.cloneDeep(this.dao.initialAddAnotherInsured)
      this.dao.AddAnotherWaiver = _.cloneDeep(this.dao.initialAddAnotherWaiver)
      this.dao.AddAnotherInsuredForCommercialAuto = _.cloneDeep(this.dao.initialAddAnotherInsuredForCommercialAuto)
      this.dao.AddAnotherWaiverForCommercialAuto = _.cloneDeep(this.dao.initialAddAnotherWaiverForCommercialAuto)
      var productQuestions = this.dao.DOTList.questionnaire.find(q => q.key === 'product');
      if (!!productQuestions) {
        productQuestions = this.dao.initialProductQuestionnaireAnswers;
      }
    }

    this.dao.isClickFromMenu = true;
    this.dao.ClickedPageFromMenuPage = this.dao.clickedPage;

    if (this.dao.isEmptyListFromMenu) {
      if (this.dao.navigatePage != this.dao.finalSubmitPage) {
        let finalSavePageOrder = await this.serviceLayer.getPageOrder(this.dao.navigatePage);
        this.dao.navigatePage = this.serviceLayer.getPageNamebyOrder(finalSavePageOrder - 1);
      }
      this.dao.isEmptyListFromMenu = false;
      this.dao.isClickFromMenu = false;
    }

    if (this.dao.IsDOTExistsInWIP && ((!this.dao.IsAccountDOTBreadcrumpClicked && this.dao.clickedPage == 'AccountInfo')
      || (!this.dao.IsUWBreadcrumpClicked && this.dao.clickedPage == 'uw')
      || (!this.dao.IsLossHistoryBreadcrumpClicked && this.dao.clickedPage == 'losshistory')
      || (!this.dao.IsVehicleBreadcrumpClicked && this.dao.clickedPage == 'vehicles')
      || (!this.dao.IsDriverBreadcrumpClicked && this.dao.clickedPage == 'driver')
      || (this.dao.clickedPage == 'product'))) {
      await this.breadcrumpClick(this.dao.clickedPage);
    }
    this.setActiveMenu();
    this.dao.IsBackButtonClicked = false;
  }

  public async breadcrumpClick(page) {
    if (this.dao.IsDOTExistsInWIP) {
      return this.serviceLayer.breadcrumpClick(page);
    }
  }
  public setActiveMenu(page = "") {
    let response = this.serviceLayer.setActiveMenu(page);
  }
  public async SetDataonInfoBar(apiResponse: ResponseContainer) {
    let getAccountInfoQuestionnaire = this.dao.DOTList.accountInfoQsnr;
    let accountNameQA = getAccountInfoQuestionnaire.findQuestion('accountName');
    if (!!accountNameQA)
    {
      this.accountName = getAccountInfoQuestionnaire.findQuestion('accountName').answer;
      if (!!!this.accountName)
      {
        if (!!accountNameQA.questionDefault && accountNameQA.questionDefault.length > 0 && !!accountNameQA.questionDefault[0].code  )
          this.accountName = accountNameQA.questionDefault[0].code;
      } 
    }
    
    this.dotNumber = this.dao.DOTList.dotQsnr?.findQuestion("accountDot")?.answer;
    try
    {
        let getPolicyExpirationDateAnswer = getAccountInfoQuestionnaire.findQuestion('policyExpirationDate').answer;
        if (!!getPolicyExpirationDateAnswer)
        this.policyExpirationDate = this.serviceLayer.getCorrectDate(getPolicyExpirationDateAnswer);
        var getPolicyEffectiveDateQuestionanswer = getAccountInfoQuestionnaire.findQuestion('policyEffectiveDate');
        if (!!getPolicyEffectiveDateQuestionanswer.answer)
        this.policyEffectiveDate =this.serviceLayer.getCorrectDate( getPolicyEffectiveDateQuestionanswer.answer);
    }
    catch(ex)
    {
        console.error(ex)
    }
    
  }

  public ngOnDestroy(): void {
    if (!!this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
      this.apiCallSubscription = null;
    }
  }
}
