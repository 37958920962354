import { Injectable, Injector } from '@angular/core';
import { DAO } from '../modal/DAO'
import _ from 'lodash';

@Injectable({
  providedIn: 'any'
})
export class ChangeDetectionService {
  constructor(public dao: DAO,) { }

  public HasCurrentPolicyTemplateDataChanged(): boolean {
    let result = false;
    if (this.dao.PolicyTemplate == "vehicles") {
      result = !_.isEqual(this.dao.vehicleModel.VehiclesList, this.dao.initialvehicleModel.VehiclesList) || !_.isEqual(this.dao.vehicleModel.trailerList, this.dao.initialvehicleModel.trailerList);
    }
    else if (this.dao.PolicyTemplate == "product") {
      let ProductQuestionnaireAnswers = this.dao?.DOTList?.questionnaire?.find(q => q.key === 'product');
      result = !_.isEqual(this.dao.ProductModel, this.dao.initialProductModel) ||
        !_.isEqual(this.dao.AddProductAnotherLocation, this.dao.initialAddProductAnotherLocation) ||
        !_.isEqual(this.dao.AddAnotherInsured, this.dao.initialAddAnotherInsured) ||
        !_.isEqual(this.dao.AddAnotherWaiver, this.dao.initialAddAnotherWaiver) ||
        !_.isEqual(this.dao.AddAnotherInsuredForCommercialAuto, this.dao.initialAddAnotherInsuredForCommercialAuto) ||
        !_.isEqual(this.dao.AddAnotherWaiverForCommercialAuto, this.dao.initialAddAnotherWaiverForCommercialAuto) ||
        !_.isEqual(this.dao.AddAnotherInsuredForCargo, this.dao.InitialAddAnotherInsuredForCargo) ||
        !_.isEqual(this.dao.AddAnotherNamedIndividual, this.dao.initialAddAnotherNamedIndividual) ||
        !_.isEqual(this.dao.initialProductQuestionnaireAnswers, ProductQuestionnaireAnswers);
    }
    return result;
  }
}
