import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ServiceLayer } from '../../services/serviceLayer';
import { DAO } from '../../modal/DAO';
import { Contact, DAODetailList } from '../../modal/PolicyHolderValueParser.models';
import { Constants } from '../../modal/ProjConstants.models';
import * as $ from "jquery";
import _ from 'lodash';
declare var jQuery: any;
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { ConfirmationDialogService } from 'src/app/services/confirmationDialog.service';
import { StateSettings } from 'src/app/modal/state.models';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuestionAnswer, QuestionnaireKeys, ResponseContainer } from 'src/app/modal/questionnaire.models';
import { PositiveIntegersOnlyDirective } from 'src/app/validations/positive-integers-validator.directive';
import { IBasePage } from 'src/app/modal/IBasePage.modules';
import { MenuClickContext } from 'src/app/modal/routing.models';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-policy-holder',
  templateUrl: './policy-holder.component.html',
  styleUrls: ['./policy-holder.component.css']
})
export class PolicyHolderComponent implements OnInit, OnDestroy, IBasePage {
  public yesterday: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1);
  public MiscCommodityMinPercentage: number = 5;
  @ViewChild('f') accountForm: NgForm;
  @ViewChild('con') contactForm: NgForm;
  @ViewChild('com') commodityForm: NgForm;
  @Input() RollbackAccountInfoData: EventEmitter<string>;
  private MyKey1MsgShown: boolean = false;
  public isValidPolicyDate: boolean = true;
  protected unsubscriber = new Subject();
  public averageMilageWarning: boolean = false;
  public fleetSizeOptions = Constants.FleetSizeOptions;
  public isFleetSizeDisabled: boolean = false;
  public dotNumber: string = "";
  public IsAddDOT: boolean = false;
  public AddAdditionalDOT: string = "";
  public qaLabel_hasContinuousAutoLiability: string = "";
  public hasContinuousAutoLiabilityLabel: string = "";
  public isPolicyEffectiveJuly : boolean = false;
  public isNewTrucking: boolean = false;
  public displayIftaQuestions: boolean = false;
  private datePipe: DatePipe = new DatePipe('en-US');

  public policyHolderData: {
    DAODetailList: DAODetailList,
    initialDAODetailList: DAODetailList,
    tempDetailList: DAODetailList
  }

  public contactData: {
    AddContactInformation: Array<any>,
    initialAddContactInformation: Array<any>,
    TempAddContactInformation: Array<any>,
    IsValidContactInfo: string
  }

  public additionalDotData: {    
    AddAdditionalDOTArray:Array<any>,
    initialAddAdditionalDOTArray:Array<any>,
    IsAddDOT: boolean
  }
  
  public commodityValidationFlags: {
    sumNotEquals100: boolean,
    SumNotMoreThan100: boolean,
    hasDuplicate: boolean,
    isEligible: boolean,
  } = {
    sumNotEquals100: false,
    SumNotMoreThan100: false,
    hasDuplicate: false,
    isEligible: true,
  } 


  private meunuClickSubscription: Subscription;
  private apiCallSubscription: Subscription;
  private RollbackDataSubscription: Subscription;  
  constructor(public serviceLayer: ServiceLayer, public dao: DAO,
    private messageService: MessageService, private confirmationDialogService: ConfirmationDialogService,
    public stateSettings: StateSettings) { }
  // ========= Page Load =====================
  ngOnInit(): void {
    var that = this;
    setTimeout(function () {      
      that.dateControlChange();
      that.policyHolderData.initialDAODetailList = _.cloneDeep(that.policyHolderData.DAODetailList);
      
    }, 3000);

    setTimeout(function () {
        (<any>$('#dotID')).focus();
    
      }, 500);
  
    this.confirmationDialogService.confirmationDialogData.subscribe((message: any) => {
      if (message?.data === 'VehicleGaragingStateConfirmation') {
        this.ValidateVehicleGaragingOption(message?.response);
      }
      else if (message?.data === 'VehicleTravelInternationalConfirmation') {
        this.validateVehicleTravelOption(message?.response)
      }
    });
    this.apiCallSubscription = this.serviceLayer.dotSubmissionDetails.subscribe((apiResponseContainer) => {
      if (!!apiResponseContainer) {
        this.initializeData(apiResponseContainer);
        this.dotNumber = apiResponseContainer.dotQsnr?.findQuestion("accountDot")?.answer;
        this.verifyPolicyHolderVersion(); 
      }
    });
    this.meunuClickSubscription = this.serviceLayer.menuClickBehavior.subscribe(
      (menuClickContext: MenuClickContext) => { this.menuClicked(menuClickContext) }
    );
    this.RollbackDataSubscription = this.RollbackAccountInfoData?.subscribe(
      (page) => this.onRollback(page)
    )
  }
  // ========= Get DOT # =====================
  public async getDOTNumber() {
    return await this.serviceLayer.getDOTNumber();
  }
  // ========= Add and Remove DOT =====================
  public async AddDOT() {
    
    var TempArray = _.cloneDeep(this.additionalDotData.AddAdditionalDOTArray);
    TempArray.push({ 'id': this.additionalDotData.AddAdditionalDOTArray.length + 1, 'answer': '', 'group': '', 'key': '' });
    this.additionalDotData.AddAdditionalDOTArray = _.cloneDeep(TempArray);
    
    this.additionalDotData.IsAddDOT = true;
  }
  public async removeAdditionalDOTPopup(index) {
    this.dao.deletedIndex = index;
    if (this.additionalDotData.AddAdditionalDOTArray && this.additionalDotData.AddAdditionalDOTArray.filter(c => c.id == index) && this.additionalDotData.AddAdditionalDOTArray.filter(c => c.id == this.additionalDotData.AddAdditionalDOTArray[index].id).length > 0) {
      var answer = this.additionalDotData.AddAdditionalDOTArray.filter(c => c.id == this.additionalDotData.AddAdditionalDOTArray[index].id)[0].answer;
      if (answer != null && answer != undefined && answer && answer != null) {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'DeleteDot', sticky: true, severity: 'warn', summary: ' Do you want to delete ?' });
      }
      else {
        this.removeAdditionalDOT();
      }
    }
  }
  openPopupDialog(message: string, data: string): void {
    this.messageService.add({ key: 'confirmationPopup', sticky: true, severity: 'warn', summary: message, data: data });
    return;
  }
  ValidateVehicleGaragingOption(response: string): void {
    document.body.style.pointerEvents = "visible";
    this.messageService.clear();
    if (response.toUpperCase() === 'NO') {
      this.policyHolderData.DAODetailList.vehicleGarageStateIndicatorModal.modal = '';
    }
  }

  private validateVehicleTravelOption(response: string): void {
    document.body.style.pointerEvents = "visible";
    this.messageService.clear();
    if (response.toUpperCase() === 'NO') {
      this.policyHolderData.DAODetailList.vehicleTravelModal.modal = 'No';
    }
  }

  public async removeAdditionalDOT() {
    this.additionalDotData.AddAdditionalDOTArray.splice(this.dao.deletedIndex, 1)
    this.messageService.clear('DeleteDot');
    document.body.style.pointerEvents = "visible";
  }
  
  public async saveAndContinue(f: NgForm, con: NgForm, com: NgForm): Promise<boolean> {
    this.dao.NotAllowedToSubmit = true;
    this.validationMsgForCommodity();
    let areFormsValidate = this.isPageValid();    
    if (areFormsValidate) {
      this.dao.NotAllowedToSubmit = false;
      this.commodityValidationFlags.SumNotMoreThan100 = false;
      return await this.savePolicyHolder(f, com, con);
    }
    else if (areFormsValidate == false && !this.dao.IsSavedFromMenu) {
      this.dao.NotAllowedToSubmit = false;
      this.openValidPopup();
    }
    else if (areFormsValidate == false && this.dao.IsSavedFromMenu) {
      this.dao.NotAllowedToSubmit = true;
      this.openValidPopup();
    }
    this.isValidPolicyDate = this.checkDateValidation();
    return false;
  }

  public validateAllForms(f: NgForm, con: NgForm, com: NgForm): boolean {        
    this.serviceLayer.UpdateFiledsValuesAndValidate(f);
    this.serviceLayer.UpdateFiledsValuesAndValidate(con);
    this.serviceLayer.UpdateFiledsValuesAndValidate(com);
    return f.valid && com.valid && con.valid && (this.totalCommodityPercent == 100);
  }

  // ========= Save Policy =====================
  public async savePolicyHolder(f: NgForm, f1: NgForm, f2: NgForm) {    
    this.dao.NotAllowedToSubmit = false;
    if (f.valid && f1.valid && f2.valid) {
      this.copyDataToResponseContainer(this.policyHolderData.DAODetailList);
      await this.bindSameAddress();
      let response = await this.serviceLayer.save();
      if (this.dao.DOTLookupStatusMsg != null && this.dao.DOTLookupStatusMsg.length > 0) {
        // jQuery('#dotLookupErrorModal').modal('toggle');
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: this.dao.DOTLookupStatusMsg[0] });
      } else {
        if (response || this.contactData.IsValidContactInfo == this.dao.businessAddressValidation)
          return true;
        this.isValidPolicyDate = this.checkDateValidation();
        this.openValidPopup();
      }
    }
    else {
      this.openValidPopup();
    }
    return false;
  }
  // ========= Open Validation Popup =====================
  public openValidPopup() {
    if (!this.MyKey1MsgShown) {
      this.messageService.add({ key: 'myKey1', severity: 'error', summary: 'Policyholder', detail: this.dao.toastValidation });
      this.MyKey1MsgShown = true;
    }
  }
  // ========= Add Commodities =====================
  public async AddCommodities(form) {    
    let index = 0;
    let isValid: boolean = true;    
    if (this.policyHolderData.DAODetailList.commodityPercent1Modal.modal < 5 || !form.valid) {
      isValid = false;
    }
    for (var i = 1; i <= 5; i++) {
      if (this.policyHolderData.DAODetailList['commodityType' + i + 'Modal'].isShow == false && index == 0) {
        index = i;
      }
    }
    if (this.totalCommodityPercent >= 100) {        
        isValid = false;        
    }
    this.commodityValidationFlags.SumNotMoreThan100 = (this.totalCommodityPercent >= 100);
    if (isValid) {

      if (this.totalCommodityCount <= 5) {
        this.policyHolderData.DAODetailList['commodityType' + index + 'Modal'].isShow = true;
      }
      if (form != undefined) {
        form.submitted = false;
      }
    }
  }
  // ========= Delete Commodity =====================
  public async deleteCommodity() {
    this.policyHolderData.DAODetailList['commodityType' + this.dao.deletedIndex + 'Modal'].modal = "";
    this.policyHolderData.DAODetailList['commodityType' + this.dao.deletedIndex + 'Modal'].isShow = false;
    this.policyHolderData.DAODetailList['commodityPercent' + this.dao.deletedIndex + 'Modal'].modal = "";    
    this.messageService.clear('DeleteCommodity');
    document.body.style.pointerEvents = "visible";    
    this.validationMsgForCommodity();
  }
  public async deleteCommodityPopup(index) {
    this.dao.deletedIndex = index;
    // if (this.policyHolderData.DAODetailList['commodityPercent' + index + 'Modal'].modal != null && this.policyHolderData.DAODetailList['commodityPercent' + index + 'Modal'].modal != undefined && this.policyHolderData.DAODetailList['commodityPercent' + index + 'Modal'].modal != "") {
    if (this.commodityForm.form.controls['commodity' + index].valid || this.commodityForm.form.controls['commodityPercent' + index].valid) {
      document.body.style.pointerEvents = "none";
      this.messageService.add({ key: 'DeleteCommodity', sticky: true, severity: 'warn', summary: ' Do you want to delete ?' });
    }
    else {
      this.deleteCommodity();    
    }
  }

  public get totalCommodityCount() {
    let count = 0;
    for (var i = 1; i <= 5; i++) {
      if (this.policyHolderData.DAODetailList['commodityType' + i + 'Modal'].isShow) {
        ++count;
      }
    }
    return count;
  }

  
  public get totalCommodityPercent() {
    var totalHundred = 0;
    for (var i = 1; i <= 5; i++) {
      totalHundred = totalHundred + (isNaN(Number(this.policyHolderData.DAODetailList['commodityPercent' + i + 'Modal'].modal)) ? 0 : Number(this.policyHolderData.DAODetailList['commodityPercent' + i + 'Modal'].modal));      
    } 
    return totalHundred;
  }

  // ========= bind same address =====================
  public async bindSameAddress() {
    if (this.policyHolderData.DAODetailList.mailAddressIndicatorModal.modal) {
      this.policyHolderData.DAODetailList.mailStateModal.modal = this.policyHolderData.DAODetailList.accountStateModal.modal;
      this.policyHolderData.DAODetailList.mailStreetModal.modal = this.policyHolderData.DAODetailList.accountStreetModal.modal;
      this.policyHolderData.DAODetailList.mailCityModal.modal = this.policyHolderData.DAODetailList.accountCityModal.modal;
      this.policyHolderData.DAODetailList.mailPostalCodeModal.modal = this.policyHolderData.DAODetailList.accountPostalCodeModal.modal;
    }
  }
  // ========= Add contact=====================
  public async AddContact() {
    await this.checkDuplicateContact();
    if (this.contactData.IsValidContactInfo == '') {
      var TempArray = _.cloneDeep(this.contactData.AddContactInformation);
      TempArray.push({ 'id': this.contactData.AddContactInformation.length + 1, 'type': "", 'details': "", 'IsOverridecontactTypeModal': false, 'IsOverridecontactDetailModal': false });
      this.contactData.AddContactInformation = _.cloneDeep(TempArray);
    }
  }
  //================ remove the contact===============
  public async removeContactPopUp(index) {
    this.dao.deletedIndex = index;
    if (this.contactData.AddContactInformation && this.contactData.AddContactInformation.filter(c => c.id == index) && this.contactData.AddContactInformation.filter(c => c.id == this.contactData.AddContactInformation[index].id).length > 0) {
      var details = this.contactData.AddContactInformation.filter(c => c.id == this.contactData.AddContactInformation[index].id)[0].details;
      if (details != null && details != undefined && details != "") {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'removeContact', sticky: true, severity: 'warn', summary: ' Do you want to delete ?' });
      }
      else {
        this.removeContact();
      }
    }
  }
  public async removeContact() {
    this.contactData.AddContactInformation.splice(this.dao.deletedIndex, 1);
    this.messageService.clear('removeContact');
    document.body.style.pointerEvents = "visible";
    await this.checkDuplicateContact();
  }
  // ========= Select Effective Date =====================
  public async selectEffective() {
    this.policyHolderData.DAODetailList.policyExpirationDateModal.modal = this.policyHolderData.DAODetailList.policyEffectiveDateModal.modal ? new Date(new Date(this.policyHolderData.DAODetailList.policyEffectiveDateModal.modal).setFullYear(new Date(this.policyHolderData.DAODetailList.policyEffectiveDateModal.modal).getFullYear() + 1)) : null;
    this.isValidPolicyDate = true;
    this.setHasContinuousAutoLiabilityQuestionLabel();
  }
  // ========= Select DOB =====================
  public async selectDateofBirth() {
    this.policyHolderData.DAODetailList.businessOwnerBirthDateModal.modal = ((this.policyHolderData.DAODetailList.businessOwnerBirthDateModal.modal == null || this.policyHolderData.DAODetailList.businessOwnerBirthDateModal.modal == undefined || this.policyHolderData.DAODetailList.businessOwnerBirthDateModal.modal == '') ? this.yesterday : this.policyHolderData.DAODetailList.businessOwnerBirthDateModal.modal);
    this.validateOverride('businessOwnerBirthDateModal')
  }
  // ========= Key down=====================
  // ========= Key Down press =====================
  public keydown(e, index) {
    let totalvalue = 0;
    const pattern = /^[0-9]/;
    var key = e.keyCode || e.which;
    var TempModal = this.policyHolderData.DAODetailList['commodityPercent' + index + 'Modal'].modal
    if (this.policyHolderData.DAODetailList['commodityPercent' + index + 'Modal'].modal != undefined && this.policyHolderData.DAODetailList['commodityPercent' + index + 'Modal'].modal != "") {
      this.policyHolderData.DAODetailList['commodityPercent' + index + 'Modal'].modal = this.policyHolderData.DAODetailList['commodityPercent' + index + 'Modal'].modal + e.key;
    } else {
      this.policyHolderData.DAODetailList['commodityPercent' + index + 'Modal'].modal = e.key;
    }


    totalvalue = ((this.policyHolderData.DAODetailList.commodityPercent1Modal.modal ? Number(this.policyHolderData.DAODetailList.commodityPercent1Modal.modal) : 0) +
      (this.policyHolderData.DAODetailList.commodityPercent2Modal.modal ? Number(this.policyHolderData.DAODetailList.commodityPercent2Modal.modal) : 0) +
      (this.policyHolderData.DAODetailList.commodityPercent3Modal.modal ? Number(this.policyHolderData.DAODetailList.commodityPercent3Modal.modal) : 0) +
      (this.policyHolderData.DAODetailList.commodityPercent4Modal.modal ? Number(this.policyHolderData.DAODetailList.commodityPercent4Modal.modal) : 0) +
      (this.policyHolderData.DAODetailList.commodityPercent5Modal.modal ? Number(this.policyHolderData.DAODetailList.commodityPercent5Modal.modal) : 0))
    this.policyHolderData.DAODetailList['commodityPercent' + index + 'Modal'].modal = TempModal
    if (key == 8 || key == 9 || key == 11 || key == 27 || key == 39 || key == 45) {
      return true;
    }
    let inputChar = String.fromCharCode(e.charCode);
    if (!pattern.test(inputChar)) {
      e.preventDefault();
    }
  }
  // ========= Numbers only input =====================
  public numbersOnly(e) {
    return this.serviceLayer.numbersOnly(e);
  }
  // ========= Validate the mail =====================
  public validateEmails(mail) {
    return this.serviceLayer.validateEmails(mail);
  }
  // ========= contact change =====================
  public contactChange(item, i) {
    item.details = '';
    this.contactData.IsValidContactInfo = '';
    this.validateOverride('contactTypeModal', i);
    this.validateOverride('contactDetailModal', i)
    if (this.contactData.AddContactInformation) {
        this.contactData.AddContactInformation.forEach(element => {
            if (this.contactData.AddContactInformation.filter(c => c.type == element.type).length > 1) {
                this.contactData.IsValidContactInfo = 'Cannot have same Contact Type';
            }

        });
    }
    return this.contactData.IsValidContactInfo;
  }
  // ========= Validate override =====================
  public validateOverride(value: string, index: any = null) {
    if (value === 'vehicleGarageStateIndicatorModal') {
      var selectedOption = this.policyHolderData.DAODetailList.vehicleGarageStateIndicatorModal.modal;
      if (selectedOption === 'No') {
        let state : string = this.policyHolderData.DAODetailList?.accountStateModal.modal;
        var message : string = `This DOT will be declined if vehicles are garaged outside of the state of ${state}. Do you still want to procced with the selection?.`;
        this.openPopupDialog(message, 'VehicleGaragingStateConfirmation');
      }
    }
    if (value === 'vehicleTravelModal') {
      if (this.policyHolderData.DAODetailList.vehicleTravelModal.modal === 'Yes') {
        let message: string = 'This submission is ineligible because of international travel. Do you still want to proceed with the selection?';
        this.openPopupDialog(message, 'VehicleTravelInternationalConfirmation');
      }
    }
    if (value == 'legalEntityModal') {
      let dotNumber = this.dao.DOTList.dotQsnr.findQuestion("accountDot")?.answer;
      var FeinNumber = dotNumber;
      if (this.policyHolderData.DAODetailList.legalEntityModal.modal == 'INDIVIDUAL') {
        var dotCount = this.WordCounter(dotNumber)
        if (dotCount <= 7) {
          for (let i = dotCount; i < 7; i++) {
            FeinNumber = '0' + FeinNumber;
          }
        }
        this.policyHolderData.DAODetailList.businessOwnerFeinModal.modal = '07-' + FeinNumber;
      } else {
        this.policyHolderData.DAODetailList.businessOwnerFeinModal.modal = '';
      }
    }
    return this.serviceLayerValidateOverride(value, index);
  }

  // ========= Validate Override check =====================
  public async serviceLayerValidateOverride(value: string, index: string) {
    if (this.policyHolderData.DAODetailList[value].IsExistsData) {
      if (value != "policyEffectiveDateModal" 
            && value != "policyExpirationDateModal" && value != "businessOwnerBirthDateModal") {
          this.policyHolderData.DAODetailList[value].modal = typeof (this.policyHolderData.DAODetailList[value].modal) != 'boolean' ? this.policyHolderData.DAODetailList[value].modal.trim() : this.policyHolderData.DAODetailList[value].modal;
        if (value == "mailPostalCodeModal" || value == "businessOwnerFeinModal" || value == "contactDetailModal" || value == "accountPostalCodeModal") {
          this.policyHolderData.DAODetailList[value].modal = this.policyHolderData.DAODetailList[value].modal.includes('_') ? '' : this.policyHolderData.DAODetailList[value].modal;
        }
        if ((!value.includes('commodityType') && !value.includes('commodityPercent')) || this.policyHolderData.tempDetailList[value].IsHidden == false) {
          this.policyHolderData.DAODetailList[value].IsOverride = this.policyHolderData.tempDetailList[value].modal != this.policyHolderData.DAODetailList[value].modal;
        }
        if ((value == 'contactTypeModal' || value == "contactDetailModal") && Number(index) < this.contactData.TempAddContactInformation.length) {
          this.contactData.TempAddContactInformation[index]['IsOverride' + value] = this.contactData.AddContactInformation[index]['IsOverride' + value] = !_.isEqual(this.contactData.AddContactInformation[index][value == 'contactTypeModal' ? 'type' : 'details'], this.contactData.TempAddContactInformation[index][value == 'contactTypeModal' ? 'type' : 'details']);
        }
      }
      else {
        this.policyHolderData.DAODetailList[value].IsOverride = new Date(this.policyHolderData.tempDetailList[value].modal).setHours(0, 0, 0, 0) != new Date(this.policyHolderData.DAODetailList[value].modal).setHours(0, 0, 0, 0);
      }
    }
  }

  // ========= Cancel my submission =====================
  public CancelmySubmission() {
    return this.serviceLayer.CancelmySubmission();
  }
  // ========= cancel my submisson popup =====================
  public cancelMySubmissionPopUp() {
    document.body.style.pointerEvents = "none";
    this.messageService.add({ key: 'CancelmySubmission', sticky: true, severity: 'warn', summary: 'All of your data will be lost if you cancel this submission. Are you sure you want to continue?' });
  }
  // ========= On Reject =====================
  public onReject(type) {
    this.messageService.clear(type);
    document.body.style.pointerEvents = "visible";
    if (type = 'myKey1')
      this.MyKey1MsgShown = false;
  }
  //  change Date control with mask
  public dateControlChange() {
    var that = this;
    jQuery("#calendarIcon1").inputmask("mm/dd/yyyy", {
      isComplete: function () {
        const value = jQuery("#calendarIcon1").val();
        const numericNewEffectiveDate = new Date(value).getTime();          
        if (!isNaN(numericNewEffectiveDate)) {
            
            if (!!!that.policyHolderData.DAODetailList.policyEffectiveDateModal.modal || new Date(that.policyHolderData.DAODetailList.policyEffectiveDateModal.modal).getTime() != numericNewEffectiveDate) {
              that.policyHolderData.DAODetailList.policyEffectiveDateModal.modal = new Date(numericNewEffectiveDate);
            }
        }
        
      }, onKeyValidation: function (key, result) {
        $("div").remove(".p-datepicker");
      }
    });  
    jQuery("#calendarIcon1").blur(function () {
      if (new Date(that.policyHolderData.DAODetailList.policyEffectiveDateModal.modal) > that.policyHolderData.DAODetailList.policyEffectiveDateModal.maxDate) {
        that.policyHolderData.DAODetailList.policyEffectiveDateModal.modal = that.policyHolderData.DAODetailList.policyEffectiveDateModal.maxDate;
      }
    });

    jQuery("#calendarIcon2").inputmask("mm/dd/yyyy", {
      isComplete: function () {
        const value = jQuery("#calendarIcon2").val();
        const numericNewEffectiveDate = new Date(value).getTime();  
        if (!isNaN(numericNewEffectiveDate)) {            
          if (!!!that.policyHolderData.DAODetailList.policyExpirationDateModal.modal || new Date(that.policyHolderData.DAODetailList.policyExpirationDateModal.modal).getTime() != numericNewEffectiveDate) {
              that.policyHolderData.DAODetailList.policyExpirationDateModal.modal = new Date(numericNewEffectiveDate);
            }
       }        
      }, onKeyValidation: function (key, result) {
        $("div").remove(".p-datepicker");
      }
    });
    jQuery("#calendarIcon2").blur(function () {
      if (new Date(that.policyHolderData.DAODetailList.policyExpirationDateModal.modal) < that.policyHolderData.DAODetailList.policyEffectiveDateModal.modal) {
        that.selectEffective();
      }
    })
    jQuery("#calendarIcon3").inputmask("mm/dd/yyyy", {
      isComplete: function (event) {
        const value = jQuery("#calendarIcon3").val();
        const numericNewEffectiveDate = new Date(value).getTime();  
        if (!isNaN(numericNewEffectiveDate)) {            
            if (!!!that.policyHolderData.DAODetailList.businessOwnerBirthDateModal.modal || new Date(that.policyHolderData.DAODetailList.businessOwnerBirthDateModal.modal).getTime() != numericNewEffectiveDate) {
              that.policyHolderData.DAODetailList.businessOwnerBirthDateModal.modal = new Date(numericNewEffectiveDate);
            }
          }
      }, onKeyValidation: function (key, result) {
        $("div").remove(".p-datepicker");
      }
    });
    jQuery("#calendarIcon3").blur(function (event) {
      if (new Date(that.policyHolderData.DAODetailList.businessOwnerBirthDateModal.modal) > that.yesterday) {
        that.policyHolderData.DAODetailList.businessOwnerBirthDateModal.modal = that.yesterday;
      }
    })
  }
  // Validate address
  public ValidateAddress(event, value, type) {
    let result = this.serviceLayer.ValidateAddress(event, value);
    if (result != null) {
      if (type == "BusinessAddress") {
        this.policyHolderData.DAODetailList.accountStreetModal.modal = result;
      }
      else if (type == "MailingAddress") {
        this.policyHolderData.DAODetailList.mailStreetModal.modal = result;
      }
    }
  }
  public WordCounter(str) {
    var words = str.split("").length;
    return words;
  }
  public shallBeReadOnly(frm: NgForm, item: any, idx: string): boolean {
    let contactDetailsCtl = "Cdetail_inp" + idx;
    if (item?.type == 'EMAIL_ADDR' && idx == "0")
      return true;
    return false;
  }

  public hasDuplicateCommodities() {
    for (let i=2;i<=5;i++)
    {
        let oldValue = this.commodityForm.form.controls["commodity"+i]?.value
        if (!!oldValue)
        {
            for (let j=i+1; j<=5; j++)
            {
                let newValue = this.commodityForm.form.controls["commodity"+j]?.value
                if(!!newValue && newValue == oldValue)
                    return true;
            }            
        }
        
    }
    return false; 
  }

  public isDuplicatedWithOtherCommodities(index: number) {
    let oldValue = this.commodityForm.form.controls["commodity"+index]?.value
    for (let i = 2; i <= 5 ; i++) {
        let newValue = this.commodityForm.form.controls["commodity"+i]?.value
        if(i != index && !!newValue && newValue == oldValue)
            return true;
    }
    return false;
  }

  // Checking Commodity is eligible or not
  public isCommodityTypeEligible(index: number): boolean {
    let selectedType = this.policyHolderData.DAODetailList["commodityType" + index + "Modal"]?.modal;
    if (!!!selectedType)
        return true;

    if (this.policyHolderData.DAODetailList["commodityType" + index + "Modal"].dropdown?.filter(x => x?.label == selectedType)[0]?.isEligible)
        return true;
    
    return false
  }

  public validationMsgForCommodity() {
    //sumNotEquals100')
    this.commodityValidationFlags.sumNotEquals100 = false
    if ((this.accountForm.submitted  || this.commodityForm.submitted)
        && this.totalCommodityPercent != 100) {
         this.commodityValidationFlags.sumNotEquals100 = true;
         this.commodityValidationFlags.SumNotMoreThan100 = this.totalCommodityPercent >100;
      }

    //'isDuplicate'
    this.commodityValidationFlags.hasDuplicate = this.hasDuplicateCommodities();
     
      // 'isEligible') 
    this.commodityValidationFlags.isEligible = false;
    if (this.isCommodityTypeEligible(2) 
          && this.isCommodityTypeEligible(3) 
          && this.isCommodityTypeEligible(4) 
          && this.isCommodityTypeEligible(5) ) {
             this.commodityValidationFlags.isEligible = true;
    }  
  }

  public commodityClassValidator(parentFrm: NgForm, childFrm: NgForm, index: number, type: string) {
    if (childFrm.form.controls[type + index]) {
      if (type == 'commodity') {
        if (((parentFrm.submitted || childFrm.submitted) && !childFrm.form.controls[type + index].valid)
          || (childFrm.touched && this.isDuplicatedWithOtherCommodities(index))) {
          return 'wid_31 ipad_65 validate_error_cls';
        } else if (!this.isCommodityTypeEligible(index)) {
          return 'wid_31 ipad_65 validate_commodityNotEligible_cls'
        }
      } else if (type == 'commodityPercent') {
        if (((parentFrm.submitted || childFrm.submitted) && !childFrm.form.controls[type + index].valid)
          || (this.totalCommodityPercent != 100
                && !(this.policyHolderData.DAODetailList[type + index + "Modal"].modal < this.MiscCommodityMinPercentage))) {
          return 'wid_31 ipad_65 validate_error_cls';
        }
      }
    }
    return 'wid_31 ipad_65';
  }

  public IftaFleetMilesChanged(val1: ResponseContainer | any) {
    if (!!this.accountForm && this.accountForm.value?.iftaFleetSize != undefined && this.accountForm.value.iftaVehiclesMileage != undefined) {
      if (Number(this.accountForm.value.iftaFleetSize) <= 0)
        this.averageMilageWarning = false;
      else
        this.averageMilageWarning = Number(this.accountForm.value.iftaVehiclesMileage / this.accountForm.value.iftaFleetSize) > 125000;
    }
    else if (val1 instanceof ResponseContainer) {
      let flSize = <ResponseContainer>val1.accountInfoQsnr.findQuestion('iftaFleetSize')?.answer;
      if (!!!flSize)
        return;
      if (Number(flSize) <= 0)
        this.averageMilageWarning = false;
      else {
        let milesForVehicles = val1.accountInfoQsnr.findQuestion('iftaVehiclesMileage')?.answer;
        this.averageMilageWarning = Number(milesForVehicles) / Number(flSize) > 125000;
      }
    }
  }

  public onStateChanged(event: HTMLSelectElement)
  {
    const value = event.value;
    //empty ifta question answers if state is not IL
    this.averageMilageWarning = false;
    if (!!this.policyHolderData.DAODetailList.iftaVehiclesMileageModal.modal)
    {
      this.policyHolderData.DAODetailList.iftaVehiclesMileageModal.modal = '';
    }
    if (!!this.policyHolderData.DAODetailList.iftaFleetSizeModal.modal)
    {
      this.policyHolderData.DAODetailList.iftaFleetSizeModal.modal = ''
    }    
    this.verifyPolicyHolderVersion(); 
    this.validateOverride('accountStateModal')
    this.resetDateAndStateDependentModalValues();
  }

  public onPolicyEffectiveDateModalChange() {
    this.verifyPolicyHolderVersion(); 
    this.selectEffective();
    this.validateOverride('policyEffectiveDateModal');
    this.validateOverride('policyExpirationDateModal');
    this.resetDateAndStateDependentModalValues();
  }

  public ngOnDestroy(): void {
    if (!!this.meunuClickSubscription)
    this.meunuClickSubscription.unsubscribe();

    if (!!this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
      this.apiCallSubscription = null;
    }

    if (!!this.RollbackDataSubscription) {
      this.RollbackDataSubscription.unsubscribe();
      this.RollbackDataSubscription = null;
    }
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
  defineFleetSizeVisibility(aDAODetailList: DAODetailList, val1: ResponseContainer | any) {
    let selectedFleetSize = val1.accountInfoQsnr.findQuestion('selectedFleetSize');
    if (!!!selectedFleetSize) {
      aDAODetailList.selectedFleetSizeModal.IsHidden = true;
    } else {
      this.isFleetSizeDisabled = false;
      if (!!!aDAODetailList.selectedFleetSizeModal.modal)
        aDAODetailList.selectedFleetSizeModal.modal = val1.dotQsnr.findQuestion('fleetSize')?.answer;
      if (!!aDAODetailList.selectedFleetSizeModal.modal && typeof (aDAODetailList.selectedFleetSizeModal.modal) == 'number')
        aDAODetailList.selectedFleetSizeModal.modal = aDAODetailList.selectedFleetSizeModal.modal.toString();
      if ((this.dao.finalSubmitPage == 'vehicles' || this.dao.finalSubmitPage == 'driver' || this.dao.finalSubmitPage == 'product' || this.dao.finalSubmitPage == 'application')) {
        this.isFleetSizeDisabled = true;
      }
    }
  }

  public commonValidation(): boolean {
    this.dao.DOTLookupStatusMsg = [];
    let isValidComm: boolean = true;    
    if (this.policyHolderData.DAODetailList.commodityPercent1Modal.modal < 5) {
      isValidComm = false;
    }
    //commodities percent are empty
    for (var i = 1; i <= 5; i++) {
      if (this.policyHolderData.DAODetailList['commodityType' + i + 'Modal'].isShow) {
        if (!this.policyHolderData.DAODetailList['commodityPercent' + i + 'Modal'].modal 
                || this.policyHolderData.DAODetailList['commodityPercent' + i + 'Modal'].modal == '' 
                || isNaN(Number(this.policyHolderData.DAODetailList['commodityPercent' + i + 'Modal'].modal))) {
          isValidComm = false;
        }
      }
    }    
    let isContactsNotDuplicated = this.checkDuplicateContact('save');
    if (this.hasDuplicateCommodities())
        isValidComm = false;
    return isValidComm && this.totalCommodityPercent ==100 && isContactsNotDuplicated && (this.checkDateValidation() || this.dao.PolicyTemplate != 'AccountInfo')
  }

  // ========= Check duplicate contact =====================
  public checkDuplicateContact(type: string = ''): boolean {
    this.contactData.IsValidContactInfo = '';
    if (this.contactData.AddContactInformation) {
      this.contactData.AddContactInformation.forEach(element => {
        if (this.contactData.AddContactInformation.filter(c => c.type == element.type).length > 1) {
          this.contactData.IsValidContactInfo = 'Cannot have same Contact Type';
        }
        if (this.contactData.IsValidContactInfo == '' && element.type == 'EMAIL_ADDR' && !this.validateEmails(element.details)) {
          this.contactData.IsValidContactInfo = 'Business Owner email address is invalid';
        }
      });
      if (this.contactData.IsValidContactInfo == '' && type == 'save' && this.contactData.AddContactInformation.filter(c => c.type == 'EMAIL_ADDR').length == 0) {
        this.contactData.IsValidContactInfo = this.dao.businessAddressValidation;
        var TempArray = _.cloneDeep(this.contactData.AddContactInformation);
        TempArray.push({ 'id': this.contactData.AddContactInformation.length + 1, 'type': "EMAIL_ADDR", 'details': "", 'IsOverridecontactTypeModal': false, 'IsOverridecontactDetailModal': false });
        this.contactData.AddContactInformation = _.cloneDeep(TempArray);        
        this.messageService.add({ key: 'myKey1', severity: 'error', summary: 'Policyholder', detail: this.dao.businessAddressValidation });
    
      }
    }
    return this.contactData.IsValidContactInfo == '';
  }

  // ========= Check date validation =====================
  public checkDateValidation():boolean {
    if(!!!this.policyHolderData.DAODetailList.policyEffectiveDateModal.modal)
      return false;
    var minExpDate: Date;
    var maxExpDate: Date;
    minExpDate = new Date(new Date(this.policyHolderData.DAODetailList.policyEffectiveDateModal.minDate).setFullYear(new Date(this.policyHolderData.DAODetailList.policyEffectiveDateModal.minDate).getFullYear() + 1))
    maxExpDate = new Date(new Date(this.policyHolderData.DAODetailList.policyEffectiveDateModal.maxDate).setFullYear(new Date(this.policyHolderData.DAODetailList.policyEffectiveDateModal.maxDate).getFullYear() + 1))
    if (new Date(this.policyHolderData.DAODetailList.policyEffectiveDateModal.minDate).setHours(0, 0, 0, 0) <= new Date(this.policyHolderData.DAODetailList.policyEffectiveDateModal.modal).setHours(0, 0, 0, 0) &&
      new Date(this.policyHolderData.DAODetailList.policyEffectiveDateModal.maxDate).setHours(0, 0, 0, 0) >= new Date(this.policyHolderData.DAODetailList.policyEffectiveDateModal.modal).setHours(0, 0, 0, 0) &&
      minExpDate.setHours(0, 0, 0, 0) <= new Date(this.policyHolderData.DAODetailList.policyExpirationDateModal.modal).setHours(0, 0, 0, 0) &&
      maxExpDate.setHours(0, 0, 0, 0) >= new Date(this.policyHolderData.DAODetailList.policyExpirationDateModal.modal).setHours(0, 0, 0, 0)) {
      return true;
    }
    return false;
  }

  public onBack(page) {
    throw "onBack is not implemented";
  }

  public initializeData(apiResponse: ResponseContainer) {
    if (!!!apiResponse)
      return;
    this.policyHolderData = {
        DAODetailList: new DAODetailList(),
        initialDAODetailList: new DAODetailList(),
        tempDetailList: new DAODetailList(),
      }

    this.contactData = {
        AddContactInformation: [],
        initialAddContactInformation: [],
        TempAddContactInformation: [],
        IsValidContactInfo: ''
      }
    
     this.additionalDotData = {        
        AddAdditionalDOTArray:[],
        initialAddAdditionalDOTArray:[],
        IsAddDOT: false
      }

    this.policyHolderData.DAODetailList = this.createNewDAODetailList(apiResponse);
    this.contactData.AddContactInformation = this.createNewContacts(apiResponse);
    this.defineCommoditiesVisibility(this.policyHolderData.DAODetailList); 
    this.IftaFleetMilesChanged(apiResponse);
    this.defineFleetSizeVisibility(this.policyHolderData.DAODetailList, apiResponse);  

    this.policyHolderData.DAODetailList.tempbillStateModal = this.policyHolderData.DAODetailList.billStateModal;
    this.policyHolderData.initialDAODetailList = _.cloneDeep(this.policyHolderData.DAODetailList);
    this.policyHolderData.tempDetailList = _.cloneDeep(this.policyHolderData.DAODetailList);
    this.contactData.TempAddContactInformation = _.cloneDeep(this.contactData.AddContactInformation);
    this.contactData.initialAddContactInformation = _.cloneDeep(this.contactData.AddContactInformation);
    this.additionalDotData.initialAddAdditionalDOTArray = _.cloneDeep(this.additionalDotData.AddAdditionalDOTArray);   
    this.qaLabel_hasContinuousAutoLiability = this.policyHolderData.DAODetailList?.hasContinuousAutoLiabilityModal?.placeHolder;
    this.setHasContinuousAutoLiabilityQuestionLabel();
  }
  
  createNewDAODetailList(apiResponse: ResponseContainer): DAODetailList
  {
    this.contactData.IsValidContactInfo = '';    
    let tempDAODetailList = new DAODetailList();
    apiResponse.questionnaire.forEach(element => {
        if (element && element.questionAnswer) {
            element.questionAnswer.forEach(obj => {
                if ((obj.key == 'accountDot' && obj.rowIndex == 1) || obj.key != 'accountDot') {
                    if (tempDAODetailList[obj.key + 'Modal']) {
                        if (obj.answer == 'false') {
                            obj.answer = false;
                        }
                        else if (obj.answer == 'true') {
                            obj.answer = true;
                        }
                        tempDAODetailList[obj.key + 'Modal'].modal = obj.answer;
                        tempDAODetailList[obj.key + 'Modal'].IsHidden = obj.hidden;
                        tempDAODetailList[obj.key + 'Modal'].IsRequired = obj.required;
                        tempDAODetailList[obj.key + 'Modal'].minDate = (obj.minimum && obj.minimum != '' ? this.serviceLayer.getCorrectDate(obj.minimum) : null);
                        tempDAODetailList[obj.key + 'Modal'].maxDate = (obj.maximum && obj.maximum != '' ? this.serviceLayer.getCorrectDate(obj.maximum) : null);;
                        tempDAODetailList[obj.key + 'Modal'].placeHolder = (obj.questionHelpText && obj.questionHelpText != '') ? obj.questionHelpText : (obj.questionLabel != undefined ? obj.questionLabel.replace(':', '') : '');
                        tempDAODetailList[obj.key + 'Modal'].status = obj.status;
                        if ((obj.answer == null || obj.answer == "") && obj.questionDefault && obj.questionDefault.length > 0 && obj.questionDefault[0].code && obj.questionDefault[0].code != '') {
                            // do not use default policyEffectiveDate From ODM for older ODM Versions
                          if (obj.key != 'policyEffectiveDate' && obj.key != 'policyExpirationDate') {
                            tempDAODetailList[obj.key + 'Modal'].modal = obj.type == 'Date' ? this.serviceLayer.getCorrectDate(obj.questionDefault[0].code) : obj.questionDefault[0].code;
                          }
                          else{
                            if(apiResponse.dotQsnr.findQuestion("dotEffectiveDate") && apiResponse.dotQsnr.findQuestion("dotExpirationDate")){
                              // use default policyEffectiveDate and policyExpirationDate From ODM
                              tempDAODetailList[obj.key + 'Modal'].modal = this.serviceLayer.getCorrectDate(obj.questionDefault[0].code);
                            }else{
                              tempDAODetailList[obj.key + 'Modal'].modal = this.serviceLayer.getCorrectDate(obj.answer);
                            }
                          }
                        } else {
                            tempDAODetailList[obj.key + 'Modal'].modal = obj.type == 'Date' ? this.serviceLayer.getCorrectDate(obj.answer) : obj.answer;
                        }
  
                        if (obj.key.toLowerCase().includes('postalcode') && !!tempDAODetailList[obj.key + 'Modal'].modal && tempDAODetailList[obj.key + 'Modal'].modal.length < 5) {
                            //if postal code is less than 5 digits, pad it with 0
                              let padStr = tempDAODetailList[obj.key + 'Modal'].modal.padStart(5, '0');
                              tempDAODetailList[obj.key + 'Modal'].modal = padStr;
                          }
                          tempDAODetailList[obj.key + 'Modal'].IsExistsData = (tempDAODetailList[obj.key + 'Modal'].modal != undefined && tempDAODetailList[obj.key + 'Modal'].modal != "");
  
                          if (obj.questionOptions && obj.questionOptions.length > 0) {
                              tempDAODetailList[obj.key + 'Modal'].dropdown = obj.questionOptions;
                          }
                      }
                  }
              });
          }
      });
      return tempDAODetailList;
  }
  
  createNewContacts(apiResponse: ResponseContainer): Array<Contact>
    {
      let getContactQueryIndex = '$..questionnaire[?(@.key =="AccountInfo")].questionAnswer[?(@.key =="contactDetail" || @.key =="contactType")].rowIndex';
      let getcontactIndex = _.max(this.serviceLayer.getObjUsingJpath(apiResponse, getContactQueryIndex));
      let tmpAddContactInformation: Array<Contact> = [];

      for (let i = 0; i <= getcontactIndex; i++) {
          let getContactDetailQuery = '$..questionnaire[?(@.key =="AccountInfo")].questionAnswer[?(@.key =="contactDetail" && @.rowIndex ==' + i + ')]';
          let getContacTypeQuery = '$..questionnaire[?(@.key =="AccountInfo")].questionAnswer[?(@.key =="contactType" && @.rowIndex ==' + i + ')]';
          let getcontactDetailDatas = _.max(this.serviceLayer.getObjUsingJpath(apiResponse, getContactDetailQuery));
          let getcontactTypeDatas = _.max(this.serviceLayer.getObjUsingJpath(apiResponse, getContacTypeQuery));
          if (getcontactTypeDatas && getcontactDetailDatas) {
              let contactType = getcontactTypeDatas.answer;
              if (i == 1 && !!!contactType) {
                  contactType = getcontactTypeDatas.questionDefault[0].code;
              }
              tmpAddContactInformation.push({
                  'id': i + 1, 
                  'type': contactType, 
                  'details': getcontactDetailDatas.answer, 
                  'IsOverridecontactTypeModal': false, 
                  'IsOverridecontactDetailModal': false
              });
          }
      }
      if (tmpAddContactInformation.length == 0) {
        tmpAddContactInformation.push({ 'id': tmpAddContactInformation.length + 1, 'type': "EMAIL_ADDR", 'details': "", 'IsOverridecontactTypeModal': false, 'IsOverridecontactDetailModal': false });
    }

      return tmpAddContactInformation;
    }
  
    defineCommoditiesVisibility(thisdaoDAODetailList: DAODetailList)
    {
      thisdaoDAODetailList.commodityType1Modal.isShow = true;
      thisdaoDAODetailList.commodityType2Modal.isShow = false;
      thisdaoDAODetailList.commodityType3Modal.isShow = false;
      thisdaoDAODetailList.commodityType4Modal.isShow = false;
      thisdaoDAODetailList.commodityType5Modal.isShow = false;
      if (thisdaoDAODetailList.commodityType2Modal.modal && thisdaoDAODetailList.commodityType2Modal.modal != '') {
          thisdaoDAODetailList.commodityType2Modal.isShow = true;          
      }
      if (thisdaoDAODetailList.commodityType3Modal.modal && thisdaoDAODetailList.commodityType3Modal.modal != '') {
          thisdaoDAODetailList.commodityType3Modal.isShow = true;          
      }
      if (thisdaoDAODetailList.commodityType4Modal.modal && thisdaoDAODetailList.commodityType4Modal.modal != '') {
          thisdaoDAODetailList.commodityType4Modal.isShow = true;          
      }
      if (thisdaoDAODetailList.commodityType5Modal.modal && thisdaoDAODetailList.commodityType5Modal.modal != '') {
          thisdaoDAODetailList.commodityType5Modal.isShow = true;               
      }
      this.commodityValidationFlags.isEligible = this.isCommodityTypeEligible(2) && this.isCommodityTypeEligible(3)
                && this.isCommodityTypeEligible(3) && this.isCommodityTypeEligible(4)
    }

      //bind Additional DOT
  public bindAdditionalDOT() {
        this.additionalDotData.AddAdditionalDOTArray = [];
        let additionalDOTObj = _.cloneDeep(this.serviceLayer.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key =="dot")].questionAnswer[?(@.key == "accountDot" && @.rowIndex > 1)]'));
        if (additionalDOTObj && additionalDOTObj.length > 0) {
            additionalDOTObj.forEach((dot, ind) => {
                dot.rowIndex = ind + 1;
                var TempArray = _.cloneDeep(this.additionalDotData.AddAdditionalDOTArray);
                TempArray.push({ 'id': this.additionalDotData.AddAdditionalDOTArray.length + 1, 'answer': dot.answer, 'group': '', 'key': '' });
                this.additionalDotData.AddAdditionalDOTArray = _.cloneDeep(TempArray);
                
            })
        }
        this.additionalDotData.IsAddDOT = this.additionalDotData.AddAdditionalDOTArray.length>0;
        this.additionalDotData.initialAddAdditionalDOTArray = _.cloneDeep(this.additionalDotData.AddAdditionalDOTArray);
  }
    
  copyDataToResponseContainer(anDaoDetailList: DAODetailList)
  {
    var IsAddedContact: boolean = false;
    if (this.dao.DOTList.questionnaire) {
        this.dao.DOTList.questionnaire.forEach(parent => {
            if (parent && parent.questionAnswer && parent.questionAnswer.length > 0) {
                _.remove(parent.questionAnswer, function (n) {
                    return (n.key == 'contactType' || n.key == 'contactDetail') && n.rowIndex > 1;
                })
                if (parent.key == "AccountInfo" && this.contactData.AddContactInformation && this.contactData.AddContactInformation.length > 0 && !IsAddedContact && parent.questionAnswer.filter(c => c.key == "contactType")[0]) {
                    this.contactData.AddContactInformation.forEach((contact, index) => {
                        //============set value to contacts========
                        if (index != 0) {
                            var TempContactModel = _.cloneDeep(parent.questionAnswer.filter(c => c.key == "contactType")[0]);
                            var TempContactTypeModel = _.cloneDeep(parent.questionAnswer.filter(c => c.key == "contactDetail")[0]);
                            TempContactModel.rowIndex = index + 1;
                            TempContactTypeModel.rowIndex = index + 1;
                            TempContactModel.answer = contact.type;
                            TempContactTypeModel.answer = contact.details;
                            parent.questionAnswer.push(TempContactModel)
                            parent.questionAnswer.push(TempContactTypeModel)
                        } else {
                            let getContactTypeIndex = parent.questionAnswer.findIndex(c => c.key == "contactType");
                            let getContactDetailIndex = parent.questionAnswer.findIndex(c => c.key == "contactDetail");
                            parent.questionAnswer[getContactTypeIndex].answer = contact.type;
                            parent.questionAnswer[getContactDetailIndex].answer = contact.details;
                        }
                    });
                    IsAddedContact = true;
                }
                parent.questionAnswer.forEach(child => {
                    if (anDaoDetailList[child.key + 'Modal'] != undefined && anDaoDetailList[child.key + 'Modal']["modal"] != undefined) {
                        if (child.key != 'contactType' && child.key != 'contactDetail') {
                            child.answer = anDaoDetailList[child.key + 'Modal']["modal"];
                            child.status = anDaoDetailList[child.key + 'Modal']["status"];
                        }
                    }
                });
                // Add Additional DOT
                if (parent.key == 'dot') {
                    _.remove(parent.questionAnswer, function (n) {
                        return (n.group != 'dot' || n.rowIndex > 1) && n.key == 'accountDot';
                    })
                    let additionalDOTObj = _.cloneDeep(this.serviceLayer.getObjUsingJpath(this.dao.DOTList, '$..questionnaire[?(@.key =="dot")].questionAnswer[?(@.key == "accountDot")]')[0]);
                    if (this.additionalDotData.AddAdditionalDOTArray && this.additionalDotData.AddAdditionalDOTArray.length > 0) {
                        this.additionalDotData.AddAdditionalDOTArray.forEach((dot, i) => {
                            let tempArray = _.cloneDeep(additionalDOTObj);
                            tempArray.answer = dot.answer;
                            tempArray.rowIndex = i + 2;
                            additionalDOTObj = _.cloneDeep(tempArray);
                            parent.questionAnswer.push(additionalDOTObj);
                        })
                    }
                }
            }
        })
    }
    //This is used in service layer.SaveDetails to rebind product data
    this.dao["isBusinessClassChanged"] = this.policyHolderData.DAODetailList.businessClassModal.modal != this.policyHolderData.initialDAODetailList.businessClassModal.modal;
  }

  menuClicked(menuClickContext: MenuClickContext) {
    if (menuClickContext != null && menuClickContext.page != QuestionnaireKeys.AccountInfo) {
      menuClickContext.currentPageIsValid = this.isPageValid();
      menuClickContext.currentPageDataChanged = 
          !_.isEqual(this.policyHolderData.DAODetailList, this.policyHolderData.initialDAODetailList) ||
          !_.isEqual(this.contactData.AddContactInformation, this.contactData.initialAddContactInformation) ||
          !_.isEqual(this.additionalDotData.AddAdditionalDOTArray, this.additionalDotData.initialAddAdditionalDOTArray);
    }
  }

  isPageValid(): boolean {
      return  this.validateAllForms(this.accountForm, this.contactForm, this.commodityForm)
              && this.commonValidation()
              && this.checkDateValidation();
  }

  onRollback(currentpage) {
    if (currentpage == QuestionnaireKeys.AccountInfo) {
      this.policyHolderData.DAODetailList = _.cloneDeep(this.policyHolderData.initialDAODetailList);
      this.contactData.AddContactInformation = _.cloneDeep(this.contactData.initialAddContactInformation);
      this.additionalDotData.AddAdditionalDOTArray = _.cloneDeep(this.additionalDotData.initialAddAdditionalDOTArray);         
    }
  }

  updateIftaValues() {
    if (this.policyHolderData.DAODetailList.doesFileIftaModal.modal === 'Yes') {
      this.displayIftaQuestions = true;
    } else {
      this.policyHolderData.DAODetailList.iftaVehiclesMileageModal.modal = '';
      this.policyHolderData.DAODetailList.iftaFleetSizeModal.modal = '';
      this.displayIftaQuestions = false;
    }
  }

  public verifyPolicyHolderVersion(): void {
    let policyEffectiveDate: Date = this.policyHolderData.DAODetailList?.policyEffectiveDateModal.modal;
    let accountState: string = this.policyHolderData.DAODetailList.accountStateModal.modal

    if(!this.serviceLayer.getPolicyHolderVersion(policyEffectiveDate, accountState)) {
      this.isPolicyEffectiveJuly = false;
      this.isNewTrucking = false;
      return;
    }

    this.isPolicyEffectiveJuly = true;
    if(this.serviceLayer.getPolicyHolderVersionNTM(policyEffectiveDate, accountState)) {
      this.isNewTrucking = true;
      this.updateIftaValues();
    } else {
      this.isNewTrucking = false;
      this.displayIftaQuestions = true;
    }
  }

  /**
   * Dynamically update the hasContinuousAutoLiability label since the label
   * has a dynamic date value that needsto be calculated at runtime based on
   * the policy effective date.
   */
  private setHasContinuousAutoLiabilityQuestionLabel(): void {
    const pastPolEffDate = new Date(this.serviceLayer.getCorrectDate(this.policyHolderData.DAODetailList.policyEffectiveDateModal.modal))
    pastPolEffDate.setFullYear(pastPolEffDate.getFullYear() - 3);
    const formattedDate = this.datePipe.transform(pastPolEffDate, 'MM/dd/yyyy');
    this.hasContinuousAutoLiabilityLabel = this.qaLabel_hasContinuousAutoLiability.replace('(policy effective date – 3yrs)', formattedDate);
  }

  private resetDateAndStateDependentModalValues() {
    if (this.isPolicyEffectiveJuly && this.isNewTrucking) {
      return;
    }

    this.policyHolderData.DAODetailList.vehicleTravelModal.modal = '';
    this.policyHolderData.DAODetailList.vehicleMileModal.modal = '';
    this.policyHolderData.DAODetailList.doesFileIftaModal.modal = '';
    this.policyHolderData.DAODetailList.currentCarrierInsuredInYearsModal.modal = '';
    this.policyHolderData.DAODetailList.currentCarrierInsuredInMonthsModal.modal = ''
    this.policyHolderData.DAODetailList.hasContinuousAutoLiabilityModal.modal = '';
  }
}
