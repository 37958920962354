import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { ServiceLayer } from '../../services/serviceLayer';
import { DAO, emptyModal, vehicleDetails } from '../../modal/DAO'
import { MenuClickContext } from '../../modal/routing.models'
import * as $ from "jquery";
import { MessageService } from 'primeng/api';
import _ from 'lodash';
import { AppComponent } from '../../app.component';
import { NgForm } from '@angular/forms';
import { ProductService } from '../../services/product.service';
import { StateSettings } from 'src/app/modal/state.models';
import * as XLSX from 'xlsx';
import { UserInputService } from 'src/app/services/userInput.service'
import { CommonService } from 'src/app/services/commonService';
import { Subscription } from 'rxjs';
import { QuestionAnswer } from 'src/app/modal/questionnaire.models';
import { Constants } from 'src/app/modal/ProjConstants.models';

@Component({
    selector: 'app-vehicles',
    templateUrl: './vehicles.component.html',
    styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit, OnDestroy, AfterContentChecked {
    public ViewMsg: string = 'view more >>';
    @ViewChild('firstForm') firstForm: NgForm;
    @ViewChild('secondForm') secondForm: NgForm;
    @ViewChild('thirdForm') thirdForm: NgForm;
    @ViewChild('insuredForm') insuredForm: NgForm;
    @ViewChild('radiusForm') radiusForm: NgForm;
    @ViewChild('collisionDeductibleForm') collisionDeductibleForm: NgForm;
    @ViewChild('compDeductibleForm') compDeductibleForm: NgForm;
    @ViewChild('garagingInformationForm') garagingInformationForm: NgForm;
    @ViewChild('customPartsForm') customPartsForm: NgForm;
    @ViewChild('powerUnitList') powerUnitList: NgForm;
    @ViewChild('trailerList') trailerList: NgForm;
    @ViewChild('vehicleForm') vehicleForm: NgForm;

    public ViewMsg1: string = 'view more >>';
    public actionType: string = '';
    public editRadiusForAllUnits: boolean = false;
    public isUpdateUnit: boolean = false;
    public editTabIndex: boolean = true;
    public EditUnitactiveIndex: number = 0;
    public ValidateRadiusQuestionInEdit: boolean = false;
    public isShowCollisionCoverageCheckBox: boolean = false;
    masterDeleteCheckBoxValue: boolean = false;
    masterTrailerDeleteCheckBoxValue: boolean = false;
    excelPowerUnits: any;
    excelTrailers: any;
    excelEvent: any;
    powerUnitsLengthBeforeUpload: number;
    trailerLengthBeforeUpload: number;
    areVinNumbersDuplicated: boolean = false;
    vehicleAgeWarning: boolean = false;
    private apiCallSubscription: Subscription;
    private uploadedExcelWorkBook: any;

    constructor(public serviceLayer: ServiceLayer, public dao: DAO, private messageService: MessageService,
        public appComponent: AppComponent, public bulkeditCheckbox: ChangeDetectorRef,
        private productService: ProductService, public stateSettings: StateSettings, private userInputService: UserInputService,
        private commonService: CommonService) { }
    // ========= Page Load =====================
    ngOnInit(): void {
        setTimeout(() => {
            this.apiCallSubscription = this.serviceLayer.dotSubmissionDetails.subscribe(
                (apiResponseContainer) => {
                    if(!!!apiResponseContainer) {
                        return;
                    }

                    this.vehicleOnInit();
                    this.dao.IsVehicleOnInItCall = true;
                    if (this.dao.vehicleModel.VehiclesList.length >= this.dao.MaximumVehicle) {
                        this.dao.HideVechicleButton = true;
                    }
                    if (this.dao.vehicleModel.trailerList.length >= this.dao.MaximumTrailer) {
                        this.dao.HideTrailerButton = true;
                    }
                });
        }, 100);
    }
    ngAfterContentChecked(): void {
        this.bulkeditCheckbox.detectChanges();
    }
    // ========= Continue =====================
    public async Continue(step: number, ngform: NgForm, key: string) {
        await this.callRadiusAndCoverageToAllUnits();
        let errmsg = this.validateVehicleData(ngform, step, false);

        if (errmsg != '') {
            ngform.form.setErrors({ 'invalid': true });
            this.appComponent.addToast('error', 'Unit Info', errmsg);
            return;
        }
        if (ngform.form.valid) {
            this.vehicleContinue(step, key);
        }
        else {
            this.appComponent.addToast('error', 'Unit Info', 'Please answer the required questions.');
        }
    }
    public AddZone() {
        var tempArray = { 'zone': '', 'percentage': '', 'helpText': '' };
        let validationStr = this.checkSameZone(this.dao.vehicleModel.vehicleItem.zoneList);
        if (validationStr == '') {
            var tempObj = _.cloneDeep(this.dao.vehicleModel.vehicleItem.zoneList);
            tempObj.push(tempArray);
            this.dao.vehicleModel.vehicleItem.zoneList = tempObj;
        }
        else {
            this.appComponent.addToast('error', 'Unit Info', validationStr);
        }
    }
    public zoneChange(i) {
        if (this.dao.vehicleModel.vehicleDefault.vehicleToZone.questionOptions && this.dao.vehicleModel.vehicleDefault.vehicleToZone.questionOptions.filter(c => c.value == this.dao.vehicleModel.vehicleItem.zoneList[i].zone) && this.dao.vehicleModel.vehicleDefault.vehicleToZone.questionOptions.filter(c => c.value == this.dao.vehicleModel.vehicleItem.zoneList[i].zone).length > 0) {
            var hoverText = this.dao.vehicleModel.vehicleDefault.vehicleToZone.questionOptions.filter(c => c.value == this.dao.vehicleModel.vehicleItem.zoneList[i].zone)[0].helpText;
            this.dao.vehicleModel.vehicleItem.zoneList[i].helpText = hoverText;
        }
    }
    // ========= Save vehicle =====================
    public async SaveUnit(evt: Event) {
        this.areVinNumbersDuplicated = false;
        this.vehicleForm?.form.setErrors(null);
        let invalidVehicleListIndexes = this.validatingVehicleList();
        if (invalidVehicleListIndexes.invalidPowerUnitIndex.length > 0 || invalidVehicleListIndexes.invalidTrailerIndex.length > 0) {
            if (this.dao.IsSavedFromMenu) {
                this.dao.NotAllowedToSubmit = true;
            } else {
                this.dao.isClickFromMenu = false;
                this.openToast('Must review and complete each vehicle details highlighted in red');
            }
            return;
        }
        let duplicateVehicleListIndexes = this.checkDuplicateVinNumberExists('save');
        if (duplicateVehicleListIndexes.duplicatePowerUnitIndex.length > 0 || duplicateVehicleListIndexes.duplicateTrailerIndex.length > 0) {
            if (this.dao.IsSavedFromMenu) {
                this.dao.NotAllowedToSubmit = true;
            } else {
                this.dao.isClickFromMenu = false;
                this.openToast('Duplicate VIN numbers have been identified, please correct the vehicles highlighted in red');
            }
            return;
        }
        if (this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length == 0) {
            if (this.dao.IsSavedFromMenu) {
                this.dao.NotAllowedToSubmit = true;
            } else {
                this.dao.isClickFromMenu = false;
                this.appComponent.addToast('error', 'Unit Info', 'At least one vehicle must be entered before continuing.');
            }
            return;
        }
        let fleetSizeAnswer = this.dao.DOTList.accountInfoQsnr.findQuestion('selectedFleetSize')?.answer;
        if (!!fleetSizeAnswer && this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length != parseInt(fleetSizeAnswer)) {
            this.dao.DOTList.accountInfoQsnr.findQuestion('selectedFleetSize').answer = this.dao.vehicleModel.VehiclesList.length;
        }
        await this.callRadiusAndCoverageToAllUnits();
        // Vehicle Age validation
        let vehicleAge = this.dao.DOTList.vehicleQsnr.findQuestion("vehicleAge");
        if (vehicleAge != null && this.vehicleAgeWarning == false && this.hasInvalidPowerUnitAge()) {
            document.body.style.pointerEvents = "none";
            this.messageService.add({ key: 'vehicle_age_validation', sticky: true, severity: 'warn', data: 'Your submission will be declined because of the vehicle age profile' });
            return;
        }
        this.dao.NotAllowedToSubmit = false;
        let questionnaire = this.productService.getQuestionnaire();
        let municipalLocation = questionnaire?.questionAnswer?.find(x => x?.key === 'municipalLocation');
        if (municipalLocation) {
            municipalLocation.questionOptions = [];
            if (!this.checkGaragingAddress()) {
                municipalLocation.answer = "CLICK";
            }
        }
        this.dao.IsDriverBreadcrumpClicked = false;
        await this.serviceLayer.save();
        if (this.dao.DOTLookupStatusMsg != null && this.dao.DOTLookupStatusMsg.length > 0) {
            if (this.dao.IsSavedFromMenu) {
                this.dao.NotAllowedToSubmit = true;
            } else {
                document.body.style.pointerEvents = "none";
                this.messageService.add({ key: 'Valitation', sticky: true, severity: 'warn', data: this.dao.DOTLookupStatusMsg });
            }
        }
    }

    atleastOneTrailerSelected() {
        let isAtleastOneVehicleSelected = false;
        for (let i = 0; i < this.dao.vehicleModel.trailerList.length; i++) {
            if (this.dao.vehicleModel.trailerList[i].isChecked) {
                isAtleastOneVehicleSelected = true;
            }
        }
        if (!isAtleastOneVehicleSelected) {
            this.openToast('At least one trailer must be selected');
        }
        else {
            this.actionType = 'multipletrailer';
            this.removeSelectedVehicles('trailer');
        }

    }

    atleastOnePowerUnitSelected() {
        let isAtleastOneVehicleSelected = false;
        for (let i = 0; i < this.dao.vehicleModel.VehiclesList.length; i++) {
            if (this.dao.vehicleModel.VehiclesList[i].isChecked) {
                isAtleastOneVehicleSelected = true;
            }
        }
        if (!isAtleastOneVehicleSelected) {
            this.openToast('At least one power unit must be selected');
        }
        else {
            this.actionType = 'multiplevehicle';
            this.removeSelectedVehicles('vehicle');
        }
    }

    onVehicleCheckBoxChange(event) {

        if (this.dao.vehicleModel.trailerList.some(item => item.isChecked)) {
            this.dao.vehicleModel.trailerList.forEach(item => item.isChecked = false);
            this.masterTrailerDeleteCheckBoxValue = false;
        }
        if (event.checked) {
            if (this.dao.vehicleModel.VehiclesList.every(item => item.isChecked == true)) {
                this.masterDeleteCheckBoxValue = true;
            }
        }
        else {
            this.masterDeleteCheckBoxValue = false;
        }
    }

    onTrailerCheckBoxChanged(event) {
        if (this.dao.vehicleModel.VehiclesList.some(item => item.isChecked)) {
            this.dao.vehicleModel.VehiclesList.forEach(item => item.isChecked = false);
            this.masterDeleteCheckBoxValue = false;
        }
        if (event.checked) {
            if (this.dao.vehicleModel.trailerList.every(item => item.isChecked)) {
                this.masterTrailerDeleteCheckBoxValue = true;
            }
        }
        else {
            this.masterTrailerDeleteCheckBoxValue = false;
        }
    }

    onChangeMasterTrailerCheckBox(event) {
        for (let i = 0; i < this.dao.vehicleModel.trailerList.length; i++) {
            if (event.checked) {
                this.dao.vehicleModel.trailerList[i].isChecked = true;
                this.masterTrailerDeleteCheckBoxValue = true;

                this.dao.vehicleModel.VehiclesList.forEach(item => item.isChecked = false);
                this.masterDeleteCheckBoxValue = false;
            }
            else {
                this.dao.vehicleModel.trailerList[i].isChecked = false;
                this.masterTrailerDeleteCheckBoxValue = false;
            }
        }
    }

    onChangeMasterPowerUnitCheckBox(event) {
        for (let i = 0; i < this.dao.vehicleModel.VehiclesList.length; i++) {
            if (event.checked) {
                this.dao.vehicleModel.VehiclesList[i].isChecked = true;
                this.masterDeleteCheckBoxValue = true;

                this.dao.vehicleModel.trailerList.forEach(item => item.isChecked = false);
                this.masterTrailerDeleteCheckBoxValue = false;
            }
            else {
                this.dao.vehicleModel.VehiclesList[i].isChecked = false;
                this.masterDeleteCheckBoxValue = false;
            }
        }
    }

    // ======== Check for Any Address Changes ==============
    public checkGaragingAddress(): boolean {

        // Vehicle Address
        const NewVehicleGaragingAddress = this.dao.vehicleModel.VehiclesList.map((vehicle) => {
            return {
                vehicleGarageStreetAddress: vehicle.vehicleGarageStreetAddress,
                vehicleGaragePostalCode: vehicle.vehicleGaragePostalCode,
                vehicleGarageCity: vehicle.vehicleGarageCity,
                vehicleGaragingAddress: vehicle.vehicleGaragingAddress,
                vehicleIsCorrectGaragingAddress: vehicle.vehicleIsCorrectGaragingAddress
            }
        });
        const initialVehicleGaragingAddress = this.dao.initialvehicleModel.VehiclesList.map((vehicle) => {
            return {
                vehicleGarageStreetAddress: vehicle.vehicleGarageStreetAddress,
                vehicleGaragePostalCode: vehicle.vehicleGaragePostalCode,
                vehicleGarageCity: vehicle.vehicleGarageCity,
                vehicleGaragingAddress: vehicle.vehicleGaragingAddress,
                vehicleIsCorrectGaragingAddress: vehicle.vehicleIsCorrectGaragingAddress
            }
        });

        // Trailer Address
        const trailerListGaragingAddress = this.dao.vehicleModel.trailerList.map((trailer) => {
            return {
                vehicleGarageStreetAddress: trailer.vehicleGarageStreetAddress,
                vehicleGaragePostalCode: trailer.vehicleGaragePostalCode,
                vehicleGarageCity: trailer.vehicleGarageCity,
                vehicleGaragingAddress: trailer.vehicleGaragingAddress,
                vehicleIsCorrectGaragingAddress: trailer.vehicleIsCorrectGaragingAddress
            }
        });
        const initialtrailerListGaragingAddress = this.dao.initialvehicleModel.trailerList.map((trailer) => {
            return {
                vehicleGarageStreetAddress: trailer.vehicleGarageStreetAddress,
                vehicleGaragePostalCode: trailer.vehicleGaragePostalCode,
                vehicleGarageCity: trailer.vehicleGarageCity,
                vehicleGaragingAddress: trailer.vehicleGaragingAddress,
                vehicleIsCorrectGaragingAddress: trailer.vehicleIsCorrectGaragingAddress
            }
        });

        return _.isEqual(NewVehicleGaragingAddress, initialVehicleGaragingAddress) && _.isEqual(trailerListGaragingAddress, initialtrailerListGaragingAddress);
    }

    // ========= Remove vehicle =====================
    remove(item, i, actionType) {
        this.dao.deletedItem = item;
        this.dao.deletedIndex = i;
        this.actionType = actionType;
        const delData = {
            deletedItem: item,
            deletedIndex: i,
            deleteType: actionType
        }
        const strData = JSON.stringify(delData);
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'remove', sticky: true, severity: 'warn', summary: ' Do you want to delete ?', data: strData });
    }

    // ========= Confirm Remove vehicle =====================
    public async confirmRemove() {
        if (this.actionType == 'vehicle') {
            await this.removeVehicle(this.dao.deletedItem);
            this.removingHighlightionOfInvalidVehicle('delete');
            await this.VehicleSaveDraft();
            if (this.dao.vehicleModel.VehiclesList.length >= this.dao.MaximumVehicle) {
                this.dao.HideVechicleButton = true;
            } else {
                this.dao.HideVechicleButton = false;
            }
            if (!this.dao.isTrailer) {
                this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex];
            }
        }
        if (this.actionType == 'multiplevehicle') {
            await this.confirmRemoveSelectedVehicles();
            if (this.dao.vehicleModel.VehiclesList.length >= this.dao.MaximumVehicle) {
                this.dao.HideVechicleButton = true;
            } else {
                this.dao.HideVechicleButton = false;
            }
            if (this.dao.vehicleModel.VehiclesList.length == 0) {
                this.dao.vehicleModel.vehicleItem = new vehicleDetails();
                this.serviceLayer.addVehicle('vehicle');
            }
        }
        if (this.actionType == 'trailer' || this.actionType == 'multipletrailer') {
            if (this.actionType == 'trailer') {
                await this.removeTrailer(this.dao.deletedItem);
                this.removingHighlightionOfInvalidVehicle('delete');
                await this.VehicleSaveDraft();
            }
            else {
                await this.confirmRemoveSelectedTrailers();
            }

            if (this.dao.vehicleModel.trailerList.length >= this.dao.MaximumTrailer) {
                this.dao.HideTrailerButton = true;
            } else {
                this.dao.HideTrailerButton = false;
            }
            if (this.dao.isTrailer) {
                this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex];
            }
        }
        if (this.actionType == 'interest') {
            this.dao.vehicleModel.vehicleItem.interestList.splice(this.dao.deletedIndex, 1);
        }
        if (this.actionType == 'zone') {
            this.dao.vehicleModel.vehicleItem.zoneList.splice(this.dao.deletedIndex, 1);
        }
        if (this.actionType == 'Editzone') {
            this.dao.editAllUnits.zoneList.splice(this.dao.deletedIndex, 1)
        }
        if (this.actionType == 'removeEditInterest') {
            this.dao.editAllUnits.interestList.splice(this.dao.deletedIndex, 1);
            this.insuredForm.form.markAsDirty();
        }
        if (this.actionType == 'confirmExcel') {
            this.ensureTrailerDetails();
        }
        this.messageService.clear('remove')
        document.body.style.pointerEvents = "visible";
    }
    // ========= Numbers only =====================
    public numbersOnly(e) {
        return this.serviceLayer.numbersOnly(e, 'vehicle');
    }
    // ========= Get Total Percent=====================
    public getTotalPercent(vehicleTotalMilesUpto50Answer, vehicleTotalMilesUpto200Answer, zoneList, farthestApplicableZonePercent) {
        let totalvalue = 0;
        if (this.dao.vehicleModel.vehicleDefault.farthestApplicableZonePercent.enabled) {
            totalvalue += (isNaN(Number(farthestApplicableZonePercent)) ? 0 : Number(farthestApplicableZonePercent));
        } else if (zoneList.length > 0) {
            zoneList.forEach(z => {
                totalvalue = totalvalue + (isNaN(Number(z.percentage)) ? 0 : Number(z.percentage));
            });
        }
        totalvalue = totalvalue + (isNaN(Number(vehicleTotalMilesUpto50Answer)) ? 0 : Number(vehicleTotalMilesUpto50Answer)) + (isNaN(Number(vehicleTotalMilesUpto200Answer)) ? 0 : Number(vehicleTotalMilesUpto200Answer));
        return totalvalue == 100;
    }
    // ========= Cancel my submission =====================
    public CancelmySubmission() {
        this.messageService.clear('c');
        document.body.style.pointerEvents = "visible";
        if (this.actionType == 'ensureTrailerDetails') {
            this.validateTemplateVersion();
        }
        else {
            return this.serviceLayer.CancelmySubmission();
        }
    }
    // ========= View click =====================
    viewClick(type) {
        if (type == 'Vehicles') {
            this.ViewMsg = (this.ViewMsg == 'view more >>' ? 'view less <<' : 'view more >>');
            if (this.ViewMsg == 'view less <<') {
                $((type == 'Vehicles') ? '.menulen' : '.tra .menulen').addClass('ovrflvsbl');
            } else {
                $((type == 'Vehicles') ? '.menulen' : '.tra .menulen').removeClass('ovrflvsbl');
            }
        }
    }
    viewClick1(type) {
        if (type == 'Trailers') {
            this.ViewMsg1 = (this.ViewMsg1 == 'view more >>' ? 'view less <<' : 'view more >>');
            if (this.ViewMsg1 == 'view less <<') {
                $((type == 'Trailers') ? '.menulen1' : '.tra .menulen1').addClass('ovrflvsbl');
            } else {
                $((type == 'Trailers') ? '.menulen1' : '.tra .menulen1').removeClass('ovrflvsbl');
            }
        }
    }
    // ========= Add vehicle=====================

    public validateVehicleData(frm: NgForm, step: number, validateAllForms: boolean) {
        let errmsg = "";
        let forms: NgForm[] = [this["firstForm"], this["secondForm"], this["thirdForm"]];

        if (validateAllForms && !this.serviceLayer.validateRequiredFieldAnsweredAllForms(forms))
            errmsg = 'Please answer the required questions.\n';
        else if (!validateAllForms && !this.serviceLayer.validateRequiredFieldAnswered(frm))
            errmsg = 'Please answer the required questions.\n';

        if (step == 1 || validateAllForms) {
            if (this.dao.vehicleModel.vehicleItem.vehicleYear.answer != null && this.dao.vehicleModel.vehicleItem.vehicleVin.answer != null && this.dao.vehicleModel.vehicleItem.vehicleType.group.indexOf('VEH') > -1
                && this.dao.vehicleModel.vehicleItem.vehicleVin.answer.length > 0) {
                if (this.dao.vehicleModel.vehicleItem.vehicleYear.answer < 1981
                    && (this.dao.vehicleModel.vehicleItem.vehicleVin.answer.length > 13 || this.dao.vehicleModel.vehicleItem.vehicleVin.answer.length < 5)) {
                    this["firstForm"].form.controls['txtVCVIN'].setErrors({ 'incorrect': true });
                    errmsg += 'Vehicle VIN # must be minimum of 5 and maximum of 13 characters.';
                }
                else if (this.dao.vehicleModel.vehicleItem.vehicleYear.answer >= 1981 && this.dao.vehicleModel.vehicleItem.vehicleVin.answer.length != 17) {
                    this["firstForm"].form.controls['txtVCVIN'].setErrors({ 'incorrect': true });
                    errmsg += 'Vehicle VIN # must be equal to 17 characters.\n';
                }
            }
        }
        if (step == 2 || validateAllForms) {

            let zoneValidation = "";
            if (step != 2) {
                //No need to pop up because the following error has already been displayed in the page
                zoneValidation = this.getTotalPercent(this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto50.answer, this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto200.answer, this.dao.vehicleModel.vehicleItem.zoneList, this.dao.vehicleModel.vehicleItem.farthestApplicableZonePercent.answer) ? '' : 'Total radius percentage must equal 100%\n';
                if (zoneValidation != null && zoneValidation != "")
                    errmsg += zoneValidation + "\n";
                zoneValidation = '';
                zoneValidation = this.checkSameZone(this.dao.vehicleModel.vehicleItem.zoneList);
                if (zoneValidation != null && zoneValidation != "")
                    errmsg += zoneValidation + "\n";
            }

            if (this.dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer == null || this.dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer == '')
                errmsg += 'Is this the correct garaging address is not answered.\n';
            if ((this.dao.vehicleModel.vehicleItem.vehicleTravelOver200.answer == null || this.dao.vehicleModel.vehicleItem.vehicleTravelOver200.answer == '') && this.dao.vehicleModel.vehicleItem.vehicleTravelOver200.enabled)
                 errmsg += 'Does the vehicle travel over 200 miles from the garaging address is not answered.\n';

        }
        if (step == 3 || validateAllForms) {
            if (this.dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.answer == 'Yes'
                && Number(this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer) == 0) {
                //do not pop the stated amount error as it shows next to the textbox
                if (step != 3)
                    errmsg += 'Stated Amount cannot be $0.\n';
            }
            if (this.dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer == 'Yes'
                && (this.dao.vehicleModel.vehicleItem.vehicleCustomAmount.answer == null
                    || this.dao.vehicleModel.vehicleItem.vehicleCustomAmount.answer == '0'
                    || this.dao.vehicleModel.vehicleItem.vehicleCustomAmount.answer == '')) {
                errmsg += 'Custom parts and equipment amount cannot be $0.\n';
            }
            if (this.dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer == null
                || this.dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer == '')
                errmsg += 'Does this unit have an additional insured or loss payee is not anwered.\n';
        }

        return errmsg;
    }

    public async addVehicle(type, evt: Event) {
        await this.callRadiusAndCoverageToAllUnits();
        let formName = this.dao?.vehicleModel?.vehiclePage == 1 ? 'firstForm' : this.dao?.vehicleModel?.vehiclePage == 2 ? 'secondForm' : 'thirdForm';
        let ngform: NgForm = this[formName];
        ngform.onSubmit(evt);
        let errmsg = this.validateVehicleData(ngform, this.dao?.vehicleModel?.vehiclePage, true);

        if ("" != errmsg) {
            ngform.form.setErrors({ 'invalid': true });
            this.appComponent.addToast('error', 'Unit Info', errmsg);
            return;
        }

        if (ngform.valid) {
            if ((this.dao.vehicleModel.VehiclesList.length >= (this.dao.MaximumVehicle - 1) && type == 'vehicle')
                || (this.dao.vehicleModel.trailerList.length >= (this.dao.MaximumTrailer - 1) && type == 'trailer')) {
                if (type == 'vehicle') {
                    this.dao.HideVechicleButton = true;
                } else if (type == 'trailer') {
                    this.dao.HideTrailerButton = true;
                }
            }
            else {
                if (type == 'vehicle') {
                    this.dao.HideVechicleButton = false;
                } else if (type == 'trailer') {
                    this.dao.HideTrailerButton = false;
                }
            }
            this.removingHighlightionOfInvalidVehicle('addingVehicle');
            await this.serviceLayer.addVehicle(type);
            await this.SetRadiusAndCovergeToAllUnits(false);
        }
        else {
            this.appComponent.addToast('error', 'Unit Info', this.dao.toastValidation);
        }
    }
    public AddInterest(type) {
        if (type == '') {
            var tempArray = { 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' };
            var tempObj = _.cloneDeep(this.dao.vehicleModel.vehicleItem.interestList);
            tempObj.push(tempArray);
            this.dao.vehicleModel.vehicleItem.interestList = tempObj;
        } else {
            var tempArray = { 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' };
            var tempObj = _.cloneDeep(this.dao.editAllUnits.interestList);
            tempObj.push(tempArray);
            this.dao.editAllUnits.interestList = tempObj;
        }
    }
    // ========= Cancel my submission popup =====================
    public cancelMySubmissionPopUp() {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'All of your data will be lost if you cancel this submission. Are you sure you want to continue?' });
    }
    // ========= On reject =====================
    public onReject(type) {
        if (this.actionType == 'ensureTrailerDetails') {
            this.openToast('Please update Trailer information in Trailer tab and upload again');
        }
        this.messageService.clear(type);
        document.body.style.pointerEvents = "visible";
        if(this.actionType == 'validateTemplateVersion') {
            this.validateAndUploadExcel();
        }
    }
    // ========== isShowBtn =========
    public isShowActinBtn(type) {
        return this.serviceLayer.isShowActinBtn(type);
    }
    //==========vehicle size Change ====//
    public vehicleSizeChange() {
        let type = this.dao.isTrailer ? 'trailer' : 'vehicle';
        if (this.dao.vehicleModel.vehicleDefault[type + 'Size'] && this.dao.vehicleModel.vehicleDefault[type + 'Size'].questionOptions && this.dao.vehicleModel.vehicleDefault[type + 'Size'].questionOptions.filter(c => c.value == this.dao.vehicleModel.vehicleItem[type + 'Size'].answer) && this.dao.vehicleModel.vehicleDefault[type + 'Size'].questionOptions.filter(c => c.value == this.dao.vehicleModel.vehicleItem[type + 'Size'].answer).length > 0) {
            var hoverText = this.dao.vehicleModel.vehicleDefault[type + 'Size'].questionOptions.filter(c => c.value == this.dao.vehicleModel.vehicleItem[type + 'Size'].answer)[0].helpText;
            this.dao.vehicleModel.vehicleItem[type + 'Size'].helpText = hoverText;
        }
    }
    // check vehicle name blank
    public checkVehicleBlank(item) {
        if ((item?.vehicleYear?.answer && item?.vehicleYear?.answer != '') || (item?.vehicleMake?.answer && item?.vehicleMake?.answer != '') || (item?.vehicleModel?.answer && item?.vehicleModel?.answer != '')) {
            return true;
        }
        return false;
    }
    // ========= On Back =====================
    public async onBack(page) {
        await this.callRadiusAndCoverageToAllUnits();
        this.dao.IsBackButtonClicked = true;
        return this.menuClick(page);
    }
    StatedAmountinput() {
        if (this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer == null || this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer == '' || this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer == '0') {
            setTimeout(() => {
                this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer = 0;
            }, 10);
        }
    }
    // Validate address
    public ValidateAddress(event, value, type) {
        let result = this.serviceLayer.ValidateAddress(event, value);
        if (result != null) {
            if (type == "txtvehicleGarageStreetAddress") {
                this.dao.vehicleModel.vehicleItem.vehicleGarageStreetAddress.answer = result;
            }
        }
    }
    public async menuClick(page) {
        let result: any;
        let currentPageOrder = this.serviceLayer.getPageOrder(this.dao.PolicyTemplate);
        let finalSavePageOrder = this.serviceLayer.getPageOrder(this.dao.navigatePage);
        let clickedPage = this.serviceLayer.getPageOrder(page);

        if (clickedPage <= finalSavePageOrder
            || (clickedPage < currentPageOrder && (page == 'AccountInfo' || page != 'AccountInfo'))) {
            let menuContext: MenuClickContext = new MenuClickContext();
            menuContext.currentPageIsValid = true;
            menuContext.page = page;
            result = await this.serviceLayer.menuClick(menuContext);
            if (result != false) {
                this.dao.PolicyTemplate = page;
            }
        }
    }
    public async ChangeVehicleGaragingAddress() {
        this.serviceLayer.ChangeVehicleGaragingAddress();
    }
    public async editAllUnits() {
        this.dao.tempEditAllUnits = _.cloneDeep(this.dao.editAllUnits);
        this.editRadiusForAllUnits = true;
        this.EditUnitactiveIndex = 0;
    }
    public async closeEditAllUnits() {
        if (!this.isUpdateUnit) {
            this.dao.editAllUnits = _.cloneDeep(this.dao.tempEditAllUnits);
        }
        this.isUpdateUnit = false;
    }
    // ============== UpdateAllUnits ======================
    public async updateAllUnits() {
        var areAllFormsCloseable = true;

        if (this.radiusForm.dirty) {
            if (this.radiusForm.valid) {
                await this.saveRadiusForAllUnits();
                this.radiusForm.form.markAsPristine();
                this.vehicleForm?.form.setErrors({ 'invalidPowerUnit': null });
                this.vehicleForm?.form.setErrors({ 'invalidPowerUnitList': [] });
                this.vehicleForm?.form.setErrors({ 'invalidTrailer': null });
                this.vehicleForm?.form.setErrors({ 'invalidTrailerList': [] });
                this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
            }
            else {
                if (areAllFormsCloseable)
                    areAllFormsCloseable = false;
            }
        }

        if (this.compDeductibleForm.dirty) {
            if (this.compDeductibleForm.valid) {
                await this.saveCompDeductibleForAllUnits();
                this.compDeductibleForm.form.markAsPristine();
                this.vehicleForm?.form.setErrors({ 'invalidPowerUnit': null });
                this.vehicleForm?.form.setErrors({ 'invalidPowerUnitList': [] });
                this.vehicleForm?.form.setErrors({ 'invalidTrailer': null });
                this.vehicleForm?.form.setErrors({ 'invalidTrailerList': [] });
                this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
            }
            else {
                if (areAllFormsCloseable)
                    areAllFormsCloseable = false;
            }
        }

        if (this.collisionDeductibleForm.dirty) {
            if (this.collisionDeductibleForm.valid) {
                await this.saveCollDeductibleForAllUnits();
                this.collisionDeductibleForm.form.markAsPristine();
                this.vehicleForm?.form.setErrors({ 'invalidPowerUnit': null });
                this.vehicleForm?.form.setErrors({ 'invalidPowerUnitList': [] });
                this.vehicleForm?.form.setErrors({ 'invalidTrailer': null });
                this.vehicleForm?.form.setErrors({ 'invalidTrailerList': [] });
                this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
            }
            else {
                if (areAllFormsCloseable)
                    areAllFormsCloseable = false;
            }
        }

        if (this.garagingInformationForm.dirty) {
            if (this.garagingInformationForm.valid) {
                await this.saveGaragingInformationForAllUnits();
                this.garagingInformationForm.form.markAsPristine();
                this.vehicleForm?.form.setErrors({ 'invalidPowerUnit': null });
                this.vehicleForm?.form.setErrors({ 'invalidPowerUnitList': [] });
                this.vehicleForm?.form.setErrors({ 'invalidTrailer': null });
                this.vehicleForm?.form.setErrors({ 'invalidTrailerList': [] });
                this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
            }
            else {
                if (areAllFormsCloseable)
                    areAllFormsCloseable = false;
            }
        }

        if (this.customPartsForm.dirty) {
            if (this.customPartsForm.valid) {
                await this.saveCustomPartsForAllUnits();
                this.customPartsForm.form.markAsPristine();
                this.vehicleForm?.form.setErrors({ 'invalidPowerUnit': null });
                this.vehicleForm?.form.setErrors({ 'invalidPowerUnitList': [] });
                this.vehicleForm?.form.setErrors({ 'invalidTrailer': null });
                this.vehicleForm?.form.setErrors({ 'invalidTrailerList': [] });
                this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
            }
            else {
                if (areAllFormsCloseable)
                    areAllFormsCloseable = false;
            }
        }

        if (this.insuredForm.dirty) {
            if (this.insuredForm.valid) {
                await this.saveInsuredLossPayeeForAllUnits();
                this.insuredForm.form.markAsPristine();
                this.vehicleForm?.form.setErrors({ 'invalidPowerUnit': null });
                this.vehicleForm?.form.setErrors({ 'invalidPowerUnitList': [] });
                this.vehicleForm?.form.setErrors({ 'invalidTrailer': null });
                this.vehicleForm?.form.setErrors({ 'invalidTrailerList': [] });
                this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
            }
            else {
                if (areAllFormsCloseable)
                    areAllFormsCloseable = false;
            }
        }

        if (areAllFormsCloseable) {
            this.dao.ValidateEditAllUnitsRadius = false;
            this.ValidateRadiusQuestionInEdit = false;
            this.editRadiusForAllUnits = false;
        }
    }
    // ================= Save Radius for all units ================ 
    public async saveRadiusForAllUnits() {
        if (this.radiusForm.invalid) return;
        this.SetInitialTempData(); //set this.dao.tempEditAllUnits properties to new emptyModal() instances
        this.dao.ValidateEditAllUnitsRadius = false;
        this.ValidateRadiusQuestionInEdit = false;
        let totalPercent = this.getTotalPercentForEditAllUnits();
        if (totalPercent == true) {
            if (this.dao.editAllUnits.vehicleTravelOver200.answer != undefined && this.dao.editAllUnits.vehicleTravelOver200.answer !== '') {
                if (this.dao.editAllUnits.vehicleTravelOver200.answer == 'No' || (this.dao.editAllUnits.vehicleTravelOver200.answer == 'Yes' && this.dao.editAllUnits.zoneList.length > 0)) {
                    await this.serviceLayer.saveRadiusForAllUnits('bulkEdit');
                    this.dao.VehicleBulkEditRadiusCheckbox = true;
                    this.dao.ValidateEditAllUnitsRadius = false;
                    this.ValidateRadiusQuestionInEdit = false;
                    this.editRadiusForAllUnits = false;
                } else {
                    this.dao.ValidateEditAllUnitsRadius = true;
                }

            } else if (this.dao.editAllUnits.zoneList.length === 1 && (this.dao.editAllUnits.zoneList[0].zone !== '' || this.dao.editAllUnits.zoneList[0].percentage !== '')) {
                await this.serviceLayer.saveRadiusForAllUnits('bulkEdit');
                this.dao.VehicleBulkEditRadiusCheckbox = true;
                this.dao.ValidateEditAllUnitsRadius = false;
                this.ValidateRadiusQuestionInEdit = false;
                this.editRadiusForAllUnits = false;
            } else if (this.dao.vehicleModel.vehicleDefault.farthestApplicableZonePercent.enabled) {
                await this.serviceLayer.saveRadiusForAllUnits('bulkEdit');
                this.dao.VehicleBulkEditRadiusCheckbox = true;
                this.dao.ValidateEditAllUnitsRadius = false;
                this.ValidateRadiusQuestionInEdit = false;
                this.editRadiusForAllUnits = false;
            } else {
                this.ValidateRadiusQuestionInEdit = true;
            }

        } else {
            this.dao.ValidateEditAllUnitsRadius = true;
            this.appComponent.addToast('error', 'Unit Info', "Total radius percentage must equal 100%");
        }
    }
    // ========= Get Total Percent For Edit All Units=====================
    public getTotalPercentForEditAllUnits() {
        let totalvalue = 0;
        if (this.dao.vehicleModel.vehicleDefault.farthestApplicableZonePercent.enabled) {
            totalvalue += (isNaN(Number(this.dao.editAllUnits.farthestApplicableZonePercent.answer)) ? 0 : Number(this.dao.editAllUnits.farthestApplicableZonePercent.answer));
        } else if (this.dao.editAllUnits && this.dao.editAllUnits.zoneList && this.dao.editAllUnits.zoneList.length > 0) {
            this.dao.editAllUnits.zoneList.forEach(z => {
                totalvalue = totalvalue + (isNaN(Number(z.percentage)) ? 0 : Number(z.percentage));
            });
        }
        totalvalue = totalvalue + (isNaN(Number(this.dao.editAllUnits.vehicleTotalMilesUpto50.answer)) ? 0 : Number(this.dao.editAllUnits.vehicleTotalMilesUpto50.answer)) + (isNaN(Number(this.dao.editAllUnits.vehicleTotalMilesUpto200.answer)) ? 0 : Number(this.dao.editAllUnits.vehicleTotalMilesUpto200.answer));
        return totalvalue == 100;
    }
    // ================= Save Comp Deductible for all units ================ 
    public async saveCompDeductibleForAllUnits() {
        if (this.compDeductibleForm.invalid) {
            this.appComponent.addToast('error', 'Unit Info', "Please fill required fields");
            return
        }
        this.SetInitialTempData();
        await this.serviceLayer.saveCompDeductibleForAllUnits('bulkEdit');
        this.dao.VehicleBulkEditCompCoverageCheckbox = true;
        this.editRadiusForAllUnits = false;
        this.dao.ValidateEditAllUnitsRadius = false;

    }
    // ================= Save Coll Deductible for all units ================ 
    public async saveCollDeductibleForAllUnits() {
        if (this.collisionDeductibleForm.invalid) {
            this.appComponent.addToast('error', 'Unit Info', "Please fill required fields");
            return
        }
        this.SetInitialTempData();
        if (this.dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled) {
            if (this.dao.vehicleModel.trailerList.length > 0) {
                this.dao.vehicleModel.trailerList.forEach(trailer => {
                    if (trailer.floorTrailer.answer == '') {
                        trailer.floorTrailer.answer = 'No';
                    }
                })
            }
        }
        await this.serviceLayer.saveCollDeductibleForAllUnits('bulkEdit');
        this.dao.VehicleBulkEditCollCoverageCheckbox = true;
        this.editRadiusForAllUnits = false;
        this.dao.ValidateEditAllUnitsRadius = false;

    }
    // ================= Save Garaging Information for all units ================ 
    public async saveGaragingInformationForAllUnits() {
        this.SetInitialTempData();
        if (this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == 'Yes' || this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == 'No') {
            if ((this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == 'No' && this.dao.editAllUnits.vehicleGarageStreetAddress.answer != null && this.dao.editAllUnits.vehicleGarageStreetAddress.answer != ''
                && this.dao.editAllUnits.vehicleGarageCity.answer != null && this.dao.editAllUnits.vehicleGarageCity.answer != ''
                && this.dao.editAllUnits.vehicleGarageState.answer != null && this.dao.editAllUnits.vehicleGarageState.answer != ''
                && this.dao.editAllUnits.vehicleGaragePostalCode.answer != null && this.dao.editAllUnits.vehicleGaragePostalCode.answer != '')
                || this.dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == 'Yes') {

                await this.serviceLayer.saveGaragingInformationForAllUnits('bulkEdit');
                this.dao.VehicleBulkEditGaragingCheckbox = true;
                this.editRadiusForAllUnits = false;
            }
            else {
                this.appComponent.addToast('error', 'Unit Info', "Please fill required fields");
            }
        }
        else {
            this.appComponent.addToast('error', 'Unit Info', "Please fill required fields");
        }


    }
    // ================= Save Custome parts for all units ================ 
    public async saveCustomPartsForAllUnits() {
        this.SetInitialTempData();
        if (this.dao.editAllUnits.vehicleCustomPartsParent.answer == 'Yes' || this.dao.editAllUnits.vehicleCustomPartsParent.answer == 'No') {
            await this.serviceLayer.saveCustomPartsForAllUnits('bulkEdit');
            this.dao.VehicleBulkEditCustomPartsCheckbox = true;
            this.editRadiusForAllUnits = false;
        } else {
            this.appComponent.addToast('error', 'Unit Info', "Please fill required fields");
        }


    }
    // ================= Save Additional Insured / Loss Payee for all units ================ 
    public async saveInsuredLossPayeeForAllUnits() {
        this.SetInitialTempData();
        if ((this.dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer == 'Yes' && this.dao.editAllUnits.interestList != []) || this.dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer == 'No') {
            await this.serviceLayer.saveInsuredLossPayeeForAllUnits('bulkEdit');
            this.dao.VehicleBulkEditInsuredLossPayeeCheckbox = true;
            this.editRadiusForAllUnits = false;
        } else {
            this.appComponent.addToast('error', 'Unit Info', "Please fill required fields");
        }


    }
    public async SetRadiusAndCovergeToAllUnits(IsCheckBoxClicked = false) {
        this.vehicleForm?.form.setErrors({ 'invalidPowerUnit': null });
        this.vehicleForm?.form.setErrors({ 'invalidPowerUnitList': [] });
        this.vehicleForm?.form.setErrors({ 'invalidTrailer': null });
        this.vehicleForm?.form.setErrors({ 'invalidTrailerList': [] });
        this.powerUnitList?.form?.controls?.masterDeleteCheckBox.updateValueAndValidity();
        await this.serviceLayer.SetRadiusAndCovergeToAllUnits(IsCheckBoxClicked);
    }
    public async callRadiusAndCoverageToAllUnits() {
        if (this.dao.VehicleBulkEditCompCoverageCheckbox && (this.dao.vehicleModel.vehicleIndex == 0 && !this.dao.isTrailer) && this.dao.IsEditCompDeductibleSaved == false) {
            this.dao.IsEditCompDeductibleSaved = true;
            await this.SetRadiusAndCovergeToAllUnits(true);
        }
        if (this.dao.VehicleBulkEditCollCoverageCheckbox && (this.dao.vehicleModel.vehicleIndex == 0 && !this.dao.isTrailer) && this.dao.IsEditCollDeductibleSaved == false) {
            this.dao.IsEditCollDeductibleSaved = true;
            await this.SetRadiusAndCovergeToAllUnits(true);
        }
        if (this.dao.VehicleBulkEditRadiusCheckbox && (this.dao.vehicleModel.vehicleIndex == 0 && !this.dao.isTrailer) && this.dao.IsEditRadiusSaved == false) {
            this.dao.IsEditRadiusSaved = true;
            await this.SetRadiusAndCovergeToAllUnits(true);
        }
        if (this.dao.VehicleBulkEditGaragingCheckbox && (this.dao.vehicleModel.vehicleIndex == 0 && !this.dao.isTrailer) && this.dao.IsEditGaragingSaved == false) {
            this.dao.IsEditGaragingSaved = true;
            await this.SetRadiusAndCovergeToAllUnits(true);
        }
        if (this.dao.VehicleBulkEditCustomPartsCheckbox && (this.dao.vehicleModel.vehicleIndex == 0 && !this.dao.isTrailer) && this.dao.IsEditCustomPartsSaved == false) {
            this.dao.IsEditCustomPartsSaved = true;
            await this.SetRadiusAndCovergeToAllUnits(true);
        }
        if (this.dao.VehicleBulkEditInsuredLossPayeeCheckbox && (this.dao.vehicleModel.vehicleIndex == 0 && !this.dao.isTrailer) && this.dao.IsEditinsuredLossPayeeSaved == false) {
            this.dao.IsEditinsuredLossPayeeSaved = true;
            await this.SetRadiusAndCovergeToAllUnits(true);
        }
        if (this.dao.VehicleBulkEditCollCoverageCheckbox && this.dao.vehicleModel.trailerList.length > 0 && this.dao.IsEditCompDeductibleSaved == false) {
            this.dao.vehicleModel.trailerList.forEach(trailer => {
                if (trailer.floorTrailer.answer == '') {
                    trailer.floorTrailer.answer = 'No';
                }
            })
        }
    }
    public SetInitialTempData() {
        this.dao.tempEditAllUnits.vehicleTotalMilesUpto50 = new emptyModal();
        this.dao.tempEditAllUnits.vehicleTotalMilesUpto200 = new emptyModal();
        this.dao.tempEditAllUnits.vehicleTravelOver200 = new emptyModal();
        this.dao.tempEditAllUnits.vehicleToZone = new emptyModal();
        this.dao.tempEditAllUnits.vehicleApplicableZonePercent = new emptyModal();
        this.dao.tempEditAllUnits.zoneList = new emptyModal();
        this.dao.tempEditAllUnits.vehicleCompOTCCoverage = new emptyModal();
        this.dao.tempEditAllUnits.vehicleComp = new emptyModal();
        this.dao.tempEditAllUnits.vehicleCollCoverage = new emptyModal();
        this.dao.tempEditAllUnits.Q_WhichCollCoverage = new emptyModal();
        this.dao.tempEditAllUnits.broadenedCollision = new emptyModal();
        this.dao.tempEditAllUnits.Q_limitedCollision = new emptyModal();
        this.dao.tempEditAllUnits.limitedCollision = new emptyModal();
        this.dao.tempEditAllUnits.vehicleColl = new emptyModal();
        this.dao.tempEditAllUnits.vehicleIsCorrectGaragingAddress = new emptyModal();
        this.dao.tempEditAllUnits.vehicleCustomPartsParent = new emptyModal();
        this.dao.tempEditAllUnits.vehicleAddInsuredOrLossPayee = new emptyModal();
        this.dao.tempEditAllUnits.interestList = [];
        this.isUpdateUnit = true;
    }
    ClearCustomDetails(type) {
        if (type == 'No') {
            this.dao.vehicleModel.vehicleItem.vehicleCustomAmount = new emptyModal();
            this.dao.vehicleModel.vehicleItem.vehicleCustomDesc = new emptyModal();
        }
    }

    // ===================saving vehicle details in DB without validating fields while save btn is clicked
    public async VehicleSaveDraft() {
        await this.serviceLayer.setUnitData();
        let getTemplate = this.serviceLayer.saveDatas.filter(s => s.template == this.dao.PolicyTemplate);
        let getFinalTemplate = this.serviceLayer.saveDatas.filter(s => s.template == this.dao.finalSubmitPage);
        let getTemplateOrder = (getTemplate && getTemplate.length > 0) ? getTemplate[0].order : 0;
        let getFinalTemplateOrder = (getFinalTemplate && getFinalTemplate.length > 0) ? getFinalTemplate[0].order : 0;
        let DotExists: boolean = false;
        if (getTemplateOrder <= getFinalTemplateOrder) {
            DotExists = true;
        }
        let payload = { "urlData": [this.dao.userMail, DotExists, this.dao.PolicyTemplate, 'dot', false], "payloadData": this.dao.DOTList }

        await this.PostVehicleDraft(payload).then((data: any) => { });
        if (this.dao.finalSubmitPage == 'losshistory') {
            this.dao.finalSubmitPage = 'vehicles';
            this.dao.navigatePage = 'vehicles';
            this.dao.DOTList.finalSubmitPage = 'vehicles';
        }
    }
    // ========= Save Vehicle Draft =====================
    public async PostVehicleDraft(payload) {
        return await this.commonService.post('vehicle', 'saveVehicleDraft', payload).toPromise();
    }

    public async CollisionOnChange(type) {
        if (this.dao?.vehicleModel?.vehicleItem?.broadenedCollision?.answer) {
            this.isShowCollisionCoverageCheckBox = true;
            if (this.dao.vehicleModel.vehicleItem?.vehicleCollCoverage?.answer) {
                if (type == 'vehicleCollCoverage') {
                    this.dao.vehicleModel.vehicleItem.Q_WhichCollCoverage.answer = '';
                    this.dao.vehicleModel.vehicleItem.Q_limitedCollision.answer = '';
                    if (this.dao.isTrailer) {
                        this.setFloorTrailerValue();
                    }
                }
                if (this.dao.vehicleModel.vehicleItem?.Q_WhichCollCoverage.answer == 'Yes' && this.dao.vehicleModel.vehicleItem.vehicleColl.answer) {
                    if (this.dao.vehicleModel.vehicleItem.vehicleColl?.questionDefault) {
                        this.dao.vehicleModel.vehicleItem.vehicleColl.answer = this.dao.vehicleModel.vehicleItem.vehicleColl.questionDefault[0].code;
                    }
                } else if (this.dao.vehicleModel.vehicleItem?.Q_WhichCollCoverage.answer == 'No' && this.dao.vehicleModel.vehicleItem.broadenedCollision.answer) {
                    if (this.dao.vehicleModel.vehicleItem.broadenedCollision?.questionDefault) {
                        this.dao.vehicleModel.vehicleItem.broadenedCollision.answer = this.dao.vehicleModel.vehicleItem.broadenedCollision.questionDefault[0].code;
                    }
                }
            } else if (this.dao.vehicleModel.vehicleItem?.Q_limitedCollision.answer) {
                if (this.dao.vehicleModel.vehicleItem?.Q_limitedCollision.answer == 'No' && this.dao.vehicleModel.vehicleItem.vehicleColl.answer) {
                    if (this.dao.vehicleModel.vehicleItem.limitedCollision?.questionDefault) {
                        this.dao.vehicleModel.vehicleItem.limitedCollision.answer = this.dao.vehicleModel.vehicleItem.limitedCollision.questionDefault[0].code;
                    }
                }
            }
        }
        else if (type == 'vehicleCollCoverage' && this.dao.isTrailer) {
            this.setFloorTrailerValue();
        }
    }
    private setFloorTrailerValue() {
        if (this.dao.vehicleModel.vehicleItem?.vehicleCollCoverage?.answer == 'Yes') {
            if (this.dao.vehicleModel.vehicleItem.floorTrailer.answer == '') {
                this.dao.vehicleModel.vehicleItem.floorTrailer.answer = 'No';
            }
        }
    }

    public async EditCollisionOnChange(type) {
        if (type == 'vehicleCollCoverage') {
            this.dao.editAllUnits.vehicleCollCoverage.answer == 'Yes' ? this.dao.editAllUnits.Q_limitedCollision.answer = '' : this.dao.editAllUnits.Q_WhichCollCoverage.answer = '';
        }
        if (type == 'Q_WhichCollCoverage') {
            if (this.dao.editAllUnits.Q_WhichCollCoverage.answer == 'No') {
                if (this.dao.vehicleModel.vehicleItem.broadenedCollision?.questionDefault) {
                    this.dao.editAllUnits.broadenedCollision.answer = this.dao.vehicleModel.vehicleItem.broadenedCollision.questionDefault[0].code;
                }
            }
        }
        if (type == 'Q_limitedCollision') {
            if (this.dao.editAllUnits.Q_limitedCollision.answer == 'Yes') {
                if (this.dao.vehicleModel.vehicleItem.limitedCollision?.questionDefault) {
                    this.dao.editAllUnits.limitedCollision.answer = this.dao.vehicleModel.vehicleItem.limitedCollision.questionDefault[0].code;
                }
            }
        }
    }

    removeSelectedVehicles(unitType: string) {
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'remove', sticky: true, severity: 'warn', summary: `Do you want to delete the selected ${unitType}(s)? ` });
    }

    async confirmRemoveSelectedVehicles() {
        for (let i = 0; i < this.dao.vehicleModel.VehiclesList.length; i++) {
            if (this.dao.vehicleModel.VehiclesList[i].isChecked) {
                this.dao.deletedItem = this.dao.vehicleModel.VehiclesList[i];
                await this.removeVehicle(this.dao.deletedItem);
                await this.VehicleSaveDraft();
                i = i - 1;
            }
        }
        this.checkDuplicateVinNumberExists('delete');
        this.masterDeleteCheckBoxValue = false;
    }
    async confirmRemoveSelectedTrailers() {
        for (let i = 0; i < this.dao.vehicleModel.trailerList.length; i++) {
            if (this.dao.vehicleModel.trailerList[i].isChecked) {
                this.dao.deletedItem = this.dao.vehicleModel.trailerList[i];
                await this.removeTrailer(this.dao.deletedItem);
                await this.VehicleSaveDraft();
                i = i - 1;
            }
        }
        this.checkDuplicateVinNumberExists('delete');
        this.masterTrailerDeleteCheckBoxValue = false;
    }
    public openToast(body) {
        document.body.style.pointerEvents = "none";
        this.messageService.add({
            key: 'ok', sticky: true, severity: 'warn',
            summary: body
        });
        this.dao.isClickFromMenu = false;
    }

    vehicleCompOTCCoverageAnswerChanged() {
        setTimeout(() => {
            this.thirdForm.controls.vehicleStatedAmount.updateValueAndValidity();
        }, 200);
    }
    trackByFn(i: number) {
        return i
    }
    public async downloadSampleVehicleTemplate(id, fileName) {
        this.serviceLayer.downloadSampleTemplate(id, fileName);
    }
    uploadVehicleList(event, fileupload) {
        if (event?.files?.length > 0) {
            this.excelEvent = event?.files[0];
            this.actionType = 'confirmExcel';
            document.body.style.pointerEvents = "none";
            this.messageService.add({ key: 'remove', sticky: true, severity: 'warn', summary: 'Do you want to upload this vehicle information?' });
        }
        fileupload.clear();
    }
    ensureTrailerDetails() {
        this.actionType = 'ensureTrailerDetails';
        document.body.style.pointerEvents = "none";
        this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'Please confirm that Trailer information is updated in Trailer tab?' });
    }
    private validateTemplateVersion(): void {
        let file = this.excelEvent;
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
            this.uploadedExcelWorkBook = XLSX.read(fileReader.result, { type: 'binary', cellText: false, cellDates: true });
            if (!!!this.uploadedExcelWorkBook.Custprops['version'] || (!!this.uploadedExcelWorkBook.Custprops['version'] && this.uploadedExcelWorkBook.Custprops['version'] != Constants.CurrentVehicleExcelTemplateVersion)) {
                this.openToast('A new version of the vehicle template is now available.  You may continue with the old version, but the new version will provide a superior experience.');
                this.actionType = "validateTemplateVersion";
                return;
            }
            this.validateAndUploadExcel();
        }
    }
    private validateAndUploadExcel(): boolean {
        this.actionType = '';
        if (!this.validateTemplateHeaders()) {
            this.openToast('The file you uploaded is not in the correct format.  Please upload the vehicle information using the BSB template only.');
            return false;
        }
        this.excelPowerUnits = XLSX.utils.sheet_to_json(this.uploadedExcelWorkBook.Sheets['PowerUnit'], { header: 0, raw: false, dateNF: 'MM/dd/yyyy' });
        this.excelTrailers = XLSX.utils.sheet_to_json(this.uploadedExcelWorkBook.Sheets['Trailer'], { header: 0, raw: false, dateNF: 'MM/dd/yyyy' });
        if (this.excelPowerUnits.length <= 2 && this.excelTrailers.length <= 1) {
            this.openToast('Vehicle list is empty, cannot be uploaded');
            return false;
        }
        let powerunitSpliceCount = 0;
        let powerunitSpliceIndex = 0;
        if (this.dao.vehicleModel.VehiclesList.length == 1) {
            if (this.dao.vehicleModel.VehiclesList[0].vehicleYear.answer == ""
                && this.dao.vehicleModel.VehiclesList[0].vehicleMake.answer == ""
                && this.dao.vehicleModel.VehiclesList[0].vehicleModel.answer == ""
                && this.dao.vehicleModel.VehiclesList[0].vehicleVin.answer == "") {
                powerunitSpliceIndex = 0;
                powerunitSpliceCount = 1;
                this.powerUnitsLengthBeforeUpload = this.dao.vehicleModel.VehiclesList.length - 1;
            }
            else {
                this.powerUnitsLengthBeforeUpload = this.dao.vehicleModel.VehiclesList.length;
            }
        }
        else if (this.dao.vehicleModel.VehiclesList.length > 1) {
            for (var x = 0; x < this.dao.vehicleModel.VehiclesList.length; x++) {
                if (this.dao.vehicleModel.VehiclesList[x].vehicleYear.answer == ""
                    && this.dao.vehicleModel.VehiclesList[x].vehicleMake.answer == ""
                    && this.dao.vehicleModel.VehiclesList[x].vehicleModel.answer == ""
                    && this.dao.vehicleModel.VehiclesList[x].vehicleVin.answer == "") {
                    powerunitSpliceIndex = x;
                    powerunitSpliceCount = this.dao.vehicleModel.VehiclesList.length - x;
                    this.powerUnitsLengthBeforeUpload = x;
                    break;
                }
                else {
                    this.powerUnitsLengthBeforeUpload = x + 1;
                }
            }
        }
        if (this.powerUnitsLengthBeforeUpload + this.excelPowerUnits.length - 2 > this.dao.MaximumVehicle) {
            this.openToast('You cannot exceed ' + this.dao.MaximumVehicle + ' Power units');
            return false;
        }
        if (this.dao.vehicleModel.trailerList.length > 0
            && this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerList.length - 1].vehicleYear.answer == ""
            && this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerList.length - 1].vehicleMake.answer == "") {
            this.trailerLengthBeforeUpload = this.dao.vehicleModel.trailerList.length - 1;
        }
        else {
            this.trailerLengthBeforeUpload = this.dao.vehicleModel.trailerList.length;
        }
        if (this.trailerLengthBeforeUpload + this.excelTrailers.length - 1 > this.dao.MaximumTrailer) {
            this.openToast('You cannot exceed ' + this.dao.MaximumTrailer + ' trailers');
            return false;
        }
        if (this.excelPowerUnits.length - 2 > 0)
            this.dao.vehicleModel.VehiclesList.splice(powerunitSpliceIndex, powerunitSpliceCount);
        if (this.excelTrailers.length - 1 > 0)
            this.dao.vehicleModel.trailerList.splice(this.trailerLengthBeforeUpload, 1);
        let isPowerUnitsUploadedSucessfully;
        let isTrailersUploadedSucessfully;
        isPowerUnitsUploadedSucessfully = this.excelPowerUnits.length - 1 <= 1 ? true : this.uploadingPowerUnits();
        if (isPowerUnitsUploadedSucessfully) {
            isTrailersUploadedSucessfully = this.excelTrailers.length - 1 <= 0 ? true : this.uploadingTrailers();
        }
        if (isPowerUnitsUploadedSucessfully && isTrailersUploadedSucessfully) {
            if (this.excelPowerUnits.length - 1 <= 1) {
                this.dao.vehicleModel.trailerIndex = this.trailerLengthBeforeUpload == 0 ? 0 : this.trailerLengthBeforeUpload;
                this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.trailerList[this.trailerLengthBeforeUpload == 0 ? 0 : this.trailerLengthBeforeUpload];
                this.dao.isTrailer = false;
            }
            else {
                this.dao.vehicleModel.vehicleIndex = this.powerUnitsLengthBeforeUpload == 0 ? 0 : this.powerUnitsLengthBeforeUpload;
                this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.VehiclesList[this.powerUnitsLengthBeforeUpload == 0 ? 0 : this.powerUnitsLengthBeforeUpload];
                this.dao.isTrailer = false;
            }
            this.dao.HideVechicleButton = false;
            if (this.dao.vehicleModel.VehiclesList.length >= this.dao.MaximumVehicle) {
                this.dao.HideVechicleButton = true;
            }
            this.dao.HideTrailerButton = false;
            if (this.dao.vehicleModel.trailerList.length >= this.dao.MaximumTrailer) {
                this.dao.HideTrailerButton = true;
            }
            this.isShowActinBtn('vehicle');
            this.isShowActinBtn('trailer');
            this.openToast('Power Unit(s)/Trailer(s) list have been uploaded successfully');
            return true;
        }
        return false;
    }
    validateTemplateHeaders(): boolean {
        if(!!!this.uploadedExcelWorkBook.Sheets['PowerUnit'] || !!!this.uploadedExcelWorkBook.Sheets['Trailer']) {
            return false;
        }
        var powerUnitsTemplateHeaders1 = XLSX.utils.sheet_to_json(this.uploadedExcelWorkBook.Sheets['PowerUnit'], { header: 1 })[0];
        var powerUnitsTemplateHeaders2 = XLSX.utils.sheet_to_json(this.uploadedExcelWorkBook.Sheets['PowerUnit'], { header: 1 })[1];
        var trailersTemplateHeaders = XLSX.utils.sheet_to_json(this.uploadedExcelWorkBook.Sheets['Trailer'], { header: 1 })[0];
        if (!powerUnitsTemplateHeaders1[0] || powerUnitsTemplateHeaders1[0] != 'Please use Trailer tab to upload Trailer details') {
            return false;
        }
        if (!powerUnitsTemplateHeaders2[0] || powerUnitsTemplateHeaders2[0] != 'Year'
            || (!powerUnitsTemplateHeaders2[1] || powerUnitsTemplateHeaders2[1] != 'Make')
            || (!powerUnitsTemplateHeaders2[2] || powerUnitsTemplateHeaders2[2] != 'Model')
            || (!powerUnitsTemplateHeaders2[3] || powerUnitsTemplateHeaders2[3] != 'VIN')
            || (!powerUnitsTemplateHeaders2[4] || powerUnitsTemplateHeaders2[4] != 'Is this the correct garaging address?')
            || (!powerUnitsTemplateHeaders2[5] || powerUnitsTemplateHeaders2[5] != 'Vehicle Weight Class')
            || (!powerUnitsTemplateHeaders2[6] || powerUnitsTemplateHeaders2[6] != 'Vehicle Body Type')
            || (!powerUnitsTemplateHeaders2[7] || powerUnitsTemplateHeaders2[7] != 'Secondary Classification')
            || (!powerUnitsTemplateHeaders2[8] || powerUnitsTemplateHeaders2[8] != 'Local - Annual miles <= 50 miles (in %)')
            || (!powerUnitsTemplateHeaders2[9] || powerUnitsTemplateHeaders2[9] != 'Intermediate - 50 miles > Annual miles <= 200 (in %)')
            || (!powerUnitsTemplateHeaders2[10] || powerUnitsTemplateHeaders2[10] != 'Comprehensive Deductible')
            || (!powerUnitsTemplateHeaders2[12] || powerUnitsTemplateHeaders2[12] != 'Collision Deductible')
            || (!powerUnitsTemplateHeaders2[14] || powerUnitsTemplateHeaders2[14] != 'Stated Amount per Unit (Including custom parts & equipment)')
            || (!powerUnitsTemplateHeaders2[15] || powerUnitsTemplateHeaders2[15] != 'Are there custom parts and equipment on this unit?')
            || (!powerUnitsTemplateHeaders2[18] || powerUnitsTemplateHeaders2[18] != 'Does this unit have an additional insured or loss payee?')) {
            return false;
        }
        if (!trailersTemplateHeaders[0] && trailersTemplateHeaders[0] != 'Year'
            || (!trailersTemplateHeaders[1] || trailersTemplateHeaders[1] != 'Make')
            || (!trailersTemplateHeaders[2] || trailersTemplateHeaders[2] != 'Model')
            || (!trailersTemplateHeaders[3] || trailersTemplateHeaders[3] != 'VIN')
            || (!trailersTemplateHeaders[4] || trailersTemplateHeaders[4] != 'Is this the correct garaging address?')
            || (!trailersTemplateHeaders[5] || trailersTemplateHeaders[5] != 'Trailer Weight Class')
            || (!trailersTemplateHeaders[6] || trailersTemplateHeaders[6] != 'Trailer Body Type')
            || (!trailersTemplateHeaders[7] || trailersTemplateHeaders[7] != 'Secondary Classification')
            || (!trailersTemplateHeaders[8] || trailersTemplateHeaders[8] != 'Local - Annual miles <= 50 miles (in %)')
            || (!trailersTemplateHeaders[9] || trailersTemplateHeaders[9] != 'Intermediate - 50 miles > Annual miles <= 200 (in %)')
            || (!trailersTemplateHeaders[10] || trailersTemplateHeaders[10] != 'Comprehensive Deductible')
            || (!trailersTemplateHeaders[12] || trailersTemplateHeaders[12] != 'Collision Deductible')
            || (!trailersTemplateHeaders[14] || trailersTemplateHeaders[14] != 'Stated Amount per Unit (Including custom parts & equipment)')
            || (!trailersTemplateHeaders[15] || trailersTemplateHeaders[15] != 'Are there custom parts and equipment on this unit?')
            || (!trailersTemplateHeaders[18] || trailersTemplateHeaders[18] != 'Does this unit have an additional insured or loss payee?')) {
            return false;
        }
        return true;
    }
    uploadingPowerUnits(): boolean {
        for (var key = 2; key <= this.excelPowerUnits.length - 1; key++) {
            let isAddVehicleBtn = this.isShowActinBtn('vehicle');
            if (!this.dao.isTrailer && this.dao.vehicleModel.VehiclesList.length > 0 && isAddVehicleBtn) {
                this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.VehiclesList.length - 1] = _.cloneDeep(this.dao.vehicleModel.vehicleItem);
            }
            var lastVehicleIndex = 0
            this.dao.vehicleModel.VehiclesList.forEach((vehicle, index) => {
                Object.keys(vehicle).forEach(tempKey => {
                    if (typeof (vehicle[tempKey]) == 'object') {
                        vehicle[tempKey].group = ('VEH-' + (index + 1));
                    }
                });
                lastVehicleIndex = index + 1;
            });
            let tempVehicle = _.cloneDeep(this.dao.vehicleModel.vehicleDefault);

            // 1st form
            tempVehicle.vehicleYear.answer = this.getAndSetValidYear(this.excelPowerUnits[key]["Please use Trailer tab to upload Trailer details"]);
            tempVehicle.vehicleMake.answer = this.getAndSetValidString(this.excelPowerUnits[key]["__EMPTY"]);
            tempVehicle.vehicleModel.answer = this.getAndSetValidString(this.excelPowerUnits[key]["__EMPTY_1"]);
            tempVehicle.vehicleVin.answer = !!this.excelPowerUnits[key]["__EMPTY_2"] ? this.excelPowerUnits[key]["__EMPTY_2"].trim() : "";
            // 2nd form 
            tempVehicle.vehicleIsCorrectGaragingAddress.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelPowerUnits[key]["__EMPTY_3"]);
            tempVehicle.vehicleSize.answer = this.getValidDropdownAnswerFromExcel('vehicleSize', this.excelPowerUnits[key]["__EMPTY_4"]);
            tempVehicle.vehicleBodyType.answer = this.getValidDropdownAnswerFromExcel('vehicleBodyType', this.excelPowerUnits[key]["__EMPTY_5"]);
            tempVehicle.vehicleSecondaryClass.answer = this.getValidDropdownAnswerFromExcel('vehicleSecondaryClass', this.excelPowerUnits[key]["__EMPTY_6"]);
            tempVehicle.vehicleTotalMilesUpto50.answer = isNaN(this.excelPowerUnits[key]["__EMPTY_7"]) ? "" : this.excelPowerUnits[key]["__EMPTY_7"];
            tempVehicle.vehicleTotalMilesUpto200.answer = isNaN(this.excelPowerUnits[key]["__EMPTY_8"]) ? "" : this.excelPowerUnits[key]["__EMPTY_8"];
            // 3rd form 
            const accounState = this.dao.DOTList.accountInfoQsnr.findQuestion("accountState")?.answer;
            if (accounState != 'MI') {
                tempVehicle.vehicleCompOTCCoverage.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelPowerUnits[key]["__EMPTY_9"]);
                tempVehicle.vehicleComp.answer = tempVehicle.vehicleCompOTCCoverage.answer == 'Yes' ? this.getValidDropdownAnswerFromExcel('vehicleComp', this.excelPowerUnits[key]["__EMPTY_10"]) : "";
                tempVehicle.vehicleCollCoverage.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelPowerUnits[key]["__EMPTY_11"]);
                tempVehicle.vehicleColl.answer = tempVehicle.vehicleCollCoverage.answer == 'Yes' ? this.getValidDropdownAnswerFromExcel('vehicleColl', this.excelPowerUnits[key]["__EMPTY_12"]) : "";
            }
            else {
                tempVehicle.vehicleCompOTCCoverage.answer = "";
                tempVehicle.vehicleCollCoverage.answer = "";
            }
            tempVehicle.vehicleStatedAmount.answer = isNaN(this.excelPowerUnits[key]["__EMPTY_13"]) ? "" : Math.round(this.excelPowerUnits[key]["__EMPTY_13"].trim());
            tempVehicle.vehicleCustomPartsParent.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelPowerUnits[key]["__EMPTY_14"]);
            tempVehicle.vehicleCustomAmount.answer = tempVehicle.vehicleCustomPartsParent.answer == 'Yes' ? (isNaN(this.excelPowerUnits[key]["__EMPTY_15"]) ? "" : Math.round(this.excelPowerUnits[key]["__EMPTY_15"].trim())) : "";
            tempVehicle.vehicleCustomDesc.answer = tempVehicle.vehicleCustomPartsParent.answer == 'Yes' ? this.excelPowerUnits[key]["__EMPTY_16"] : "";
            tempVehicle.vehicleAddInsuredOrLossPayee.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelPowerUnits[key]["__EMPTY_17"]);

            if (this.dao.vehicleModel.VehiclesList.length > 0 && this.dao.vehicleModel.VehiclesList.every(item => item.isChecked)) {
                tempVehicle.isChecked = true;
            }
            Object.keys(tempVehicle).forEach(tempKey => {
                if (typeof (tempVehicle[tempKey]) == 'object') {
                    tempVehicle[tempKey].group = 'VEH-' + (lastVehicleIndex + 1);
                    if (tempVehicle[tempKey].questionOptions != null && tempVehicle[tempKey].questionOptions.length > 0) {
                        tempVehicle[tempKey].questionOptions = []; //empty question options to reduce payload size
                    }
                }
            });
            tempVehicle.interestList = [];
            if (tempVehicle.vehicleAddInsuredOrLossPayee.answer == 'Yes') {
                var vehicleInsuredOrLossPayee = { 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' };
                tempVehicle.interestList.push(vehicleInsuredOrLossPayee);
            }
            tempVehicle.vehicleType.answer = '';
            this.dao.vehicleModel.VehiclesList.push(tempVehicle);
            this.dao.isTrailer = false;
        }
        return true;
    }
    uploadingTrailers(): boolean {
        for (var key = 1; key <= this.excelTrailers.length - 1; key++) {
            let isAddVehicleBtn = this.isShowActinBtn('trailer');
            if (this.dao.isTrailer && this.dao.vehicleModel.trailerList.length > 0 && isAddVehicleBtn) {
                this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerList.length - 1] = _.cloneDeep(this.dao.vehicleModel.vehicleItem);
            }
            var lastTrailerIndex = 0
            this.dao.vehicleModel.trailerList.forEach((trailer, index) => {
                Object.keys(trailer).forEach(tempKey => {
                    if (typeof (trailer[tempKey]) == 'object') {
                        trailer[tempKey].group = ('TRA-' + (index + 1));
                    }
                });
                lastTrailerIndex = index + 1;
            });
            let tempTrailer = _.cloneDeep(this.dao.vehicleModel.vehicleDefault);

            // 1st form
            tempTrailer.vehicleYear.answer = this.getAndSetValidYear(this.excelTrailers[key]["Year"]);
            tempTrailer.vehicleMake.answer = this.getAndSetValidString(this.excelTrailers[key]["Make"]);
            tempTrailer.vehicleModel.answer = this.getAndSetValidString(this.excelTrailers[key]["Model"]);
            tempTrailer.vehicleVin.answer = !!this.excelTrailers[key]["VIN"] ? this.excelTrailers[key]["VIN"].trim() : "";
            // 2nd form 
            tempTrailer.vehicleIsCorrectGaragingAddress.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelTrailers[key]["Is this the correct garaging address?"]);
            tempTrailer.trailerSize.answer = this.getValidDropdownAnswerFromExcel('trailerSize', this.excelTrailers[key]["Trailer Weight Class"]);
            tempTrailer.trailerBodyType.answer = this.getValidDropdownAnswerFromExcel('trailerBodyType', this.excelTrailers[key]["Trailer Body Type"]);
            tempTrailer.vehicleSecondaryClass.answer = this.getValidDropdownAnswerFromExcel('vehicleSecondaryClass', this.excelTrailers[key]["Secondary Classification"]);
            tempTrailer.vehicleTotalMilesUpto50.answer = isNaN(this.excelTrailers[key]["Local - Annual miles <= 50 miles (in %)"]) ? "" : this.excelTrailers[key]["Local - Annual miles <= 50 miles (in %)"];
            tempTrailer.vehicleTotalMilesUpto200.answer = isNaN(this.excelTrailers[key]["Intermediate - 50 miles > Annual miles <= 200 (in %)"]) ? "" : this.excelTrailers[key]["Intermediate - 50 miles > Annual miles <= 200 (in %)"];
            // 3rd form 
            const accounState = this.dao.DOTList.accountInfoQsnr.findQuestion("accountState")?.answer;
            if (accounState != 'MI') {
                tempTrailer.vehicleCompOTCCoverage.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelTrailers[key]["Comprehensive Deductible"]);
                tempTrailer.vehicleComp.answer = tempTrailer.vehicleCompOTCCoverage.answer == 'Yes' ? this.getValidDropdownAnswerFromExcel('vehicleComp', this.excelTrailers[key]["__EMPTY"]) : "";
                tempTrailer.vehicleCollCoverage.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelTrailers[key]["Collision Deductible"]);
                tempTrailer.vehicleColl.answer = tempTrailer.vehicleCollCoverage.answer == 'Yes' ? this.getValidDropdownAnswerFromExcel('vehicleColl', this.excelTrailers[key]["__EMPTY_1"]) : "";
                tempTrailer.floorTrailer.answer = tempTrailer.vehicleCollCoverage.answer == 'Yes' ? 'No' : "";
            }
            else {
                tempTrailer.vehicleCompOTCCoverage.answer = "";
                tempTrailer.vehicleCollCoverage.answer = "";
            }
            tempTrailer.vehicleStatedAmount.answer = isNaN(this.excelTrailers[key]["Stated Amount per Unit (Including custom parts & equipment)"]) ? "" : Math.round(this.excelTrailers[key]["Stated Amount per Unit (Including custom parts & equipment)"].trim());
            tempTrailer.vehicleCustomPartsParent.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelTrailers[key]["Are there custom parts and equipment on this unit?"]);
            tempTrailer.vehicleCustomAmount.answer = tempTrailer.vehicleCustomPartsParent.answer == 'Yes' ? (isNaN(this.excelTrailers[key]["__EMPTY_2"]) ? "" : Math.round(this.excelTrailers[key]["__EMPTY_2"].trim())) : "";
            tempTrailer.vehicleCustomDesc.answer = tempTrailer.vehicleCustomPartsParent.answer == 'Yes' ? this.excelTrailers[key]["__EMPTY_3"] : "";
            tempTrailer.vehicleAddInsuredOrLossPayee.answer = this.getValidRadiobuttonAnswerFromExcel(this.excelTrailers[key]["Does this unit have an additional insured or loss payee?"]);

            if (this.dao.vehicleModel.trailerList.length > 0 && this.dao.vehicleModel.trailerList.every(item => item.isChecked)) {
                tempTrailer.isChecked = true;
            }
            Object.keys(tempTrailer).forEach(tempKey => {
                if (typeof (tempTrailer[tempKey]) == 'object') {
                    tempTrailer[tempKey].group = 'TRA-' + (lastTrailerIndex + 1);
                    if (tempTrailer[tempKey].questionOptions != null && tempTrailer[tempKey].questionOptions.length > 0) {
                        tempTrailer[tempKey].questionOptions = []; //empty question options to reduce payload size
                    }
                }
            });
            tempTrailer.interestList = [];
            if (tempTrailer.vehicleAddInsuredOrLossPayee.answer == 'Yes') {
                var vehicleInsuredOrLossPayee = { 'Type': '', 'Name': '', 'streetAddress': '', 'city': '', 'state': '', 'zip': '', 'loanNo': '' };
                tempTrailer.interestList.push(vehicleInsuredOrLossPayee);
            }
            tempTrailer.vehicleType.answer = 'Unknown';
            this.dao.vehicleModel.trailerList.push(tempTrailer);
            this.dao.isTrailer = true;
        }
        return true;
    }
    getValidDropdownAnswerFromExcel(key: string, value: string): string {
        if (!!!value) {
            return "";
        }
        value = value.toUpperCase().trim().replace(/ /g, "").replace(",", "");
        if (this.dao.vehicleModel.vehicleDefault[key].questionOptions.some(x => x.label.toUpperCase().trim().replace(/ /g, "").replace(",", "") == value)) {
            return this.dao.vehicleModel.vehicleDefault[key].questionOptions.find(x => x.label.toUpperCase().trim().replace(/ /g, "").replace(",", "") == value).code;
        }
        return "";
    }
    getValidRadiobuttonAnswerFromExcel(value: string): string {
        if (!!!value) {
            return "";
        }
        value = value.toUpperCase().trim();
        if (value == 'YES') {
            return 'Yes';
        }
        if (value == 'NO') {
            return 'No';
        }
        return "";
    }
    getAndSetValidYear(year: string): string {
        if (!!!year) {
            return "";
        }
        year = year.trim();
        const isValidYear = /^\d{4}$/.test(year);
        if (isValidYear) {
            return year;
        }
        return "";
    }
    getAndSetValidString(value: string): string {
        if (!!!value) {
            return "";
        }
        if (!this.userInputService.valueContainsNonAlphaNumericCharacters(value)) {
            return value.trim();
        }
        return "";
    }
    clickedItem(type, item, index) {
        this.callRadiusAndCoverageToAllUnits();
        this.removingHighlightionOfInvalidVehicle('navigationThroughList');
        this.dao.vehicleModel.vehicleItem = item;
        this.dao.vehicleModel.vehiclePage = 1;
        if (type == 'powerunit') {
            this.dao.vehicleModel.vehicleIndex = index;
            this.dao.vehicleModel.trailerIndex = 0;
            this.dao.isTrailer = false;
        }
        else {
            this.dao.vehicleModel.trailerIndex = index;
            this.dao.vehicleModel.vehicleIndex = 0;
            this.dao.isTrailer = true;
        }
    }
    removingHighlightionOfInvalidVehicle(type: string) {
        if (!!!this.areVinNumbersDuplicated && type != 'delete') {
            let isCurrentItemValid = this.validatingItem();
            let invalidVehicleIndex = this.dao.vehicleModel.vehicleItem.vehicleVin.group.includes("VEH") ?
                this.vehicleForm?.form?.errors?.invalidPowerUnitList : this.vehicleForm?.form?.errors?.invalidTrailerList;
            invalidVehicleIndex = !!!invalidVehicleIndex ? [] : invalidVehicleIndex;
            let getCurrentUnitIndex = this.dao.vehicleModel.vehicleItem.vehicleVin.group.includes("VEH") ? this.dao.vehicleModel.vehicleIndex : this.dao.vehicleModel.trailerIndex;
            if (!!!isCurrentItemValid && !!!invalidVehicleIndex.includes(getCurrentUnitIndex)) {
                invalidVehicleIndex.push(getCurrentUnitIndex);
            }
            if (!!isCurrentItemValid && !!invalidVehicleIndex.includes(getCurrentUnitIndex)) {
                let getIndex = invalidVehicleIndex.indexOf(getCurrentUnitIndex);
                invalidVehicleIndex.splice(getIndex, 1);
            }
            if (this.dao.vehicleModel.vehicleItem.vehicleVin.group.includes("VEH")) {
                if (this.vehicleForm?.form?.errors?.invalidTrailer) {
                    this.vehicleForm?.form.setErrors({
                        'invalidPowerUnit': true,
                        'invalidPowerUnitList': invalidVehicleIndex,
                        'invalidTrailer': true,
                        'invalidTrailerList': this.vehicleForm?.form?.errors?.invalidTrailerList
                    });
                }
                else {
                    this.vehicleForm?.form.setErrors({
                        'invalidPowerUnit': true,
                        'invalidPowerUnitList': invalidVehicleIndex
                    });
                }
            }
            else {
                if (this.vehicleForm?.form?.errors?.invalidPowerUnit) {
                    this.vehicleForm?.form.setErrors({
                        'invalidPowerUnit': true,
                        'invalidPowerUnitList': this.vehicleForm?.form?.errors?.invalidPowerUnitList,
                        'invalidTrailer': true,
                        'invalidTrailerList': invalidVehicleIndex
                    });
                }
                else {
                    this.vehicleForm?.form.setErrors({
                        'invalidTrailer': true,
                        'invalidTrailerList': invalidVehicleIndex
                    });
                }
            }
        }
        if (!!this.areVinNumbersDuplicated && type == 'delete') {
            this.checkDuplicateVinNumberExists(type);
        }
    }
    validatingItem(): boolean {
        this.dao.vehicleModel.vehicleItem.vehicleYear.answer = this.getAndSetValidYear(this.dao.vehicleModel.vehicleItem.vehicleYear.answer);
        this.dao.vehicleModel.vehicleItem.vehicleMake.answer = this.getAndSetValidString(this.dao.vehicleModel.vehicleItem.vehicleMake.answer);
        if (!!this.dao.vehicleModel.vehicleItem.vehicleModel.answer) {
            this.dao.vehicleModel.vehicleItem.vehicleModel.answer = this.getAndSetValidString(this.dao.vehicleModel.vehicleItem.vehicleModel.answer);
        }
        if (!!!this.dao.vehicleModel.vehicleItem.vehicleYear.answer || !!!this.dao.vehicleModel.vehicleItem.vehicleMake.answer
            || ((!!!this.dao.vehicleModel.vehicleItem.vehicleModel.answer || !!!this.dao.vehicleModel.vehicleItem.vehicleVin.answer) && this.dao.vehicleModel.vehicleItem.vehicleBodyType.group.includes("VEH"))
            || !!!this.dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer
            || ((!!!this.dao.vehicleModel.vehicleItem.vehicleSize.answer || !!!this.dao.vehicleModel.vehicleItem.vehicleBodyType.answer) && this.dao.vehicleModel.vehicleItem.vehicleBodyType.group.includes("VEH"))
            || ((!!!this.dao.vehicleModel.vehicleItem.trailerSize.answer || !!!this.dao.vehicleModel.vehicleItem.trailerBodyType.answer) && this.dao.vehicleModel.vehicleItem.vehicleBodyType.group.includes("TRA"))
            || !!!this.dao.vehicleModel.vehicleItem.vehicleSecondaryClass.answer || (!!!this.dao.vehicleModel.vehicleItem.vehicleTravelOver200.answer && this.dao.vehicleModel.vehicleItem.vehicleTravelOver200.enabled)
            || (this.dao.vehicleModel.vehicleItem.farthestApplicableZonePercent.enabled && this.dao.vehicleModel.vehicleItem.farthestToZone.enabled && this.dao.vehicleModel.vehicleItem.farthestToZone.answer !== '' && !!!this.dao.vehicleModel.vehicleItem.farthestApplicableZonePercent.answer)
            || !!!this.dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.answer || (!!this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.enabled && !!!this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.answer)
            || !!!this.dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer || !!!this.dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer) {
            return false;
        }
        if (!!this.dao.vehicleModel.vehicleItem.vehicleModel.answer && this.dao.vehicleModel.vehicleItem.vehicleModel.answer.length > 25) {
            return false;
        }
        if (!!this.dao.vehicleModel.vehicleItem.vehicleVin.answer) {
            if (this.dao.vehicleModel.vehicleItem.vehicleType.group.indexOf('VEH') > -1
                && this.dao.vehicleModel.vehicleItem.vehicleVin.answer.length > 0) {
                if (this.dao.vehicleModel.vehicleItem.vehicleYear.answer < 1981
                    && (this.dao.vehicleModel.vehicleItem.vehicleVin.answer.length > 13 || this.dao.vehicleModel.vehicleItem.vehicleVin.answer.length < 5)) {
                    return false;
                }
                else if (this.dao.vehicleModel.vehicleItem.vehicleYear.answer >= 1981 && this.dao.vehicleModel.vehicleItem.vehicleVin.answer.length != 17) {
                    return false;
                }
            }
        }
        if (this.dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer == 'No') {
            if (!!!this.dao.vehicleModel.vehicleItem.vehicleGarageStreetAddress.answer
                || !!!this.dao.vehicleModel.vehicleItem.vehicleGarageCity.answer
                || !!!this.dao.vehicleModel.vehicleItem.vehicleGaragePostalCode.answer) {
                return false;
            }
        }
        if (!this.getTotalPercent(this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto50.answer, this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto200.answer, this.dao.vehicleModel.vehicleItem.zoneList, this.dao.vehicleModel.vehicleItem.farthestApplicableZonePercent.answer)) {
            return false;
        }
        if (this.dao.vehicleModel.vehicleItem.vehicleTravelOver200.answer == 'Yes') {
            let isSameZoneExists = this.checkSameZone(this.dao.vehicleModel.vehicleItem.zoneList);
            if (isSameZoneExists != ''
                || (this.dao.vehicleModel.vehicleItem.vehicleApplicableZonePercent.required && this.dao.vehicleModel.vehicleItem.zoneList.some((zone) => !!!zone.percentage))
                || (this.dao.vehicleModel.vehicleItem.vehicleToZone.required && this.dao.vehicleModel.vehicleItem.zoneList.some((zone) => !!!zone.zone))) {
                return false;
            }
        }
        if (this.dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.answer == 'Yes') {
            if (!!!this.dao.vehicleModel.vehicleItem.vehicleComp.answer || !!!this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer || this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer == '0') {
                return false;
            }
            if (this.dao.vehicleModel.vehicleItem.vinEtched.enabled && !!!this.dao.vehicleModel.vehicleItem.vinEtched.answer) {
                return false;
            }
            if (!!!this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.enabled && !!!this.dao.vehicleModel.vehicleItem.vehicleColl.answer) {
                return false;
            }
        }
        if (!!this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer) {
            if (this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer == '0.00')
                this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer = '0';
            let statedAmountValue = this.dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer;
            if (typeof (statedAmountValue) != 'string') {
                statedAmountValue = statedAmountValue.toString();
            }
            if (statedAmountValue.length > 0) {
                for (var i = 0; i < statedAmountValue.length; i++) {
                    var charCode = statedAmountValue.charCodeAt(i);
                    if (charCode < 47 || charCode > 58) {
                        return false;
                    }
                }
            }
        }
        const accounState = this.dao.DOTList.accountInfoQsnr.findQuestion("accountState")?.answer;
        if (!!accounState) {
            if (accounState != 'MI') {
                if (!!this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.enabled && this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.answer == 'Yes') {
                    if (this.dao.isTrailer && this.dao.vehicleModel.vehicleItem.floorTrailer.answer == '') {
                        this.dao.vehicleModel.vehicleItem.floorTrailer.answer = 'No';
                    }
                    if (!!!this.dao.vehicleModel.vehicleItem.vehicleColl.answer) {
                        return false;
                    }
                }
            }
            else {
                if (!!this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.enabled && !!!this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.answer) {
                    return false;
                }
                else {
                    if (!!this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.enabled && this.dao.vehicleModel.vehicleItem.vehicleCollCoverage.answer == 'Yes') {
                        if (this.dao.vehicleModel.vehicleItem.Q_WhichCollCoverage.answer == 'Yes') {
                            if (!!!this.dao.vehicleModel.vehicleItem.vehicleColl.answer) {
                                return false;
                            }
                        }
                        else {
                            if (!!!this.dao.vehicleModel.vehicleItem.broadenedCollision.answer) {
                                return false;
                            }
                        }
                        if (this.dao.DOTList.questionnaire.filter(x => x.key == 'vehicle')[0].questionAnswer.some(z => z.key == 'floorTrailer')
                            && this.dao.vehicleModel.vehicleItem.vehicleVin.group.includes("TRA")
                            && !!!this.dao.vehicleModel.vehicleItem.floorTrailer.answer) {
                            return false;
                        }
                    }
                    else {
                        if (!!!this.dao.vehicleModel.vehicleItem.Q_limitedCollision.answer) {
                            return false;
                        }
                        if (this.dao.vehicleModel.vehicleItem.Q_limitedCollision.answer == 'Yes') {
                            if (!!!this.dao.vehicleModel.vehicleItem.limitedCollision.answer) {
                                return false;
                            }
                        }
                    }
                }
            }
        }
        if (this.dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer == 'Yes') {
            if (!!!this.dao.vehicleModel.vehicleItem.vehicleCustomAmount.answer || this.dao.vehicleModel.vehicleItem.vehicleCustomAmount.answer == '0' || !!!this.dao.vehicleModel.vehicleItem.vehicleCustomDesc.answer) {
                return false;
            }
            if (!!this.dao.vehicleModel.vehicleItem.vehicleCustomAmount.answer) {
                let customAmountValue = this.dao.vehicleModel.vehicleItem.vehicleCustomAmount.answer;
                if (typeof (customAmountValue) != 'string') {
                    customAmountValue = customAmountValue.toString();
                }
                if (customAmountValue.length > 0) {
                    for (var i = 0; i < customAmountValue.length; i++) {
                        var charCode = customAmountValue.charCodeAt(i);
                        if (charCode < 47 || charCode > 58) {
                            return false;
                        }
                    }
                }
            }
        }
        if (this.dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer == 'Yes') {
            if (this.dao.vehicleModel.vehicleItem.interestList.length > 0) {
                for (var i = 0; i < this.dao.vehicleModel.vehicleItem.interestList.length; i++) {
                    if (!!!this.dao.vehicleModel.vehicleItem.interestList[i].Name || !!!this.dao.vehicleModel.vehicleItem.interestList[i].Type
                        || !!!this.dao.vehicleModel.vehicleItem.interestList[i].city || !!!this.dao.vehicleModel.vehicleItem.interestList[i].state
                        || !!!this.dao.vehicleModel.vehicleItem.interestList[i].streetAddress || !!!this.dao.vehicleModel.vehicleItem.interestList[i].zip) {
                        return false;
                    }
                }
            }
        }
        this.dao.vehicleModel.vehicleItem.vehicleVin.group.includes("VEH") ? this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex].isReviewed = true : this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex].isReviewed = true;
        return true;
    }
    validatingVehicleList() {
        let invalidPowerUnitIndex = [];
        let invalidTrailerIndex = [];
        let tempVehicleList = [];
        tempVehicleList = this.dao.vehicleModel.VehiclesList;
        tempVehicleList = tempVehicleList.concat(this.dao.vehicleModel.trailerList);
        for (var i = 0; i < tempVehicleList.length; i++) {
            tempVehicleList[i].vehicleYear.answer = this.getAndSetValidYear(tempVehicleList[i].vehicleYear.answer);
            tempVehicleList[i].vehicleMake.answer = this.getAndSetValidString(tempVehicleList[i].vehicleMake.answer);
            if (!!tempVehicleList[i].vehicleModel.answer) {
                tempVehicleList[i].vehicleModel.answer = this.getAndSetValidString(tempVehicleList[i].vehicleModel.answer);
            }
            if (!!!tempVehicleList[i].vehicleYear.answer || !!!tempVehicleList[i].vehicleMake.answer
                || ((!!!tempVehicleList[i].vehicleModel.answer || !!!tempVehicleList[i].vehicleVin.answer) && tempVehicleList[i].vehicleBodyType.group.includes("VEH"))
                || !!!tempVehicleList[i].vehicleIsCorrectGaragingAddress.answer
                || ((!!!tempVehicleList[i].vehicleSize.answer || !!!tempVehicleList[i].vehicleBodyType.answer) && tempVehicleList[i].vehicleBodyType.group.includes("VEH"))
                || ((!!!tempVehicleList[i].trailerSize.answer || !!!tempVehicleList[i].trailerBodyType.answer) && tempVehicleList[i].vehicleBodyType.group.includes("TRA"))
                || !!!tempVehicleList[i].vehicleSecondaryClass.answer || (!!!tempVehicleList[i].vehicleTravelOver200.answer && tempVehicleList[i].vehicleTravelOver200.enabled)
                || (tempVehicleList[i].farthestApplicableZonePercent.enabled && tempVehicleList[i].farthestToZone.enabled && tempVehicleList[i].farthestToZone.answer !== '' && !!!tempVehicleList[i].farthestApplicableZonePercent.answer)
                || !!!tempVehicleList[i].vehicleCompOTCCoverage.answer || (!!tempVehicleList[i].vehicleCollCoverage.enabled && !!!tempVehicleList[i].vehicleCollCoverage.answer)
                || !!!tempVehicleList[i].vehicleCustomPartsParent.answer || !!!tempVehicleList[i].vehicleAddInsuredOrLossPayee.answer) {
                tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                continue;
            }
            if (!!tempVehicleList[i].vehicleModel.answer && tempVehicleList[i].vehicleModel.answer.length > 25) {
                tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                continue;
            }
            if (!!tempVehicleList[i].vehicleVin.required && tempVehicleList[i].vehicleVin.answer) {
                if (tempVehicleList[i].vehicleType.group.indexOf('VEH') > -1
                    && tempVehicleList[i].vehicleVin.answer.length > 0) {
                    if (tempVehicleList[i].vehicleYear.answer < 1981
                        && (tempVehicleList[i].vehicleVin.answer.length > 13 || tempVehicleList[i].vehicleVin.answer.length < 5)) {
                        tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                        continue;
                    }
                    else if (tempVehicleList[i].vehicleYear.answer >= 1981 && tempVehicleList[i].vehicleVin.answer.length != 17) {
                        tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                        continue;
                    }
                }
            }
            if (tempVehicleList[i].vehicleBodyType.group.includes("VEH") && (!!!tempVehicleList[i].vehicleBodyType.answer || !!!tempVehicleList[i].vehicleSize.answer)) {
                tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                continue;
            }
            if (tempVehicleList[i].trailerBodyType.group.includes("TRA") && (!!!tempVehicleList[i].trailerBodyType.answer || !!!tempVehicleList[i].trailerSize.answer)) {
                tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                continue;
            }
            if (tempVehicleList[i].vehicleIsCorrectGaragingAddress.answer == 'No') {
                if (!!!tempVehicleList[i].vehicleGarageStreetAddress.answer
                    || !!!tempVehicleList[i].vehicleGarageCity.answer
                    || !!!tempVehicleList[i].vehicleGaragePostalCode.answer) {
                    tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                    continue;
                }
            }
            if (!this.getTotalPercent(tempVehicleList[i].vehicleTotalMilesUpto50.answer, tempVehicleList[i].vehicleTotalMilesUpto200.answer, tempVehicleList[i].zoneList, tempVehicleList[i].farthestApplicableZonePercent.answer)) {
                tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                continue;
            }
            if (tempVehicleList[i].vehicleTravelOver200.answer == 'Yes') {
                let isSameZoneExists = this.checkSameZone(tempVehicleList[i].zoneList);
                if (isSameZoneExists != ''
                    || (tempVehicleList[i].vehicleApplicableZonePercent.required && tempVehicleList[i].zoneList.some((zone) => !!!zone.percentage))
                    || (tempVehicleList[i].vehicleToZone.required && tempVehicleList[i].zoneList.some((zone) => !!!zone.zone))) {
                    tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                    continue;
                }
            }
            if (tempVehicleList[i].vehicleCompOTCCoverage.answer == 'Yes') {
                if (!!!tempVehicleList[i].vehicleComp.answer || !!!tempVehicleList[i].vehicleStatedAmount.answer || tempVehicleList[i].vehicleStatedAmount.answer == '0') {
                    tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                    continue;
                }
                if (tempVehicleList[i].vinEtched.enabled && !!!tempVehicleList[i].vinEtched.answer) {
                    tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                    continue;
                }
                if (!!!tempVehicleList[i].vehicleCollCoverage.enabled && !!!tempVehicleList[i].vehicleColl.answer) {
                    tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                    continue;
                }
            }
            if (!!tempVehicleList[i].vehicleStatedAmount.answer) {
                if (tempVehicleList[i].vehicleStatedAmount.answer == '0.00')
                    tempVehicleList[i].vehicleStatedAmount.answer = '0';
                let statedAmountValue = tempVehicleList[i].vehicleStatedAmount.answer;
                if (typeof (statedAmountValue) != 'string') {
                    statedAmountValue = statedAmountValue.toString();
                }
                if (statedAmountValue.length > 0) {
                    for (var k = 0; k < statedAmountValue.length; k++) {
                        var charCode = statedAmountValue.charCodeAt(k);
                        if (charCode < 47 || charCode > 58) {
                            tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                            continue;
                        }
                    }
                }
            }
            const accounState = this.dao.DOTList.accountInfoQsnr.findQuestion("accountState")?.answer;
            if (!!accounState) {
                if (accounState != 'MI') {
                    if (!!tempVehicleList[i].vehicleCollCoverage.enabled && tempVehicleList[i].vehicleCollCoverage.answer == 'Yes') {
                        if (!!!tempVehicleList[i].vehicleColl.answer) {
                            tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                            continue;
                        }
                    }
                }
                else {
                    if (!!tempVehicleList[i].vehicleCollCoverage.enabled && !!!tempVehicleList[i].vehicleCollCoverage.answer) {
                        tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                        continue;
                    }
                    else {
                        if (!!tempVehicleList[i].vehicleCollCoverage.enabled && tempVehicleList[i].vehicleCollCoverage.answer == 'Yes') {
                            if (tempVehicleList[i].Q_WhichCollCoverage.answer == 'Yes') {
                                if (!!!tempVehicleList[i].vehicleColl.answer) {
                                    tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                                    continue;
                                }
                            }
                            else {
                                if (!!!tempVehicleList[i].broadenedCollision.answer) {
                                    tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                                    continue;
                                }
                            }
                            if (this.dao.DOTList.questionnaire.filter(x => x.key == 'vehicle')[0].questionAnswer.some(z => z.key == 'floorTrailer')
                                && tempVehicleList[i].vehicleVin.group.includes("TRA")
                                && !!!this.dao.vehicleModel.vehicleItem.floorTrailer.answer) {
                                tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                                continue;
                            }
                        }
                        else {
                            if (!!!tempVehicleList[i].Q_limitedCollision.answer) {
                                tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                                continue;
                            }
                            if (tempVehicleList[i].Q_limitedCollision.answer == 'Yes') {
                                if (!!!tempVehicleList[i].limitedCollision.answer) {
                                    tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                                    continue;
                                }
                            }
                        }
                    }
                }
            }
            if (tempVehicleList[i].vehicleCustomPartsParent.answer == 'Yes') {
                if (!!!tempVehicleList[i].vehicleCustomAmount.answer || tempVehicleList[i].vehicleCustomAmount.answer == '0' || !!!tempVehicleList[i].vehicleCustomDesc.answer) {
                    tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                    continue;
                }
                if (!!tempVehicleList[i].vehicleCustomAmount.answer) {
                    let customAmountValue = tempVehicleList[i].vehicleCustomAmount.answer;
                    if (typeof (customAmountValue) != 'string') {
                        customAmountValue = customAmountValue.toString();
                    }
                    if (customAmountValue.length > 0) {
                        for (var k = 0; k < customAmountValue.length; k++) {
                            var charCode = customAmountValue.charCodeAt(k);
                            if (charCode < 47 || charCode > 58) {
                                tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                                continue;
                            }
                        }
                    }
                }
            }
            if (tempVehicleList[i].vehicleAddInsuredOrLossPayee.answer == 'Yes') {
                if (tempVehicleList[i].interestList.length > 0) {
                    for (var j = 0; j < tempVehicleList[i].interestList.length; j++) {
                        if (!!!tempVehicleList[i].interestList[j].Name || !!!tempVehicleList[i].interestList[j].Type
                            || !!!tempVehicleList[i].interestList[j].city || !!!tempVehicleList[i].interestList[j].state
                            || !!!tempVehicleList[i].interestList[j].streetAddress || !!!tempVehicleList[i].interestList[j].zip) {
                            tempVehicleList[i].vehicleVin.group.includes("VEH") ? invalidPowerUnitIndex.push(i) : invalidTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                            continue;
                        }
                    }
                }
            }
            tempVehicleList[i].isReviewed = true;
        }
        if (invalidPowerUnitIndex.length > 0) {
            invalidPowerUnitIndex = Array.from(new Set(invalidPowerUnitIndex));
            this.vehicleForm?.form.setErrors({
                'invalidPowerUnit': true,
                'invalidPowerUnitList': invalidPowerUnitIndex
            });
        }
        if (invalidTrailerIndex.length > 0) {
            invalidTrailerIndex = Array.from(new Set(invalidTrailerIndex));
            if (this.vehicleForm?.form?.errors?.invalidPowerUnit) {
                this.vehicleForm?.form.setErrors({
                    'invalidPowerUnit': true,
                    'invalidPowerUnitList': invalidPowerUnitIndex,
                    'invalidTrailer': true,
                    'invalidTrailerList': invalidTrailerIndex
                });
            }
            else {
                this.vehicleForm?.form.setErrors({
                    'invalidTrailer': true,
                    'invalidTrailerList': invalidTrailerIndex
                });
            }
        }
        return { invalidPowerUnitIndex, invalidTrailerIndex }
    }
    checkDuplicateVinNumberExists(actionType: string) {
        this.areVinNumbersDuplicated = false;
        let duplicatePowerUnitIndex = [];
        let duplicateTrailerIndex = [];
        let tempVehicleList = [];
        tempVehicleList = this.dao.vehicleModel.VehiclesList;
        tempVehicleList = tempVehicleList.concat(this.dao.vehicleModel.trailerList);
        for (let i = 0; i < tempVehicleList.length; i++) {
            if (!!tempVehicleList[i].vehicleVin.answer) {
                for (let j = i + 1; j < tempVehicleList.length; j++) {
                    if (_.isEqual(tempVehicleList[i].vehicleVin.answer, tempVehicleList[j].vehicleVin.answer)) {
                        this.areVinNumbersDuplicated = actionType == 'save';
                        tempVehicleList[i].vehicleVin.group.includes("VEH") ?
                            duplicatePowerUnitIndex.push(i) :
                            duplicateTrailerIndex.push(i - this.dao.vehicleModel.VehiclesList.length);
                        tempVehicleList[j].vehicleVin.group.includes("VEH") ?
                            duplicatePowerUnitIndex.push(j) :
                            duplicateTrailerIndex.push(j - this.dao.vehicleModel.VehiclesList.length);
                    }
                }
            }
        }
        if (duplicatePowerUnitIndex.length > 0) {
            duplicatePowerUnitIndex = Array.from(new Set(duplicatePowerUnitIndex));
            this.vehicleForm?.form.setErrors({
                'duplicatePowerUnit': true,
                'duplicatePowerUnitList': duplicatePowerUnitIndex
            });
        }
        if (duplicateTrailerIndex.length > 0) {
            duplicateTrailerIndex = Array.from(new Set(duplicateTrailerIndex));
            if (!!this.vehicleForm?.form?.errors?.duplicatePowerUnit) {
                this.vehicleForm?.form.setErrors({
                    'duplicatePowerUnit': true,
                    'duplicatePowerUnitList': duplicatePowerUnitIndex,
                    'duplicateTrailer': true,
                    'duplicateTrailerList': duplicateTrailerIndex
                });
            }
            else {
                this.vehicleForm?.form.setErrors({
                    'duplicateTrailer': true,
                    'duplicateTrailerList': duplicateTrailerIndex
                });
            }
        }
        return { duplicatePowerUnitIndex, duplicateTrailerIndex }
    }
    public async vehicleContinue(step, key = 'Next') {
        let zoneValidation = '';
        zoneValidation = this.getTotalPercent(this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto50.answer, this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto200.answer, this.dao.vehicleModel.vehicleItem.zoneList, this.dao.vehicleModel.vehicleItem.farthestApplicableZonePercent.answer) ? '' : 'Total radius percentage must equal 100%';
        zoneValidation = zoneValidation != '' ? zoneValidation : this.checkSameZone(this.dao.vehicleModel.vehicleItem.zoneList);
        if ((step != 2 || (zoneValidation == '' && this.getTotalPercent(this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto50.answer, this.dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto200.answer, this.dao.vehicleModel.vehicleItem.zoneList, this.dao.vehicleModel.vehicleItem.farthestApplicableZonePercent.answer)))) {
            this.removingHighlightionOfInvalidVehicle('nextOrBack');
            if (this.dao.vehicleModel.vehiclePage == 3) {
                if (key == 'Next') {
                    this.dao.vehicleModel.vehiclePage = 1;
                    this.dao.vehicleModel.vehicleItem.isReviewed = true;
                    if (!this.dao.isTrailer) {
                        this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex] = _.cloneDeep(this.dao.vehicleModel.vehicleItem);
                        if (this.dao.vehicleModel.VehiclesList.length - 1 == this.dao.vehicleModel.vehicleIndex) {
                            this.dao.isTrailer = true;
                            this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] ? this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] : this.dao.vehicleModel.vehicleItem;
                            this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.vehicleIndex + 1;
                        }
                        else {
                            this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.vehicleIndex + 1;
                            this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex] ? this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex] : this.dao.vehicleModel.vehicleItem;
                        }
                    }
                    else {
                        this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] = _.cloneDeep(this.dao.vehicleModel.vehicleItem);
                        this.dao.vehicleModel.trailerIndex = this.dao.vehicleModel.trailerIndex + 1;
                        this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] ? this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] : this.dao.vehicleModel.vehicleItem;
                    }
                }
                else {
                    this.dao.vehicleModel.vehiclePage = this.dao.vehicleModel.vehiclePage - 1;
                }
            }
            else {
                if (key == 'Next') {
                    this.dao.vehicleModel.vehiclePage = this.dao.vehicleModel.vehiclePage + 1;
                }
                else {
                    this.dao.vehicleModel.vehiclePage = this.dao.vehicleModel.vehiclePage - 1;
                }

            }
            if (step == 1) {
                const accounState = this.dao.DOTList.accountInfoQsnr.findQuestion("accountState")?.answer;
                this.dao.vehicleModel.vehicleItem.vehicleGarageState.answer = (this.dao.vehicleModel.vehicleItem.vehicleGarageState.answer && this.dao.vehicleModel.vehicleItem.vehicleGarageState.answer != '') ? this.dao.vehicleModel.vehicleItem.vehicleGarageState.answer : accounState;
                this.dao.vehicleModel.vehicleItem.vehicleGaragingAddress = (this.dao.vehicleModel.vehicleItem.vehicleGaragingAddress.answer && this.dao.vehicleModel.vehicleItem.vehicleGaragingAddress.answer != '') ? this.dao.vehicleModel.vehicleItem.vehicleGaragingAddress : this.serviceLayer.createNewGaragingAddressFromAccountAddress(this.dao?.vehicleModel?.vehicleItem?.vehicleGaragingAddress?.group);
                this.dao.vehicleModel.vehicleItem.vehicleSecondaryClass.answer = (this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length > 0 && this.dao.vehicleModel.VehiclesList[0].vehicleSecondaryClass && this.dao.vehicleModel.vehicleItem.vehicleSecondaryClass.answer == '') ? this.dao.vehicleModel.VehiclesList[0].vehicleSecondaryClass.answer : this.dao.vehicleModel.vehicleItem.vehicleSecondaryClass.answer;
            }
        }
    }

    // ============= Remove Vehicle
    public async removeVehicle(item) {
        this.dao.IsDriverBreadcrumpClicked = false;
        this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.VehiclesList.indexOf(item);
        this.dao.vehicleModel.VehiclesList.splice(this.dao.vehicleModel.vehicleIndex, 1);
        this.dao.vehicleModel.VehiclesList.forEach((vehicle, index) => {
            Object.keys(vehicle).forEach(tempKey => {
                if (typeof (vehicle[tempKey]) == 'object') {
                    vehicle[tempKey].group = ('VEH-' + (index + 1));
                }
            });
        });

        if (!this.dao.isTrailer) {
            this.dao.vehicleModel.vehiclePage = 1;
            if (this.dao.vehicleModel.VehiclesList.length == 0) {
                this.dao.vehicleModel.vehicleItem = new vehicleDetails();
                this.serviceLayer.addVehicle('vehicle');
            }
            else {
                if (!!this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex]) {
                    this.dao.vehicleModel.vehicleItem = _.cloneDeep(this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex]);
                }
                else {
                    if (this.dao.vehicleModel.trailerList.length > 0) {
                        if (this.dao.vehicleModel.VehiclesList.length > 0 && !!!this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex]) {
                            this.dao.vehicleModel.trailerIndex = this.dao.vehicleModel.trailerIndex - 1;
                        }
                        this.dao.vehicleModel.vehicleItem = _.cloneDeep(this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex])
                        this.dao.isTrailer = true;
                    }
                    else if (!!this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex - 1]) {
                        this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.vehicleIndex - 1;
                        this.dao.vehicleModel.vehicleItem = _.cloneDeep(this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.vehicleIndex]);
                    }
                    else {
                        this.dao.vehicleModel.vehicleItem = new vehicleDetails();
                    }
                }
            }
        }
    }
    // ============= Remove Trailer
    public async removeTrailer(item) {
        this.dao.vehicleModel.trailerIndex = this.dao.vehicleModel.trailerList.indexOf(item);
        this.dao.vehicleModel.trailerList.splice(this.dao.vehicleModel.trailerIndex, 1);
        this.dao.vehicleModel.trailerList.forEach((trailer, index) => {
            Object.keys(trailer).forEach(tempKey => {
                if (typeof (trailer[tempKey]) == 'object') {
                    trailer[tempKey].group = ('TRA-' + (index + 1));
                }
            });
        });
        if (this.dao.isTrailer) {
            if (this.dao.vehicleModel.trailerList.length > 0 && !!!this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex]) {
                this.dao.vehicleModel.trailerIndex = this.dao.vehicleModel.trailerIndex - 1;
            }
            this.dao.vehicleModel.vehicleItem = _.cloneDeep(this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] ? this.dao.vehicleModel.trailerList[this.dao.vehicleModel.trailerIndex] : this.dao.vehicleModel.vehicleItem);
            this.dao.vehicleModel.vehiclePage = 1;
            if (this.dao.vehicleModel.trailerList.length == 0) {
                this.dao.isTrailer = false;
                if (this.dao.vehicleModel.VehiclesList.length > 0) {
                    this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.VehiclesList[this.dao.vehicleModel.VehiclesList.length - 1];
                    this.dao.vehicleModel.vehicleIndex = this.dao.vehicleModel.VehiclesList.indexOf(this.dao.vehicleModel.vehicleItem);
                }
                else {
                    this.dao.vehicleModel.vehicleItem = new vehicleDetails();
                    this.serviceLayer.addVehicle('vehicle');
                }
            }
        }
    }

    public collisionDedCheckBoxChecked() {
        this.dao.IsEditCollDeductibleSaved = false;
        if (this.dao.VehicleBulkEditCollCoverageCheckbox) {
            if (this.dao.vehicleModel.trailerList.length > 0) {
                this.dao.vehicleModel.trailerList.forEach(trailer => {
                    if (trailer.floorTrailer.answer == '') {
                        trailer.floorTrailer.answer = 'No';
                    }
                })
            }
        }
    }

    public hasInvalidPowerUnitAge(): boolean {
        let isValidAge: boolean = false;
        if (!!this.dao.vehicleModel.VehiclesList) {
            let numberOfVehicles = this.dao.vehicleModel.VehiclesList.length;
            let numberOfVehicleWithAgeGreaterThan25 = this.dao.vehicleModel.VehiclesList.filter(x => (new Date().getFullYear() - Number(x.vehicleYear?.answer)) > 25)?.length;
            if (numberOfVehicles > 0) {
                let vehicleAge = (numberOfVehicleWithAgeGreaterThan25 / numberOfVehicles) * 100;
                isValidAge = vehicleAge > 25;
            }
        }
        return isValidAge
    }

    // ========= Cancel =====================
    public cancel(): void {
        this.messageService.clear();
        document.body.style.pointerEvents = "visible";
    }

    public skipVehicleAgeValidation(): void {
        this.vehicleAgeWarning = true;
        this.messageService.clear();
        document.getElementById('btnsaveContinueID').click();
        document.body.style.pointerEvents = "visible";
    }
    private checkSameZone(vehicleItem) {
        let validationStr = '';
        if (vehicleItem.length > 0) {
            vehicleItem.forEach(z => {
                if (vehicleItem.filter(zone => zone.zone == z.zone).length > 1) {
                    validationStr = "This vehicle cannot have the same zone, please select a different zone.";
                }
            });
        }
        return validationStr;
    }
    public EditAddZone() {
        var tempArray = { 'zone': '', 'percentage': '', 'helpText': '' };
        let validationStr = this.EditcheckSameZone();
        if (validationStr == '') {
            var tempObj = _.cloneDeep(this.dao.editAllUnits.zoneList);
            tempObj.push(tempArray);
            this.dao.editAllUnits.zoneList = _.cloneDeep(tempObj);
        }
        else {
            this.appComponent.addToast('error', 'Unit Info', validationStr);
        }
    }
    private EditcheckSameZone() {
        let validationStr = '';
        if (this.dao.editAllUnits && this.dao.editAllUnits.zoneList && this.dao.editAllUnits.zoneList.length > 0) {
            this.dao.editAllUnits.zoneList.forEach(z => {
                if (this.dao.editAllUnits.zoneList.filter(zone => zone.zone == z.zone).length > 1) {
                    validationStr = "This vehicle cannot have the same zone, please select a different zone.";
                }
            });
        }
        return validationStr;
    }

    // =============vehicle Oninit====
    private vehicleOnInit() {
        this.dao.isTrailer = false;
        if (this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length > 0) {
            if (this.dao.finalSubmitPage != 'driver' && this.dao.finalSubmitPage != 'vehicles' && this.dao.finalSubmitPage != 'product' && this.dao.finalSubmitPage != 'application') {
                this.dao.vehicleModel.VehiclesList.forEach(v => {
                    v.isReviewed = false;
                });
            }
            this.dao.vehicleModel.vehicleIndex = 0;
            this.dao.vehicleModel.vehicleItem = this.dao.vehicleModel.VehiclesList[0];
        }
        if (this.dao.vehicleModel.trailerList && this.dao.vehicleModel.trailerList.length > 0) {
            if (this.dao.finalSubmitPage != 'driver' && this.dao.finalSubmitPage != 'vehicles' && this.dao.finalSubmitPage != 'product' && this.dao.finalSubmitPage != 'application') {
                this.dao.vehicleModel.trailerList.forEach(v => {
                    v.isReviewed = false;
                });
            }
            this.dao.vehicleModel.trailerIndex = 0;
            this.dao.vehicleModel.vehicleItem = (this.dao.vehicleModel.VehiclesList && this.dao.vehicleModel.VehiclesList.length > 0) ? this.dao.vehicleModel.VehiclesList[0] : this.dao.vehicleModel.trailerList[0];
        }
        this.dao.vehicleModel.vehiclePage = 1;
        this.dao.initialvehicleModel = _.cloneDeep(this.dao.vehicleModel);
    }

    public shouldLongDistanceNewTruckingDisplay(): boolean {
        const policyEffectiveDate = this.dao.DOTList?.accountInfoQsnr.findQuestion('policyEffectiveDate').answer;
        const accountState = this.dao.DOTList?.accountInfoQsnr.findQuestion('accountState').answer;
        if(this.serviceLayer.getPolicyHolderVersionNTM(policyEffectiveDate, accountState)) {
            return true;
        }

        return false;
    }

    public ngOnDestroy(): void {
        if (!!this.apiCallSubscription) {
            this.apiCallSubscription.unsubscribe();
            this.apiCallSubscription = null;
        }
    }
}
