<div class="container">
    <div class="vehiclescrn main_content mt-2">
        <form #vehicleForm="ngForm" class="width-100">
            <div class="row mt-0">
                <div class="col-lg-3 col-md-4">
                    <div class="vehicles_list mb-4">
                        <form #powerUnitList="ngForm" [validatingVehicleList]="'PowerUnit'">
                            <h5 class="float-left pr-4 mb-3 pr-15">Power Unit
                                {{this.dao.vehicleModel.VehiclesList.length}}
                            </h5>
                            <p-checkbox name="masterDeleteCheckBox" id="masterDeleteCheckBoxID"
                                *ngIf="dao.vehicleModel.VehiclesList.length > 0 " type="checkbox" binary="true"
                                [(ngModel)]="masterDeleteCheckBoxValue"
                                (onChange)="onChangeMasterPowerUnitCheckBox($event)">
                            </p-checkbox>
                            <span>
                                <a>
                                    <i class="fa fa-trash remove_icon pl-10" title="Delete"
                                        name="btnRemoveSelectedDVehicles" id="btnRemoveSelectedVehiclesID"
                                        aria-hidden="true" *ngIf="dao.vehicleModel.VehiclesList.length > 0"
                                        (click)="atleastOnePowerUnitSelected()"></i>
                                </a>
                            </span>
                            <span>
                                <a title="Add a Vehicle" *ngIf="!dao.HideVechicleButton">
                                    <i class="{{isShowActinBtn('finalSave') ? '' : 'disAddUnit'}} fa fa-plus-circle add_icon"
                                        name="AddAnotherUnit" id="AddAnotherUnitID"
                                        (click)="addVehicle('vehicle', $event);" aria-hidden="true"></i>
                                </a>
                            </span>
                            <div class="clearfix"></div>
                            <div class="menulen">
                                <div *ngFor="let item of dao?.vehicleModel?.VehiclesList; let i= index;"
                                    [class.active-item]="i == dao?.vehicleModel?.vehicleIndex && dao.vehicleModel.vehicleItem.vehicleType.group.indexOf('VEH') > -1">
                                    <h6 title="{{item?.vehicleYear?.answer}} {{item?.vehicleMake?.answer}} {{item?.vehicleModel?.answer}}"
                                        [class.highlight-invalid-item]="(vehicleForm?.errors?.invalidPowerUnit && vehicleForm?.errors?.invalidPowerUnitList?.includes(i))
                                                                 || (vehicleForm?.errors?.duplicatePowerUnit && vehicleForm?.errors?.duplicatePowerUnitList?.includes(i))">
                                        <label class="txtellipsis m-0 width-70">
                                            <a *ngIf="checkVehicleBlank(item)" name="EditVehicle_{{i}}"
                                                id="EditVehicleID_{{i}}" (click)="clickedItem('powerunit', item, i)">
                                                {{item?.vehicleYear?.answer}} {{item?.vehicleMake?.answer}}
                                                {{item?.vehicleModel?.answer}}</a>
                                            <a *ngIf="!checkVehicleBlank(item)" name="EditVehicleBlank_{{i}}"
                                                id="EditVehicleIDBlank_{{i}}"
                                                (click)="clickedItem('powerunit', item, i)">
                                                New Vehicle {{i + 1}} </a>
                                        </label>
                                        <span>
                                            <a class="float-right">
                                                <i class="fa fa-trash remove_icon" title="Delete" name="remove_{{i}}"
                                                    id="remove_{{i}}" (click)="remove(item,i,'vehicle')"
                                                    aria-hidden="true"></i>
                                            </a>
                                        </span>
                                        <p-checkbox class="delete-checkbox" name="deleteVehicleCheckBox"
                                            id="deleteVehicleCheckBoxID" type="checkbox" binary="true"
                                            [(ngModel)]="item.isChecked" #deleteVehicleCheckBox="ngModel"
                                            (onChange)="onVehicleCheckBoxChange($event)">
                                        </p-checkbox>
                                    </h6>
                                </div>
                            </div>
                        </form>
                        <a class="float-right viewmorecls" *ngIf="dao?.vehicleModel?.VehiclesList?.length > 10"
                            title="{{ViewMsg}}" (click)="viewClick('Vehicles')">{{ViewMsg}}</a>
                    </div>
                    <div class="vehicles_list">
                        <form #trailerList="ngForm" [validatingVehicleList]="'Trailer'">
                            <h5 class="float-left pr-4 mb-3 pr-15">Trailers {{dao.vehicleModel.trailerList.length}}</h5>
                            <p-checkbox *ngIf="dao.vehicleModel.trailerList.length > 0"
                                name="masterTrailerDeleteCheckBox" id="masterTrailerDeleteCheckBoxID" type="checkbox"
                                binary="true" [(ngModel)]="masterTrailerDeleteCheckBoxValue"
                                (onChange)="onChangeMasterTrailerCheckBox($event)">
                            </p-checkbox>
                            <span>
                                <a>
                                    <i *ngIf="dao.vehicleModel.trailerList.length > 0"
                                        class="fa fa-trash remove_icon pl-10" title="Delete"
                                        name="btnRemoveSelectedDVehicles" id="btnRemoveSelectedVehiclesID"
                                        aria-hidden="true" (click)="atleastOneTrailerSelected()"></i>
                                </a>
                            </span>
                            <span>
                                <a title="Add a Trailer" *ngIf="!dao.HideTrailerButton">
                                    <i class="{{isShowActinBtn('finalSave') ? '' : 'disAddUnit'}} fa fa-plus-circle add_icon"
                                        name="AddTrailer" id="AddTrailerID" (click)="addVehicle('trailer', $event);"
                                        aria-hidden="true"></i>
                                </a>
                            </span>
                            <div class="clearfix"></div>
                            <div class="menulen1">
                                <div *ngFor="let item of dao?.vehicleModel?.trailerList; let i= index;"
                                    [class.active-item]="i == dao?.vehicleModel?.trailerIndex && dao.vehicleModel.vehicleItem.vehicleType.group.indexOf('TRA') > -1">
                                    <h6 title="{{item?.vehicleYear?.answer}} {{item?.vehicleMake?.answer}} {{item?.vehicleModel?.answer}}"
                                        [class.highlight-invalid-item]="(vehicleForm?.errors?.invalidTrailer && vehicleForm?.errors?.invalidTrailerList?.includes(i))
                                                                 || (vehicleForm?.errors?.duplicateTrailer && vehicleForm?.errors?.duplicateTrailerList?.includes(i))">
                                        <label class="txtellipsis m-0" style="width: 70%;">
                                            <a *ngIf="checkVehicleBlank(item)" name="EditTrailer_{{i}}"
                                                id="EditTrailerID_{{i}}" (click)="clickedItem('trailer', item, i);">
                                                {{item?.vehicleYear?.answer}} {{item?.vehicleMake?.answer}}
                                                {{item?.vehicleModel?.answer}}</a>
                                            <a *ngIf="!checkVehicleBlank(item)" name="EditTrailBlank_{{i}}"
                                                id="EditTraiIDBlank_{{i}}" (click)="clickedItem('trailer', item, i);">
                                                New Trailer {{i + 1}} </a>
                                        </label>
                                        <span>
                                            <a class="float-right">
                                                <i class="fa fa-trash remove_icon" title="Delete"
                                                    (click)="remove(item,i,'trailer')" aria-hidden="true"></i>
                                            </a>
                                        </span>
                                        <p-checkbox class="delete-checkbox" name="deleteTrailerCheckBox"
                                            id="deleteTrailerCheckBoxID" type="checkbox" binary="true"
                                            [(ngModel)]="item.isChecked" #deleteTrailerCheckBox="ngModel"
                                            (onChange)="onTrailerCheckBoxChanged($event)">
                                        </p-checkbox>
                                    </h6>
                                </div>
                            </div>
                        </form>
                        <a class="float-right viewmorecls" *ngIf="dao?.vehicleModel?.trailerList?.length > 10"
                            title="{{ViewMsg}}" (click)="viewClick1('Trailers')">{{ViewMsg1}}</a>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 pl-0">
                    <span class="text-lg-left float-lg-left unitaddcls">
                        <button name="editAllUnits" id="editAllUnitsID" class="btnsuccess" title="editAllUnits"
                            data-toggle="modal" (click)="editAllUnits()">
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            Edit all Units
                        </button>
                    </span>
                    <span class="text-lg-right float-lg-right">
                        <button type="button" class="btnCancelSub" title="Cancel my Submission" name="btnVCCancelSub"
                            id="btnVCCancelSubID" (click)="cancelMySubmissionPopUp()">
                            <i class="fa fa-times" aria-hidden="true"></i>
                            Cancel my Submission
                        </button>
                    </span>
                    <span class="text-lg-left float-lg-left" style="padding-left: 15px;">
                        <button type="button" name="btnSampleVehicleDownload" id="btnSampleVehicleDownloadID"
                            class="innerSubButton" title="Download Sample Vehicle Template" data-toggle="modal"
                            (click)="downloadSampleVehicleTemplate('SampleVehicleTemplate', 'BSB_VehicleUploadTemplate')">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            Download Sample Vehicle Template
                        </button>
                    </span>
                    <div class="clearfix"></div>

                    <div class="vehicle_details1 mt-4" *ngIf="dao?.vehicleModel?.vehiclePage == 1">
                        <div class="card">
                            <form #firstForm="ngForm" style="width:100%" name="firstForm">
                                <div class="card-header">
                                    <h4 *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleYear?.answer" class="mb-1">
                                        <b>{{dao?.vehicleModel?.vehicleItem?.vehicleYear?.answer}}
                                            {{dao?.vehicleModel?.vehicleItem?.vehicleMake?.answer}}
                                            {{dao?.vehicleModel?.vehicleItem?.vehicleModel?.answer}}
                                            {{dao?.vehicleModel?.vehicleItem?.vehicleVin?.answer}}</b>
                                    </h4>
                                    <h4 *ngIf="!dao?.vehicleModel?.vehicleItem?.vehicleYear?.answer" class="mb-1"><b>New
                                            {{dao?.isTrailer ? 'Trailer' : 'Unit'}} :</b>
                                    </h4>
                                </div>
                                <div class="card-body">
                                    <div class="row mb-1"
                                        *ngIf="!dao?.vehicleModel?.vehicleItem?.vehicleYear?.IsHidden">
                                        <div class="col-lg-6">
                                            <label name="lblVCYear" id="lblVCYearID">Year:</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <input
                                                [class.validate_error_cls]="(firstForm.submitted || dao?.isSubmitForm) && Year.invalid"
                                                class="form-control"
                                                [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleYear.answer"
                                                type="text" maxlength="4" (keypress)="numbersOnly($event)" minlength="4"
                                                placeholder="{{dao?.vehicleModel?.vehicleDefault?.vehicleYear?.questionHelpText ? dao?.vehicleModel?.vehicleDefault?.vehicleYear?.questionHelpText : 'YYYY'}}"
                                                name="txtVCYear" id="txtVCYearID" #Year="ngModel"
                                                [required]="dao?.vehicleModel?.vehicleDefault?.vehicleYear?.required">
                                        </div>
                                    </div>

                                    <div class="row mb-1"
                                        *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleMake?.IsHidden">
                                        <div class="col-lg-6">
                                            <label name="lblVCMake" id="lblVCMakeID">Make:</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <input
                                                [class.validate_error_cls]="(firstForm.submitted || dao?.isSubmitForm) && Make.invalid"
                                                class="form-control"
                                                [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleMake.answer"
                                                type="text"
                                                placeholder="{{dao?.vehicleModel?.vehicleDefault?.vehicleMake?.questionHelpText ? dao?.vehicleModel?.vehicleDefault?.vehicleMake?.questionHelpText : 'MAKE'}}"
                                                name="txtVCMake" id="txtVCMakeID" #Make="ngModel"
                                                [alphaNumericCharactersOnly]
                                                [required]="dao?.vehicleModel?.vehicleDefault?.vehicleMake?.required">
                                            <span class="validationred"
                                                *ngIf="(firstForm.submitted || !Make.pristine) && Make.errors?.alphanumericCharactersOnly">
                                                Special characters are not allowed
                                            </span>
                                        </div>
                                    </div>

                                    <div class="row mb-1"
                                        *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleModel?.IsHidden">
                                        <div class="col-lg-6">
                                            <label name="lblVCModel" id="lblVCModelID">Model:</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <input
                                                [class.validate_error_cls]="(firstForm.submitted || dao?.isSubmitForm) && Model.invalid"
                                                class="form-control"
                                                [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleModel.answer"
                                                type="text"
                                                placeholder="{{dao?.vehicleModel?.vehicleDefault?.vehicleModel?.questionHelpText ? dao?.vehicleModel?.vehicleDefault?.vehicleModel?.questionHelpText : 'MODEL'}}"
                                                name="txtVCModel" id="txtVCModelID" #Model="ngModel"
                                                [alphaNumericCharactersOnly]
                                                [required]="dao?.vehicleModel?.vehicleDefault?.vehicleModel?.required && !dao?.isTrailer"
                                                maxlength="25">
                                                <span class="validationred"
                                                    *ngIf="(firstForm.submitted || !Model.pristine) && Model.errors?.alphanumericCharactersOnly">
                                                        Special characters are not allowed
                                                </span>
                                                <span class="validationred"
                                                    *ngIf="(firstForm.submitted || !Model.pristine) && Model.errors?.maxlength">
                                                        Vehicle Model cannot be greater than 25 characters
                                                </span>
                                        </div>
                                    </div>

                                    <div class="row mb-1"
                                        *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleVin?.IsHidden">
                                        <div class="col-lg-6">
                                            <label name="lblVCVIN" id="lblVCVINID">VIN #:</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <input
                                                [class.validate_error_cls]="(firstForm.submitted || dao?.isSubmitForm) && VIN?.invalid"
                                                class="form-control"
                                                [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleVin.answer" type="text"
                                                placeholder="{{dao?.vehicleModel?.vehicleDefault?.vehicleVin?.questionHelpText ? dao?.vehicleModel?.vehicleDefault?.vehicleVin?.questionHelpText : 'Vehicle ID Number'}}"
                                                name="txtVCVIN" id="txtVCVINID" #VIN="ngModel"
                                                [required]="!dao?.isTrailer ? dao?.vehicleModel?.vehicleDefault?.vehicleVin?.required : false"
                                                [maxlength]="!dao?.isTrailer ? dao?.vehicleModel?.vehicleItem?.vehicleYear?.answer < 1981 ? 13 : 17 : 17"
                                                [minlength]="!dao?.isTrailer ? dao?.vehicleModel?.vehicleItem?.vehicleYear?.answer < 1981 ? 5 : 17 : 0">
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-12 text-center">
                                            <span *ngIf="dao?.vehicleModel?.vehiclePage != 1"
                                                class="uplod text-lg-left mr-3 float-left">
                                                <button type="submit" class="innerSubButton" title="Back"
                                                    name="btnVCback" id="btnVCBackID"
                                                    (click)="Continue('1',firstForm,'Back')">
                                                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                                    Back
                                                </button>
                                            </span>
                                            <span class="text-lg-left float-lg-left">
                                                <p-fileUpload mode="basic" #fileUpload class="basic-upload"
                                                    name="demo[]" [auto]="true"
                                                    (onSelect)="uploadVehicleList($event,fileUpload)"
                                                    chooseLabel="Upload Vehicle List" chooseIcon="pi-upload"
                                                    accept=".xlsx, .xls, .csv" maxFileSize="15728640"></p-fileUpload>
                                            </span>
                                            <span class="text-lg-right float-lg-right">
                                                <button type="submit" class="innerSubButton" name="btnVCNext"
                                                    id="btnVCNextID" title="Next" data-toggle="modal"
                                                    (click)="Continue('1',firstForm,'Next')">
                                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                                    Next
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- 2nd Vehicle List -->


                    <div class="vehicle_details1" *ngIf="dao?.vehicleModel?.vehiclePage == 2" style="margin-top: 18px;">
                        <div class="card">
                            <form #secondForm="ngForm" style="width:100%" name="secondForm">
                                <div class="card-header">
                                    <h4 *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleYear?.answer" class="mb-1">
                                        <b>{{dao?.vehicleModel?.vehicleItem?.vehicleYear?.answer}}
                                            {{dao?.vehicleModel?.vehicleItem?.vehicleMake?.answer}}
                                            {{dao?.vehicleModel?.vehicleItem?.vehicleModel?.answer}}
                                            {{dao?.vehicleModel?.vehicleItem?.vehicleVin?.answer}}</b>
                                    </h4>
                                </div>
                                <div class="card-body">
                                    <div class="row mb-1">
                                        <div class="col-lg-6">
                                            <label name="lblVCGaragingAddress" id="lblVCGaragingAddressID">Garaging
                                                Address:</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div>
                                                <input
                                                    class="form-control {{(secondForm.submitted || dao?.isSubmitForm) && (vehicleGaragingAddress?.invalid || (dao.vehicleModel.vehicleItem.vehicleGaragingAddress.answer == null)) ? 'validate_error_cls' : ''}}"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleGaragingAddress.answer"
                                                    type="text" name="txtaccountStreetModal" id="txtVCGaragingAddressID"
                                                    #vehicleGaragingAddress="ngModel"
                                                    [required]="true"
                                                    placeholder="Enter Garaging Address" disabled>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-1"
                                        *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleIsCorrectGaragingAddress?.IsHidden">
                                        <div class="col-lg-6">
                                            <label name="lblvehicleIsCorrectGaragingAddress"
                                                [ngClass]="(secondForm.submitted || dao?.isSubmitForm) && (dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer == null || dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer == '') ? 'validationred' : ''"
                                                id="lblvehicleIsCorrectGaragingAddressID">{{dao?.vehicleModel?.vehicleDefault?.vehicleIsCorrectGaragingAddress?.questionLabel}}</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="p-field-radiobutton">
                                                <p-radioButton name="vehicleIsCorrectGaragingAddressYes"
                                                    id="radvehicleIsCorrectGaragingAddressYesID" value="Yes"
                                                    class="radio_button"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer">
                                                </p-radioButton>
                                                <label class="radio_button_lbl"
                                                    name="lblvehicleIsCorrectGaragingAddressYes"
                                                    id="lblvehicleIsCorrectGaragingAddressYesID">Yes</label>
                                                <p-radioButton name="vehicleIsCorrectGaragingAddressNo"
                                                    id="radvehicleIsCorrectGaragingAddressNoID" class="radio_button"
                                                    value="No"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer">
                                                </p-radioButton>
                                                <label class="radio_button_lbl"
                                                    name="lblvehicleIsCorrectGaragingAddressNo"
                                                    id="lblvehicleIsCorrectGaragingAddressNoID">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-1"
                                        *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleIsCorrectGaragingAddress?.answer == 'No'">
                                        <div class="col-lg-6">
                                            <label name="lblvehiclegaragingaddress"
                                                id="lblvehiclegaragingaddressID">Enter
                                                this vehicle's garaging address</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div
                                                *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleIsCorrectGaragingAddress?.IsHidden">
                                                <input
                                                    class="{{(secondForm.submitted || dao?.isSubmitForm) && dao?.vehicleModel?.vehicleDefault?.vehicleGarageStreetAddress?.required && (vehicleGarageStreetAddress?.invalid || dao.vehicleModel.vehicleItem.vehicleGarageStreetAddress.answer == null) ? 'validate_error_cls' : ''}} form-control"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleGarageStreetAddress.answer"
                                                    type="text" name="txtvehicleGarageStreetAddress"
                                                    id="txtvehiclegaragingaddressID"
                                                    #vehicleGarageStreetAddress="ngModel"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.vehicleGarageStreetAddress?.required"
                                                    placeholder="Enter Garaging Address" maxlength="40"
                                                    (keypress)="ValidateAddress($event,dao.vehicleModel.vehicleItem.vehicleGarageStreetAddress.answer,'txtvehicleGarageStreetAddress')">
                                            </div>
                                            <div class="row ml-0 mt-1" style="width: 100%;">
                                                <div *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleGarageCity?.IsHidden"
                                                    class="col-lg-4 pr-1 col-md-4 col-4 p-0">
                                                    <input
                                                        class="{{(secondForm.submitted || dao?.isSubmitForm) && dao?.vehicleModel?.vehicleDefault?.vehicleGarageCity?.required && (!vehicleGarageCity?.valid || dao.vehicleModel.vehicleItem.vehicleGarageCity.answer == null) ? 'validate_error_cls' : ''}} form-control"
                                                        [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleGarageCity.answer"
                                                        type="text" name="txtvehicleGarageCity" id="txtvehicleCityID"
                                                        #vehicleGarageCity="ngModel"
                                                        [required]="dao?.vehicleModel?.vehicleDefault?.vehicleGarageCity?.required"
                                                        placeholder="Enter City">
                                                </div>
                                                <div *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleGarageState?.IsHidden"
                                                    class="col-lg-4 pr-1 col-md-4 col-4 p-0">
                                                    <input
                                                        class="{{(secondForm.submitted || dao?.isSubmitForm) && dao?.vehicleModel?.vehicleDefault?.vehicleGarageState?.required && (!vehicleGarageState?.valid || dao.vehicleModel.vehicleItem.vehicleGarageState.answer == null) ? 'validate_error_cls' : ''}} form-control"
                                                        [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleGarageState.answer"
                                                        type="text" name="txtvehicleGarageState"
                                                        id="txtvehicleGarageStateID" #vehicleGarageState="ngModel"
                                                        readonly
                                                        [required]="dao?.vehicleModel?.vehicleDefault?.vehicleGarageState?.required"
                                                        placeholder="Enter State">
                                                </div>
                                                <div *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleGaragePostalCode?.IsHidden"
                                                    class="col-lg-4 pr-1 col-md-4 col-4 p-0">
                                                    <div
                                                        class="{{(secondForm.submitted || dao?.isSubmitForm) && dao?.vehicleModel?.vehicleDefault?.vehicleGaragePostalCode?.required && !vehicleGaragePostalCode?.valid ? 'validate_error_cls' : ''}}">
                                                        <p-inputMask
                                                            [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleGaragePostalCode.answer"
                                                            mask="99999"
                                                            [required]="dao?.vehicleModel?.vehicleDefault?.vehicleGaragePostalCode?.required"
                                                            name="vehicleGaragePostalCode" id="vehicleGaragePostalCode"
                                                            #vehicleGaragePostalCode="ngModel" placeholder="Zip">
                                                        </p-inputMask>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-1"
                                        *ngIf="!dao?.isTrailer && !dao?.vehicleModel?.vehicleDefault?.vehicleSize?.IsHidden">
                                        <div class="col-lg-6">
                                            <label name="lblVehicleWeightClass" id="lblVehicleWeightClassID">Vehicle
                                                Weight
                                                Class</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div
                                                class="{{(secondForm.submitted || dao?.isSubmitForm) && dao?.vehicleModel?.vehicleDefault?.vehicleSize?.required && !vehicleSize?.valid ? 'validateDrodown' : ''}}">
                                                <p-dropdown placeholder="Select Vehicle Weight Class"
                                                    [options]="dao?.vehicleModel?.vehicleDefault?.vehicleSize?.questionOptions"
                                                    name="vehicleSize" id="ddvehicleSizeID"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleSize.answer"
                                                    (onChange)="vehicleSizeChange()" tooltipStyleClass="vehiclesizecls"
                                                    #vehicleSize="ngModel"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.vehicleSize?.required"
                                                    tooltip="{{dao.vehicleModel.vehicleItem?.vehicleSize?.helpText}}">
                                                    <ng-template let-target pTemplate="item">
                                                        <div tooltipStyleClass="vehiclesizecls"
                                                            pTooltip="{{target?.helpText}}">{{target.label}}</div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-1"
                                        *ngIf="dao?.isTrailer && !dao?.vehicleModel?.vehicleDefault?.trailerSize?.IsHidden">
                                        <div class="col-lg-6">
                                            <label name="lbltrailersize" id="lbltrailersizeID">Vehicle Weight
                                                Class</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div
                                                class="{{(secondForm.submitted || dao?.isSubmitForm) && dao?.vehicleModel?.vehicleDefault?.trailerSize?.required && !trailerSize?.valid ? 'validateDrodown' : ''}}">
                                                <p-dropdown placeholder="Select Vehicle Weight Class"
                                                    [options]="dao?.vehicleModel?.vehicleDefault?.trailerSize?.questionOptions"
                                                    name="trailerSize" id="ddtrailerSizeID"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.trailerSize.answer"
                                                    (onChange)="vehicleSizeChange()" tooltipStyleClass="vehiclesizecls"
                                                    #trailerSize="ngModel"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.trailerSize?.required"
                                                    tooltip="{{dao?.vehicleModel?.vehicleItem?.trailerSize?.helpText}}">
                                                    <ng-template let-target pTemplate="item">
                                                        <div tooltipStyleClass="vehiclesizecls"
                                                            pTooltip="{{target?.helpText}}">{{target.label}}</div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-1"
                                        *ngIf="!dao?.isTrailer && !dao?.vehicleModel?.vehicleDefault?.vehicleBodyType?.IsHidden">
                                        <div class="col-lg-6">
                                            <label name="lblVehicleBodyType" id="lblVehicleBodyTypeID">Vehicle Body
                                                Type</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div
                                                class="{{(secondForm.submitted || dao?.isSubmitForm) && dao?.vehicleModel?.vehicleDefault?.vehicleBodyType?.required && !vehicleBodyType?.valid ? 'validateDrodown' : ''}}">
                                                <p-dropdown placeholder="Select Vehicle Body Type"
                                                    [options]="dao?.vehicleModel?.vehicleDefault?.vehicleBodyType?.questionOptions"
                                                    name="vehicleBodyType" id="txtVehicleBodyTypeID"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleBodyType.answer"
                                                    #vehicleBodyType="ngModel"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.vehicleBodyType?.required">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-1"
                                        *ngIf="dao?.isTrailer && !dao?.vehicleModel?.vehicleDefault?.trailerBodyType?.IsHidden">
                                        <div class="col-lg-6">
                                            <label name="lblTrailerBodyType" id="lblTrailerBodyTypeID">Vehicle Body
                                                Type</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div
                                                class="{{(secondForm.submitted || dao?.isSubmitForm) && dao?.vehicleModel?.vehicleDefault?.trailerBodyType?.required && !TrailerBodyType?.valid ? 'validateDrodown' : ''}}">
                                                <p-dropdown placeholder="Select Vehicle Body Type"
                                                    [options]="dao?.vehicleModel?.vehicleDefault?.trailerBodyType?.questionOptions"
                                                    name="TrailerBodyType" id="txtTrailerBodyTypeID"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.trailerBodyType.answer"
                                                    #TrailerBodyType="ngModel"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.trailerBodyType?.required">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mb-1"
                                        *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleSecondaryClass?.IsHidden">
                                        <div class="col-lg-6">
                                            <label name="lblSecondaryClassification"
                                                id="lblSecondaryClassificationID">Secondary Classification</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div
                                                class="{{(secondForm.submitted || dao?.isSubmitForm) && dao?.vehicleModel?.vehicleDefault?.vehicleSecondaryClass?.required && !vehicleSecondaryClass?.valid ? 'validateDrodown' : ''}}">
                                                <p-dropdown placeholder="Select Secondary Class"
                                                    [options]="dao?.vehicleModel?.vehicleDefault?.vehicleSecondaryClass?.questionOptions"
                                                    name="vehicleSecondaryClass" id="txtSecondaryClassificationID"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.vehicleSecondaryClass?.required"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleSecondaryClass.answer"
                                                    #vehicleSecondaryClass="ngModel">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="zonesGroup" ngModelGroup="zonesGroup" #radiusZones="ngModelGroup"
                                        [totalHundredControls]="'percentage'" [uniqueValuedControls]="'vehicleToZone_'">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <h5 class="heading_vehicle">Enter the total percentage for each Radius
                                                </h5>
                                            </div>
                                            <div class="col-lg-6">
                                                <span *ngIf="secondForm.submitted && radiusZones.errors?.totalHundred"
                                                    class="validationred">
                                                    Total radius percentage must equal 100%
                                                </span>
                                            </div>
                                        </div>


                                        <div class="row"
                                            *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleTotalMilesUpto50?.IsHidden">
                                            <div class="col-lg-6">
                                                <label name="lblvehicleTotalMilesUpto50"
                                                    id="lblvehicleTotalMilesUpto50ID">{{dao?.vehicleModel?.vehicleDefault?.vehicleTotalMilesUpto50?.questionLabel}}</label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input class="form-control  float-left"
                                                    [class.validate_error_cls]="(secondForm.submitted || dao?.isSubmitForm) && (fiftyRadiusPercentage?.invalid || radiusZones.errors?.totalHundred)"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto50.answer"
                                                    type="text" name="fiftyRadiusPercentage"
                                                    id="txtvehicleTotalMilesUpto50ID" #fiftyRadiusPercentage="ngModel"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.vehicleTotalMilesUpto50?.required"
                                                    (keypress)="numbersOnly($event)" DecimalPlaces="2"
                                                    placeholder="Enter Percentage">
                                            </div>
                                        </div>

                                        <div class="row"
                                            *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleTotalMilesUpto200?.IsHidden">
                                            <div class="col-lg-6">
                                                <label name="lblvehicleTotalMilesUpto200"
                                                    id="lblvehicleTotalMilesUpto200ID">{{dao?.vehicleModel?.vehicleDefault?.vehicleTotalMilesUpto200?.questionLabel}}</label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input class="form-control"
                                                    [class.validate_error_cls]="(secondForm.submitted || dao?.isSubmitForm ) && (fifty200RadiusPercentage?.invalid || radiusZones.errors?.totalHundred)"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleTotalMilesUpto200.answer"
                                                    type="text" name="fifty200RadiusPercentage"
                                                    id="txtvehicleTotalMilesUpto200ID"
                                                    #fifty200RadiusPercentage="ngModel" (keypress)="numbersOnly($event)"
                                                    placeholder="Enter Percentage">
                                            </div>
                                        </div>

                                        <div class="row"
                                            *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleTravelOver200?.hidden">
                                            <div class="col-lg-6">
                                                <label name="lblvehicleTravelOver200"
                                                    [ngClass]="(secondForm.submitted || dao?.isSubmitForm) && (dao.vehicleModel.vehicleItem.vehicleTravelOver200.answer == null || dao.vehicleModel.vehicleItem.vehicleTravelOver200.answer == '') ? 'validationred' : ''"
                                                    id="lblvehicleTravelOver200ID">{{dao?.vehicleModel?.vehicleDefault?.vehicleTravelOver200?.questionLabel}}</label>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="p-field-radiobutton">
                                                    <p-radioButton id="radIs200OverID"
                                                        (onClick)="dao.vehicleModel.vehicleItem.zoneList = [];AddZone();"
                                                        name="Is200Over" value="Yes" class="radio_button"
                                                        [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleTravelOver200.answer">
                                                    </p-radioButton>
                                                    <label class="radio_button_lbl" name="radIs200OverYes"
                                                        id="radIs200OverYesID">Yes</label>
                                                    <p-radioButton
                                                        (onClick)="dao.vehicleModel.vehicleItem.zoneList = [];AddZone();"
                                                        name="Is200Over" id="Is200OverNoID" class="radio_button"
                                                        value="No"
                                                        [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleTravelOver200.answer">
                                                    </p-radioButton>
                                                    <label class="radio_button_lbl" name="radIs200OverNo"
                                                        id="radIs200OverNoID">No</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleToZone?.enabled && dao?.vehicleModel?.vehicleItem?.vehicleTravelOver200?.answer == 'Yes'"
                                            class="row">
                                            <div class="col-lg-6">
                                                <h5 class="heading_vehicle">Select all zones that this unit travels in:
                                                </h5>
                                            </div>
                                            <div class="col-lg-6">
                                                <div *ngIf="secondForm.submitted && radiusZones.errors?.uniqueValue">
                                                    <span class="validationred">
                                                        This vehicle cannot have the same zone, please select a
                                                        different zone.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleToZone?.enabled && dao?.vehicleModel?.vehicleItem?.vehicleTravelOver200?.answer == 'Yes'">
                                            <div class="row mb-1"
                                                *ngFor="let zone of dao?.vehicleModel?.vehicleItem.zoneList; let i= index; trackBy: trackByFn;">
                                                <div class="col-lg-6">
                                                    <div
                                                        [class.validateDrodown]="(secondForm.submitted || dao?.isSubmitForm)
                                            && dao?.vehicleModel?.vehicleDefault?.vehicleToZone?.required
                                            && (vehicleToZone?.invalid || (radiusZones.errors?.uniqueValue && radiusZones.errors?.invalidUniqueValues?.indexOf(zone.zone)>-1))">
                                                        <p-dropdown placeholder="Select Applicable Zone"
                                                            [required]="dao?.vehicleModel?.vehicleDefault?.vehicleToZone?.required"
                                                            [options]="dao?.vehicleModel?.vehicleDefault?.vehicleToZone?.questionOptions"
                                                            tooltip="{{zone?.helpText}}" name="vehicleToZone_{{i}}"
                                                            id="ddvehicleToZoneID_{{i}}" [(ngModel)]="zone.zone"
                                                            #vehicleToZone="ngModel" (onChange)="zoneChange(i)">
                                                            <ng-template let-target pTemplate="item">
                                                                <div pTooltip="{{target?.helpText}}">{{target.label}}
                                                                </div>
                                                            </ng-template>
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div>
                                                        <input class="form-control float-left"
                                                            [class.validate_error_cls]="(secondForm.submitted || dao?.isSubmitForm) && (zonePercentage?.invalid  || radiusZones.errors?.totalHundred)"
                                                            [(ngModel)]="zone.percentage" type="text"
                                                            (keypress)="numbersOnly($event)" name="zonePercentage_{{i}}"
                                                            id="zonePercentageID_{{i}}" style="width: 85%;"
                                                            [required]="dao?.vehicleModel?.vehicleDefault?.vehicleApplicableZonePercent?.required"
                                                            #zonePercentage="ngModel" placeholder="Enter Percentage">
                                                        <span *ngIf="i != 0">
                                                            <i class="fa fa-trash delete_icon ml-3" title="delete"
                                                                (click)="remove(null,i,'zone')"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleToZone?.enabled && dao.vehicleModel.vehicleItem.vehicleTravelOver200.answer == 'Yes' && dao?.vehicleModel?.vehicleItem.zoneList?.length < 10"
                                            class="text-right mr-5 mb-3 pr-2">
                                            <a style="color: #115740;" title="Add Additional Zone" name="btnAddZone"
                                                id="btnAddZoneID" (click)="AddZone()">
                                                Add Additional
                                                Zone
                                            </a>
                                        </div>
                                    
                                        <div class="row" *ngIf="!!dao?.vehicleModel?.vehicleDefault?.farthestApplicableZonePercent?.key && !dao?.vehicleModel?.vehicleDefault?.farthestApplicableZonePercent?.hidden">
                                            <div class="col-lg-6">
                                                <label name="lblFarthestApplicableZonePercent" id="lblFarthestApplicableZonePercentID">
                                                    {{dao?.vehicleModel?.vehicleDefault?.farthestApplicableZonePercent?.questionLabel}}
                                                </label>
                                            </div>
                                            <div class="col-lg-6">
                                                <input class="form-control float-left"
                                                    [class.validate_error_cls]="(secondForm.submitted || dao?.isSubmitForm) && radiusZones.errors?.totalHundred || (dao.vehicleModel.vehicleItem.farthestToZone.answer !== '' && farthestApplicableZonePercentage.invalid)"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.farthestApplicableZonePercent.answer"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.farthestApplicableZonePercent?.required && dao.vehicleModel.vehicleItem.farthestToZone.answer !== ''"
                                                    type="text" name="farthestApplicableZonePercentage"
                                                    id="txtFarthestApplicableZonePercentID" #farthestApplicableZonePercentage="ngModel"
                                                    (keypress)="numbersOnly($event)" DecimalPlaces="2"
                                                    placeholder="Enter Percentage">
                                            </div>
                                        </div>    
                                    
                                    <div class="row mb-1" *ngIf="!!dao?.vehicleModel?.vehicleDefault?.farthestToZone?.key &&  !dao?.vehicleModel?.vehicleDefault?.farthestToZone?.hidden">
                                        <div class="col-lg-6">
                                            <label name="lblFarthestToZone" id="lblFarthestToZoneID">
                                                {{dao?.vehicleModel?.vehicleDefault?.farthestToZone?.questionLabel}}
                                            </label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div [class.validateDrodown]="(secondForm.submitted || dao?.isSubmitForm) 
                                                && (farthestToZone?.invalid || (radiusZones.errors?.uniqueValue && radiusZones.errors?.invalidUniqueValues?.indexOf(zone.zone)>-1))">
                                                <p-dropdown placeholder="Select Applicable Zone"
                                                    [options]="dao?.vehicleModel?.vehicleDefault?.farthestToZone?.questionOptions" name="farthestToZone"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.farthestToZone?.required && dao.vehicleModel.vehicleItem.farthestApplicableZonePercent.answer !== '' && dao.vehicleModel.vehicleItem.farthestApplicableZonePercent.answer !== '0'"
                                                    id="ddFarthestToZoneID" [(ngModel)]="dao.vehicleModel.vehicleItem.farthestToZone.answer"
                                                    #farthestToZone="ngModel">
                                                    <ng-template let-target pTemplate="item">
                                                        <div pTooltip="{{target?.helpText}}">{{target.label}}</div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div class="row mt-3">
                                        <div class="col-lg-12 text-center">
                                            <span *ngIf="dao?.vehicleModel?.vehiclePage != 1"
                                                class="uplod text-lg-left mr-3 float-left">
                                                <button type="submit" class="innerSubButton" name="btnvehicleBack"
                                                    id="btnvehicleBackID" title="Back"
                                                    (click)="Continue('2',secondForm,'Back')">
                                                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                                    Back
                                                </button>
                                            </span>
                                            <span class="text-lg-right float-lg-right">
                                                <button type="submit" class="innerSubButton" name="btnvehicleNext"
                                                    id="btnvehicleNextID" title="Next" data-toggle="modal"
                                                    (click)="Continue('2',secondForm,'Next')">
                                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                                    Next
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <!-- 3rd Vehicle List -->

                    <div class="mt-4" *ngIf="dao?.vehicleModel?.vehiclePage == 3">
                        <div class="card">
                            <form #thirdForm="ngForm" style="width:100%" name="thirdForm">
                                <div class="card-header">
                                    <h4 style="font-size: 15px;"
                                        *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleYear?.answer" class="mb-1">
                                        <b>{{dao?.vehicleModel?.vehicleItem?.vehicleYear?.answer}}
                                            {{dao?.vehicleModel?.vehicleItem?.vehicleMake?.answer}}
                                            {{dao?.vehicleModel?.vehicleItem?.vehicleModel?.answer}}
                                            {{dao?.vehicleModel?.vehicleItem?.vehicleVin?.answer}}</b>
                                    </h4>
                                </div>
                                <div class="card-body">
                                    <div class="row"
                                        *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleCompOTCCoverage?.IsHidden">
                                        <div class="col-lg-6"
                                            *ngIf="dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled">
                                            <label name="lblvehicleCompOTCCoverage"
                                                [ngClass]="(thirdForm.submitted || dao?.isSubmitForm) && (dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.answer == null || dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.answer == '') ? 'validationred' : ''"
                                                id="lblvehicleCompOTCCoverageID">Do you need comp coverage to protect
                                                this vehicle in an accident?</label>
                                        </div>
                                        <div class="col-lg-6"
                                            *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled">
                                            <label name="lblvehicleCompOTCCoverage"
                                                [ngClass]="(thirdForm.submitted || dao?.isSubmitForm) && (dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.answer == null || dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.answer == '') ? 'validationred' : ''"
                                                id="lblvehicleCompOTCCoverageID">Do you need comp/OTC and collision
                                                coverage to protect this vehicle in an accident?</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="p-field-radiobutton">
                                                <p-radioButton name="IsCompOTC" id="IsCompOTCYesID" value="Yes"
                                                    class="radio_button" (click)="vehicleCompOTCCoverageAnswerChanged()"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.answer"
                                                    required>
                                                </p-radioButton>
                                                <label class="radio_button_lbl" name="lblIsCompOTCYes"
                                                    id="lblIsCompOTCYesID">Yes</label>
                                                <p-radioButton name="IsCompOTC" id="radIsCompOTCNoID"
                                                    class="radio_button" value="No"
                                                    (click)="vehicleCompOTCCoverageAnswerChanged()"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.answer">
                                                </p-radioButton>
                                                <label class="radio_button_lbl" name="lblIsCompOTCNo"
                                                    id="lblIsCompOTCNoID">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleCompOTCCoverage?.answer == 'Yes' && !dao?.vehicleModel?.vehicleDefault?.vehicleComp?.IsHidden"
                                        class="row mt-2 mb-2 ">
                                        <div class="col-lg-12">
                                            <h5 *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled"
                                                class="heading_vehicle">
                                                Select Unit Physical Damage Deductibles
                                            </h5>
                                        </div>

                                        <div class="col-lg-6"
                                            *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleCompOTCCoverage?.answer == 'Yes' && dao?.vehicleModel?.vehicleItem?.vinEtched?.enabled">
                                            <label name="lblvinEtched" id="vinEtched"
                                                [class.validationred]="((thirdForm.submitted || dao?.isSubmitForm) && !dao?.vehicleModel?.vehicleItem?.vinEtched?.answer)">
                                                {{dao?.vehicleModel?.vehicleDefault?.vinEtched?.questionLabel}}
                                            </label>
                                        </div>
                                        <div class="col-lg-6"
                                            *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleCompOTCCoverage?.answer == 'Yes' && dao?.vehicleModel?.vehicleItem?.vinEtched?.enabled">
                                            <div class="p-field-radiobutton">
                                                <p-radioButton name="IsvinEtched" value="Yes" label="Yes"
                                                    class="radio_button"
                                                    [required]="dao.vehicleModel.vehicleItem.vinEtched?.required"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vinEtched.answer">
                                                </p-radioButton>
                                                <p-radioButton name="IsvinEtched" class="radio_button" value="No"
                                                    label="No"
                                                    [required]="dao.vehicleModel.vehicleItem.vinEtched?.required"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vinEtched.answer">
                                                </p-radioButton>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <label>Comprehensive Deductible</label>
                                            <span class="question_icon">
                                                <i class="fa fa-question-circle"
                                                    pTooltip="Select a deductible for this optional coverage that helps protect your unit in case of theft, vandalism, glass damage, or damage from hitting an animal,fire and other weather related coverage. Read your policy for a complete listing."
                                                    aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div class="col-lg-6">
                                            <div
                                                class="{{thirdForm.submitted && dao?.vehicleModel?.vehicleDefault?.vehicleComp?.required && !vehicleComp?.valid ? 'validateDrodown' : ''}}">
                                                <p-dropdown placeholder="Select Comp/OTC"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.vehicleComp?.required"
                                                    [options]="dao?.vehicleModel?.vehicleDefault?.vehicleComp?.questionOptions"
                                                    name="vehicleComp" id="ddvehicleCompID"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleComp.answer"
                                                    #vehicleComp="ngModel">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row"
                                        *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.IsHidden">
                                        <div class="col-lg-6">
                                            <label name="lblvehicleCollCoverage"
                                                [ngClass]="(thirdForm.submitted || dao?.isSubmitForm) && (dao.vehicleModel.vehicleItem.vehicleCollCoverage.answer == null || dao.vehicleModel.vehicleItem.vehicleCollCoverage.answer == '') ? 'validationred' : ''"
                                                id="lblvehicleCollCoverageID">{{dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.questionLabel}}</label>
                                        </div>
                                        <div class="col-lg-6"
                                            *ngIf="dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled">
                                            <div class="p-field-radiobutton">
                                                <p-radioButton name="IsColl" id="IsCollID" value="Yes"
                                                    class="radio_button"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleCollCoverage.answer"
                                                    required (onClick)="CollisionOnChange('vehicleCollCoverage')">
                                                </p-radioButton>
                                                <label class="radio_button_lbl" name="lblIsCollYes"
                                                    id="lblIsCollYesID">Yes</label>
                                                <p-radioButton name="IsColl" id="radIsCollNoID" class="radio_button"
                                                    value="No"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleCollCoverage.answer"
                                                    (onClick)="CollisionOnChange('vehicleCollCoverage')">
                                                </p-radioButton>
                                                <label class="radio_button_lbl" name="lblIsCollNo"
                                                    id="lblIsCollNoID">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Which collision coverage do you want?  Standard Collision  or  Broadened Collision -->
                                    <div class="row"
                                        *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleCollCoverage?.answer == 'Yes' && dao.vehicleModel.vehicleItem?.broadenedCollision?.enabled">
                                        <div class="col-lg-6">
                                            <label name="lblQ_WhichCollCoverage"
                                                [ngClass]="(thirdForm.submitted || dao?.isSubmitForm) && (dao.vehicleModel.vehicleItem.Q_WhichCollCoverage.answer == null || dao.vehicleModel.vehicleItem.Q_WhichCollCoverage.answer == '') ? 'validationred' : ''"
                                                id="Q_WhichCollCoverageID">{{dao?.vehicleModel?.vehicleDefault?.Q_WhichCollCoverage?.questionLabel}}</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="p-field-radiobutton">
                                                <p-radioButton name="IsQ_WhichCollCoverage" id="IsQ_WhichCollCoverageID"
                                                    value="Yes" class="radio_button"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.Q_WhichCollCoverage.answer"
                                                    required (onClick)="CollisionOnChange('Q_WhichCollCoverage')">
                                                </p-radioButton>
                                                <label class="radio_button_lbl" name="lblQ_WhichCollCoverageYes"
                                                    id="lblQ_WhichCollCoverageYesID">Standard Collision</label>
                                                <p-radioButton name="IsQ_WhichCollCoverage"
                                                    id="radQ_WhichCollCoverageID" class="radio_button" value="No"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.Q_WhichCollCoverage.answer"
                                                    (onClick)="CollisionOnChange('Q_WhichCollCoverage')">
                                                </p-radioButton>
                                                <label class="radio_button_lbl" name="lblQ_WhichCollCoverage"
                                                    id="lblQ_WhichCollCoverageID">Broadened Collision</label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Collision -->
                                    <div *ngIf="(!dao?.vehicleModel?.vehicleItem?.vehicleCollCoverage?.enabled && dao?.vehicleModel?.vehicleItem?.vehicleCompOTCCoverage?.answer == 'Yes') || (dao?.vehicleModel?.vehicleItem?.vehicleCollCoverage?.answer == 'Yes'
                                    && (!dao.vehicleModel.vehicleItem?.broadenedCollision?.enabled || dao.vehicleModel.vehicleItem.Q_WhichCollCoverage.answer == 'Yes'))"
                                        class="row mt-2 mb-2">
                                        <div class="col-lg-6">
                                            <label>Collision Deductible</label>
                                            <span class="question_icon">
                                                <i class="fa fa-question-circle"
                                                    pTooltip="This optional coverage, provided by CA 22 22 10 13, states that the Collision Deductible will not apply or be waived for a “loss” to a designated covered auto provided that the operator of the covered auto was not more than 50% at fault or the cause of the accident. In addition, if there was no physical contact with another motor vehicle involved in an accident and the insured offers reasonable evidence that the operator of the covered auto was not more than 50% the cause of the accident or the covered auto was damaged while parked in such a way as not to cause unreasonable risk of damage.  Depending on the type and age of the vehicle and the deductible requested, this additional coverage may result in a premium change for Collision Coverage for the designated vehicle.  The designated vehicle must be licensed or principally garaged in Michigan and listed on the endorsement."
                                                    aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div class="col-lg-6">
                                            <div
                                                class="{{thirdForm.submitted && dao?.vehicleModel?.vehicleDefault?.vehicleColl?.required && !vehicleColl?.valid ? 'validateDrodown' : ''}}">
                                                <p-dropdown placeholder="Select Collision"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.vehicleColl?.required"
                                                    [options]="dao?.vehicleModel?.vehicleDefault?.vehicleColl?.questionOptions"
                                                    name="vehicleColl" id="ddvehicleCollID"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleColl.answer"
                                                    #vehicleColl="ngModel">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Broadened Collision -->
                                    <div *ngIf="dao?.vehicleModel?.vehicleItem?.broadenedCollision?.enabled && dao?.vehicleModel?.vehicleItem?.Q_WhichCollCoverage?.answer == 'No'"
                                        class="row mt-2 mb-2">
                                        <div class="col-lg-6">
                                            <label>{{dao?.vehicleModel?.vehicleItem?.broadenedCollision?.questionLabel}}</label>
                                            <span class="question_icon">
                                                <i class="fa fa-question-circle"
                                                    pTooltip="This optional coverage, provided by CA 22 23 10 13, states that the Collision can be provided if the operator of the designated covered auto was not more than 50% at fault or the cause of the accident.  The coverage is available only on those vehicles for which the insured does not elect to purchase Collision Coverage. Depending on the type and age of the vehicle and the deductible requested, this additional coverage may result in a premium change for Collision Coverage for the designated vehicle.  The designated vehicle must be licensed or principally garaged in Michigan and listed on the endorsement."
                                                    aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div class="col-lg-6">
                                            <div
                                                class="{{thirdForm.submitted && dao?.vehicleModel?.vehicleDefault?.broadenedCollision?.required && !broadenedCollision?.valid ? 'validateDrodown' : ''}}">
                                                <p-dropdown placeholder="Select Broadened Collision"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.broadenedCollision?.required"
                                                    [options]="dao?.vehicleModel?.vehicleDefault?.broadenedCollision?.questionOptions"
                                                    name="broadenedCollision" id="ddbroadenedCollisionID"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.broadenedCollision.answer"
                                                    #broadenedCollision="ngModel">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Limited Collision -->
                                    <div class="row"
                                        *ngIf="dao?.vehicleModel?.vehicleItem?.Q_limitedCollision?.enabled && dao?.vehicleModel?.vehicleItem?.vehicleCollCoverage?.answer == 'No'">
                                        <div class="col-lg-6">
                                            <label name="lblQ_limitedCollision"
                                                [ngClass]="(thirdForm.submitted || dao?.isSubmitForm) && (dao.vehicleModel.vehicleItem.Q_limitedCollision.answer == null || dao.vehicleModel.vehicleItem.Q_limitedCollision.answer == '') ? 'validationred' : ''"
                                                id="lblQ_limitedCollisionID">{{dao?.vehicleModel?.vehicleDefault?.Q_limitedCollision?.questionLabel}}</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="p-field-radiobutton">
                                                <p-radioButton name="IsLC" id="IsLCID" value="Yes" class="radio_button"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.Q_limitedCollision.answer"
                                                    required>
                                                </p-radioButton>
                                                <label class="radio_button_lbl" name="lblIsLCYes"
                                                    id="lblIsLCYesID">Yes</label>
                                                <p-radioButton name="IsLC" id="radIsLCNoID" class="radio_button"
                                                    value="No"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.Q_limitedCollision.answer"
                                                    (onClick)="CollisionOnChange('Q_limitedCollision')">
                                                </p-radioButton>
                                                <label class="radio_button_lbl" name="lblIsLCNo"
                                                    id="lblIsLCNoID">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="dao?.vehicleModel?.vehicleItem?.limitedCollision?.enabled && dao?.vehicleModel?.vehicleItem?.Q_limitedCollision?.answer == 'Yes'"
                                        class="row mt-2 mb-2">
                                        <div class="col-lg-6">
                                            <label>{{dao?.vehicleModel?.vehicleItem?.limitedCollision?.questionLabel}}</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div
                                                class="{{thirdForm.submitted && dao?.vehicleModel?.vehicleDefault?.limitedCollision?.required && !limitedCollision?.valid ? 'validateDrodown' : ''}}">
                                                <p-dropdown placeholder="Select Limited Collision"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.limitedCollision?.required"
                                                    [options]="dao?.vehicleModel?.vehicleDefault?.limitedCollision?.questionOptions"
                                                    name="limitedCollision" id="ddlimitedCollisionID"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.limitedCollision.answer"
                                                    #limitedCollision="ngModel">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2 mb-2"
                                        *ngIf="dao.isTrailer && dao.vehicleModel.vehicleItem.vehicleCollCoverage?.answer == 'Yes' && dao.vehicleModel.vehicleItem.floorTrailer?.enabled">
                                        <div class="col-lg-6">
                                            <label name="lblfloorTrailer" id="floorTrailer"
                                                [class.validationred]="((thirdForm.submitted || dao?.isSubmitForm) && thirdForm.controls?.IsfloorTrailer?.invalid)">
                                                {{dao?.vehicleModel?.vehicleDefault?.floorTrailer?.questionLabel}}
                                            </label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div>
                                                <p-radioButton name="IsfloorTrailer" value="Yes" label="Yes"
                                                    class="radio_button"
                                                    [required]="dao.vehicleModel.vehicleItem.floorTrailer?.required"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.floorTrailer.answer">
                                                </p-radioButton>
                                                <p-radioButton name="IsfloorTrailer" class="radio_button" value="No"
                                                    label="No"
                                                    [required]="dao.vehicleModel.vehicleItem.floorTrailer?.required"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.floorTrailer.answer">
                                                </p-radioButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row"
                                        *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleStatedAmount?.IsHidden">
                                        <div class="col-lg-6">
                                            <label>
                                                {{dao.vehicleModel.vehicleDefault.vehicleStatedAmount.questionLabel}}
                                                <span class="question_icon">
                                                    <i class="fa fa-question-circle"
                                                        pTooltip="Enter the unit's total value including customizations. ( Ex. If you sold the unit today, the stated amount is the amount you would ask the purchaser to pay.)"
                                                        aria-hidden="true"></i>
                                                </span>
                                            </label>
                                        </div>
                                        <div class="col-lg-6">
                                            <p-inputNumber
                                                [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleStatedAmount.answer"
                                                [value]="" placeholder="Enter Stated Amount $0"
                                                [class.invalidInputNumber]="(thirdForm?.submitted || dao?.isSubmitForm  || vehicleStatedAmount.touched) && vehicleStatedAmount.invalid"
                                                class="vehicle_amt" #vehicleStatedAmount="ngModel" inputId="locale-us"
                                                locale="en-US" name="vehicleStatedAmount" (onBlur)="StatedAmountinput()"
                                                id="vehicleStatedAmount"
                                                [numbersOnly]
                                                [minAmt]="dao.vehicleModel.vehicleItem.vehicleCompOTCCoverage.answer=='Yes'?1:0">
                                            </p-inputNumber>
                                            <span class="validationred"
                                                *ngIf="(thirdForm.submitted || !vehicleStatedAmount.pristine) && vehicleStatedAmount.errors?.minAmt">
                                                Stated Amount cannot be $0.
                                            </span>
                                        </div>
                                    </div>

                                    <!-- Custom parts - Start -->

                                    <div class="row"
                                        *ngIf="dao.vehicleModel.vehicleDefault.vehicleCustomPartsParent.enabled">
                                        <div class="col-lg-6">
                                            <label
                                                [ngClass]="(thirdForm.submitted || dao?.isSubmitForm) && (dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer == null || dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer == '') ? 'validationred' : ''">
                                                {{dao.vehicleModel.vehicleDefault.vehicleCustomPartsParent.questionLabel}}
                                            </label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="p-field-radiobutton">
                                                <p-radioButton name="VehicleCustomPartParent"
                                                    id="VehicleCustomPartParentYesID" value="Yes" class="radio_button"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer"
                                                    required>
                                                </p-radioButton>
                                                <label class="radio_button_lbl" name="lblvehicleCustomPartsParentYes"
                                                    id="lblvehicleCustomPartsParentYesId">Yes</label>
                                                <p-radioButton name="VehicleCustomPartParent"
                                                    id="VehicleCustomPartParentNoID" class="radio_button" value="No"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer"
                                                    (onClick)="ClearCustomDetails('No')">
                                                </p-radioButton>
                                                <label class="radio_button_lbl" name="lblvehicleCustomPartsParentNo"
                                                    id="lblvehicleCustomPartsParentNoId">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row"
                                        *ngIf="dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer == 'Yes'">
                                        <div class="col-lg-6">
                                            <label>{{dao.vehicleModel.vehicleDefault.vehicleCustomAmount.questionLabel}}</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <p-inputNumber
                                                [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleCustomAmount.answer"
                                                [value]="" placeholder="Enter Custom Amount $0"
                                                class="vehicle_amt"
                                                [class.invalidInputNumber]="(thirdForm?.submitted || dao?.isSubmitForm) && dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer == 'Yes' && vehicleCustomAmount.invalid"
                                                #vehicleCustomAmount="ngModel" inputId="locale-us" locale="en-US"
                                                [required]="dao.vehicleModel.vehicleDefault.vehicleCustomAmount.required"
                                                name="vehicleCustomAmount" id="vehicleCustomAmountID"
                                                [minAmt]="dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer=='Yes'?1:0"
                                                [numbersOnly]>
                                            </p-inputNumber>
                                        </div>
                                    </div>

                                    <div class="row"
                                        *ngIf="dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer == 'Yes'">
                                        <div class="col-lg-6">
                                            <label>{{dao.vehicleModel.vehicleDefault.vehicleCustomDesc.questionLabel}}</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <textarea
                                                [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleCustomDesc.answer"
                                                style="width: 80%;" placeholder="Enter Description"
                                                #vehicleCustomDesc="ngModel" inputId="locale-us" locale="en-US"
                                                name="vehicleCustomDesc"
                                                class="{{(thirdForm.submitted || dao?.isSubmitForm) && (dao.vehicleModel.vehicleItem.vehicleCustomDesc.answer == '' || dao.vehicleModel.vehicleItem.vehicleCustomDesc.answer == null ) ? 'validate_error_cls form-control' : 'form-control'}}"
                                                id="vehicleCustomDescID"
                                                [required]="dao.vehicleModel.vehicleDefault.vehicleCustomDesc.required">
                                        </textarea>
                                        </div>
                                    </div>

                                    <!-- Custom parts - End -->

                                    <div class="row"
                                        *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleAddInsuredOrLossPayee?.IsHidden">
                                        <div class="col-lg-6">
                                            <label
                                                [ngClass]="(thirdForm.submitted || dao?.isSubmitForm) && (dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer == null || dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer == '') ? 'validationred' : ''">
                                                Does
                                                this unit have an <b>additional insured or loss payee?</b>
                                            </label>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="p-field-radiobutton">
                                                <p-radioButton name="IsLossPayee"
                                                    (onClick)="dao.vehicleModel.vehicleItem.interestList = [];AddInterest('')"
                                                    id="radIsLossPayeeYesID" value="Yes" class="radio_button"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer"
                                                    required>
                                                </p-radioButton>
                                                <label class="radio_button_lbl" name="lblIsLossPayeeYes"
                                                    id="lblIsLossPayeeYesID">Yes</label>
                                                <p-radioButton name="IsLossPayee"
                                                    (onClick)="dao.vehicleModel.vehicleItem.interestList = [];AddInterest('')"
                                                    id="radIsLossPayeeNo" class="radio_button" value="No"
                                                    [(ngModel)]="dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer">
                                                </p-radioButton>
                                                <label class="radio_button_lbl" name="lblIsLossPayeeNo"
                                                    id="lblIsLossPayeeNoID">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleAddInsuredOrLossPayee?.answer == 'Yes' && !dao?.vehicleModel?.vehicleDefault?.vehicleAddInsuredOrLossPayee?.IsHidden">
                                        <div class="row {{i != 0 ? 'pt-3 interbor' : ''}}"
                                            *ngFor="let interest of dao?.vehicleModel?.vehicleItem?.interestList; let i= index;">
                                            <div class="col-lg-6">
                                                <label>Select what type of interest:</label>
                                                <span class="question_icon">
                                                    <i class="fa fa-question-circle"
                                                        pTooltip="Select the type of interest that will be entitled to receive insurance benefits and notices along with the insured. Additional insureds receive liability protection while loss payees receive only property damage coverage."
                                                        aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            <div class="col-lg-6 {{i != 0 ? 'interesttype' : ''}}">
                                                <div
                                                    class="{{thirdForm.submitted && dao?.vehicleModel?.vehicleDefault?.addInsuredInterestType?.required && !addInsuredInterestType?.valid ? 'validateDrodown' : ''}}">
                                                    <p-dropdown placeholder="Select Type"
                                                        [options]="dao?.vehicleModel?.vehicleDefault?.addInsuredInterestType?.questionOptions"
                                                        [required]="dao?.vehicleModel?.vehicleDefault?.addInsuredInterestType?.required"
                                                        name="addInsuredInterestType_{{i}}"
                                                        id="addInsuredInterestTypeID_{{i}}" [(ngModel)]="interest.Type"
                                                        #addInsuredInterestType="ngModel">
                                                    </p-dropdown>
                                                    <span *ngIf="i != 0">
                                                        <i class="fa fa-trash delete_icon ml-3" title="delete"
                                                            name="remove{{i}}" id="removeID{{i}}"
                                                            (click)="remove(null,i,'interest')"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 mt-2">
                                                <label>Enter the name:</label>
                                            </div>
                                            <div class="col-lg-6 mt-2">
                                                <input
                                                    class="{{thirdForm.submitted && dao?.vehicleModel?.vehicleDefault?.addInsuredName?.required && (!adddInsuredName?.valid || interest.Name == null) ? 'validate_error_cls' : ''}} form-control"
                                                    [(ngModel)]="interest.Name" type="text"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.addInsuredName?.required"
                                                    name="adddInsuredName_{{i}}" id="adddInsuredNameID_{{i}}"
                                                    #adddInsuredName="ngModel" placeholder="Enter Name">
                                            </div>
                                            <div class="col-lg-6 mt-2">
                                                <label>Address:</label>
                                            </div>
                                            <div class="col-lg-6 mt-2">
                                                <input
                                                    class="{{thirdForm.submitted && (!addInsuredStreetAddress?.valid || interest.streetAddress == null) ? 'validate_error_cls' : ''}} form-control"
                                                    [(ngModel)]="interest.streetAddress" type="text" required
                                                    name="addInsuredStreetAddress_{{i}}"
                                                    id="addInsuredStreetAddressID_{{i}}"
                                                    #addInsuredStreetAddress="ngModel" placeholder="Street Address"
                                                    maxlength="40">
                                                <div class="row ml-0 mt-2" style="margin-left: -14px !important;">
                                                    <div class="col-lg-4 col-md-4 col-4"
                                                        style="padding: 0px 13px !important">
                                                        <input
                                                            class="{{thirdForm.submitted && (!addInsuredCity?.valid || interest.city == null) ? 'validate_error_cls' : ''}} form-control"
                                                            [(ngModel)]="interest.city" type="text" required
                                                            name="addInsuredCity_{{i}}" id="addInsuredCityID_{{i}}"
                                                            #addInsuredCity="ngModel" placeholder="City">
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-4"
                                                        style="padding: 0px 13px !important">
                                                        <div
                                                            class="{{thirdForm.submitted && !addInsuredState?.valid ? 'validateDrodown' : ''}}">
                                                            <p-dropdown placeholder="Select State"
                                                                [options]="stateSettings.StateQuestionOptions" required
                                                                name="addInsuredState_{{i}}"
                                                                id="addInsuredStateID_{{i}}"
                                                                [(ngModel)]="interest.state" #addInsuredState="ngModel">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-4 col-4"
                                                        style="padding: 0px 13px !important">
                                                        <div
                                                            class="{{thirdForm.submitted && !addInsuredPostalCode?.valid ? 'validate_error_cls' : ''}}">
                                                            <p-inputMask [(ngModel)]="interest.zip" mask="99999"
                                                                required name="addInsuredPostalCode_{{i}}"
                                                                id="addInsuredPostalCodeID_{{i}}"
                                                                #addInsuredPostalCode="ngModel" placeholder="Zip">
                                                            </p-inputMask>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input
                                                    class="{{thirdForm.submitted && dao?.vehicleModel?.vehicleDefault?.addInsuredLoanNumber?.required && !addInsuredLoanNumber?.valid ? 'validate_error_cls' : ''}} mt-2 form-control"
                                                    [(ngModel)]="interest.loanNo" type="text"
                                                    [required]="dao?.vehicleModel?.vehicleDefault?.addInsuredLoanNumber?.required"
                                                    name="addInsuredLoanNumber_{{i}}" id="addInsuredLoanNumberID_{{i}}"
                                                    #addInsuredLoanNumber="ngModel"
                                                    placeholder="Loan Number (optional)">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="text-right mb-3">
                                                <a style="color: #115740;" title="Add Another Interest"
                                                    (click)="AddInterest('')">Add Another Interest</a>
                                            </div>
                                        </div>

                                    </div>
                                    <ng-template [ngTemplateOutlet]="BulkEditCheckbox"></ng-template>
                                    <div class="row mt-3">
                                        <div class="col-lg-12 text-center">
                                            <span *ngIf="dao?.vehicleModel?.vehiclePage != 1"
                                                class="uplod text-lg-left mr-3 float-left">
                                                <button type="submit" class="innerSubButton" name="btnBack"
                                                    id="btnBackID" title="Back"
                                                    (click)="Continue('3',thirdForm,'Back')">
                                                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                                    Back
                                                </button>
                                            </span>
                                            <span class="text-lg-right float-lg-right"
                                                *ngIf="isShowActinBtn('finalNext')">
                                                <button type="submit" class="innerSubButton" name="btnNext"
                                                    id="btnNextID" title="Next" data-toggle="modal"
                                                    (click)="Continue('3',thirdForm,'Next')">
                                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                                    Next
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="mt-3 unitaddcls">
                        <button type="submit" name="btnAddTrailer" id="btnAddTrailerID"
                            *ngIf="isShowActinBtn('trailer') && !dao.HideTrailerButton"
                            class="btnsuccess float-right ml-2" title="Add a Trailer" (click)="addVehicle('trailer');">
                            <i class="fa fa-truck" aria-hidden="true"></i>
                            Add a Trailer
                        </button>
                        <button type="submit" name="btnAddAnotherUnit" id="btnAddAnotherUnitID"
                            *ngIf="isShowActinBtn('vehicle') && !dao.HideVechicleButton" class="btnsuccess float-right"
                            title="Add a Vehicle" (click)="addVehicle('vehicle');">
                            <i class="fa fa-automobile" aria-hidden="true"></i>
                            Add a Vehicle
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-lg-12 text-center">
                    <div class="col-lg-12 p-0 mt-3">
                        <span class="uplod text-lg-left mr-3 float-left">
                            <button type="button" name="btnVACBack" id="btnVACBackID" class="btnerror" title="Back"
                                (click)="onBack('losshistory')">
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                Back
                            </button>
                        </span>
                        <span class="text-lg-right float-lg-right"
                            *ngIf="isShowActinBtn('finalSave') && !dao.IsShowVehicleDraftBtn">
                            <button type="submit" name="btnVACSaveContinue" id="btnsaveContinueID" class="btnsuccess"
                                (click)="SaveUnit($event)" title="Continue" data-toggle="modal">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                Save & Continue
                            </button>
                        </span>
                        <span class="text-lg-right float-lg-right" *ngIf="dao.IsShowVehicleDraftBtn">
                            <button type="button" name="btnVACSaveAs" id="btnsaveAsID" class="btnsuccess"
                                (click)="VehicleSaveDraft()" title="Save As" data-toggle="modal">
                                <i class="fa fa-save" aria-hidden="true"></i>
                                Save As
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-toast key="myKey1" position="top-center"></p-toast>
    <p-toast position="center" key="c" (onClose)="onReject('c')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" class="warnBtnSuccess float-right" name="btnVCancelSubYes"
                            id="btnVCancelSubYesID" title="Yes" (click)="CancelmySubmission()">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" class="warnBtnerror" title="No" name="btnVCancelSubNo"
                            id="btnVCancelSubNoID" (click)="onReject('c')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <p-toast position="center" key="remove" (onClose)="onReject('remove')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                </div>
                <div class="p-grid p-fluid">
                    <div class="p-col-6">
                        <button type="button" class="warnBtnSuccess float-right" title="Yes" name="btnonRejectYesNo"
                            id="btnonRejectYesID" (click)="confirmRemove()">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Yes
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button" class="warnBtnerror" title="No" name="btnVonRejectNo"
                            id="btnVonRejectNoID" (click)="onReject('remove')">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            No
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <!-- Edit All Units -->
    <p-dialog header="" [(visible)]="editRadiusForAllUnits" [style]="{width: '100vw'}" [baseZIndex]="4000"
        class="vehicle_details1 dialog_bulk_edit" [modal]="true" (onHide)="closeEditAllUnits()">
        <p-tabView [(activeIndex)]="EditUnitactiveIndex" [scrollable]="true">
            <!--Radius-->
            <p-tabPanel>
                <ng-template pTemplate="header">Radius</ng-template>
                <form #radiusForm="ngForm" [totalHundredControls]="'percentage'"
                    [uniqueValuedControls]="'EditvehicleToZone_'" style="width:100%">
                    <div class="row">
                        <div class="col-lg-6">
                            <h5 class="editUnitHeader">Update the total percentage for each Radius</h5>
                        </div>
                        <div class="col-lg-6">
                            <span *ngIf="radiusForm.submitted && radiusForm.errors?.totalHundred" class="validationred">
                                Total radius percentage must equal 100%.
                            </span>
                        </div>
                    </div>

                    <div class="row" *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleTotalMilesUpto50?.IsHidden">
                        <div class="col-lg-6">
                            <label name="lblvehicleTotalMilesUpto50"
                                id="lblvehicleTotalMilesUpto50ID">{{dao?.vehicleModel?.vehicleDefault?.vehicleTotalMilesUpto50?.questionLabel}}</label>
                        </div>
                        <div class="col-lg-6">
                            <input class="form-control float-left"
                                [class.validate_error_cls]="radiusForm.submitted && (EditfiftyRadiusPercentage.invalid || radiusForm.errors?.totalHundred)"
                                [(ngModel)]="dao.editAllUnits.vehicleTotalMilesUpto50.answer" type="text"
                                name="EditfiftyRadiusPercentage" id="EdittxtvehicleTotalMilesUpto50ID"
                                #EditfiftyRadiusPercentage="ngModel"
                                [required]="dao?.vehicleModel?.vehicleDefault?.vehicleTotalMilesUpto50?.required"
                                (keypress)="numbersOnly($event)" DecimalPlaces="2" placeholder="Enter Percentage">
                        </div>
                    </div>

                    <div class="row" *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleTotalMilesUpto200?.IsHidden">
                        <div class="col-lg-6">
                            <label name="lblvehicleTotalMilesUpto200"
                                id="lblvehicleTotalMilesUpto200ID">{{dao?.vehicleModel?.vehicleDefault?.vehicleTotalMilesUpto200?.questionLabel}}</label>
                        </div>
                        <div class="col-lg-6">
                            <input [(ngModel)]="dao.editAllUnits.vehicleTotalMilesUpto200.answer" class="form-control"
                                [class.validate_error_cls]="radiusForm.submitted && (fifty200RadiusPercentage.invalid || radiusForm.errors?.totalHundred)"
                                type="text" name="Editfifty200RadiusPercentage" id="EdittxtvehicleTotalMilesUpto200ID"
                                #fifty200RadiusPercentage="ngModel" (keypress)="numbersOnly($event)"
                                placeholder="Enter Percentage">
                        </div>
                    </div>

                    <div class="row" *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleTravelOver200?.hidden">
                        <div lcass="col-lg-6" [class.validationred]="radiusForm.submitted && EditIs200Over.invalid">
                            <label name="lblvehicleTravelOver200"
                                id="lblvehicleTravelOver200ID">{{dao?.vehicleModel?.vehicleDefault?.vehicleTravelOver200?.questionLabel}}</label>
                        </div>

                        <div class="col-lg-6">
                            <div class="p-field-radiobutton">
                                <p-radioButton id="EditradIs200OverID"
                                    (onClick)="dao.editAllUnits.zoneList = [];EditAddZone()" name="EditIs200Over"
                                    value="Yes" class="radio_button" #EditIs200Over="ngModel"
                                    [(ngModel)]="dao.editAllUnits.vehicleTravelOver200.answer " required>
                                </p-radioButton>
                                <label class="radio_button_lbl" name="EditradIs200OverYes"
                                    id="EditradIs200OverYesID">Yes</label>
                                <p-radioButton (onClick)="dao.editAllUnits.zoneList = [];EditAddZone()"
                                    name="EditIs200Over" id="EditIs200OverNoID" class="radio_button" value="No"
                                    #EditIs200Over="ngModel" [(ngModel)]="dao.editAllUnits.vehicleTravelOver200.answer">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="EditradIs200OverNo"
                                    id="EditradIs200OverNoID">No</label>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="dao?.vehicleModel?.vehicleDefault?.vehicleToZone?.enabled && dao?.editAllUnits?.vehicleTravelOver200?.answer == 'Yes'" class="row">
                        <div class="col-lg-6">
                            <h5 class="editUnitHeader">Select all zones that this unit travels in:</h5>
                        </div>
                        <div class="col-lg-6">
                            <span *ngIf="radiusForm.submitted && radiusForm.errors?.uniqueValue" class="validationred">
                                Vehicles cannot have the same zone, please select a different zone.
                            </span>
                        </div>
                    </div>

                    <div *ngIf="dao?.vehicleModel?.vehicleDefault?.vehicleToZone?.enabled && dao?.editAllUnits?.vehicleTravelOver200?.answer == 'Yes'">
                        <div class="row mb-1"
                            *ngFor="let Editzone of dao?.editAllUnits.zoneList; let i= index; trackBy: trackByFn;">
                            <div class="col-lg-6">
                                <div
                                    [class.validateDrodown]="radiusForm.submitted && dao.vehicleModel?.vehicleDefault?.vehicleToZone?.required && (EditvehicleToZone.invalid || (radiusForm.errors?.uniqueValue && radiusForm.errors?.invalidUniqueValues?.indexOf(Editzone.zone)>-1))">
                                    <p-dropdown placeholder="Select Applicable Zone"
                                        [required]="dao?.vehicleModel?.vehicleDefault?.vehicleToZone?.required"
                                        [options]="dao?.vehicleModel?.vehicleDefault?.vehicleToZone?.questionOptions"
                                        tooltip="{{Editzone?.helpText}}" name="EditvehicleToZone_{{i}}"
                                        id="EditddvehicleToZoneID_{{i}}" [(ngModel)]="Editzone.zone"
                                        #EditvehicleToZone="ngModel" appendTo="body">
                                        <ng-template let-target pTemplate="item">
                                            <div pTooltip="{{target?.helpText}}">{{target.label}}</div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <p class="text-red d-none"><small>Radius for all mileage must equal 100%</small></p>
                            </div>

                            <div class="col-lg-6">
                                <div>
                                    <input [(ngModel)]="Editzone.percentage" type="text" class="form-control float-left"
                                        [class.validate_error_cls]="radiusForm.submitted && (EditzonePercentage.invalid || radiusForm.errors?.totalHundred)"
                                        (keypress)="numbersOnly($event)" name="EditzonePercentage_{{i}}"
                                        id="EditzonePercentageID_{{i}}" style="width: 85%;"
                                        [required]="dao?.vehicleModel?.vehicleDefault?.vehicleApplicableZonePercent?.required"
                                        #EditzonePercentage="ngModel" placeholder="Enter Percentage">
                                    <span *ngIf="i != 0"><i class="fa fa-trash delete_icon ml-3" title="delete"
                                            (click)="remove(null,i,'Editzone')"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="dao?.vehicleModel?.vehicleDefault?.vehicleToZone?.enabled && dao.editAllUnits.vehicleTravelOver200.answer == 'Yes' && dao?.vehicleModel?.vehicleItem.zoneList?.length < 10"
                        class="text-right mr-5 mb-3 pr-2">
                        <a style="color: #115740;" title="Add Additional Zone" name="btnAddZone" id="btnAddZoneID"
                            (click)="EditAddZone()">Add Additional Zone</a>
                    </div>
                
                    <div class="row" *ngIf="!!dao?.vehicleModel?.vehicleDefault?.farthestApplicableZonePercent?.key && !dao?.vehicleModel?.vehicleDefault?.farthestApplicableZonePercent?.hidden">
                        <div class="col-lg-6">
                            <label name="lblFarthestApplicableZonePercent" id="lblFarthestApplicableZonePercentID">
                                {{dao?.vehicleModel?.vehicleDefault?.farthestApplicableZonePercent?.questionLabel}}
                            </label>
                        </div>
                        <div class="col-lg-6">
                            <input class="form-control  float-left"
                                [class.validate_error_cls]="radiusForm.submitted && (radiusForm.errors?.totalHundred || (dao?.editAllUnits?.farthestToZone?.answer !== '' && farthestApplicableZonePercentage.invalid))"
                                [(ngModel)]="dao.editAllUnits.farthestApplicableZonePercent.answer"
                                [required]="dao?.editAllUnits?.farthestApplicableZonePercent?.required && dao?.editAllUnits?.farthestToZone?.answer !== ''"
                                type="text" name="farthestApplicableZonePercentage"
                                id="txtFarthestApplicableZonePercentID" #farthestApplicableZonePercentage="ngModel"
                                [required]="dao?.vehicleModel?.vehicleDefault?.farthestApplicableZonePercent?.required"
                                (keypress)="numbersOnly($event)" DecimalPlaces="2"
                                placeholder="Enter Percentage">
                        </div>
                    </div>
                
                    <div class="row mb-1" *ngIf="!!dao?.vehicleModel?.vehicleDefault?.farthestToZone?.key && !dao?.vehicleModel?.vehicleDefault?.farthestToZone?.hidden">
                        <div class="col-lg-6">
                            <label name="lblVehicleToZone" id="lblFarthestToZoneID">
                                {{dao?.vehicleModel?.vehicleDefault?.farthestToZone?.questionLabel}}
                            </label>
                        </div>
                        <div class="col-lg-6">
                            <div [class.validateDrodown]="radiusForm.submitted && dao.vehicleModel?.vehicleDefault?.farthestToZone?.required && farthestToZone.invalid">
                                <p-dropdown placeholder="Select Applicable Zone"
                                    [options]="dao?.vehicleModel?.vehicleDefault?.farthestToZone?.questionOptions" name="vehicleToZoneID"
                                    [required]="dao?.vehicleModel?.vehicleDefault?.farthestToZone?.required && dao?.editAllUnits?.farthestApplicableZonePercent?.answer !== '' && dao?.editAllUnits?.farthestApplicableZonePercent?.answer !== '0'"
                                    id="ddFarthestToZoneID" [(ngModel)]="dao.editAllUnits.farthestToZone.answer"
                                    #farthestToZone="ngModel" appendTo="body">
                                    <ng-template let-target pTemplate="item">
                                        <div pTooltip="{{target?.helpText}}">{{target.label}}</div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-0 m-0">
                        <div class="col-md-12 p-0">
                            <button type="submit" class="btnsuccess float-right" name="btnSaveAllUnits"
                                id="btnSaveAllUnitsID" title="Save" (click)="updateAllUnits()">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                Update All Units
                            </button>
                        </div>
                    </div>
                </form>
            </p-tabPanel>
            <!--Comprehensive   -->
            <p-tabPanel>
                <ng-template pTemplate="header">{{dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled ? 'Comprehensive Deductibles' : 'Comp/Coll Deductibles'}}</ng-template>
                <form #compDeductibleForm="ngForm">
                    <div class="row">
                        <div class="col-lg-12">
                            <h5 *ngIf="dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled"
                                class="editUnitHeader">Update Unit Comprehensive Deductibles</h5>
                            <h5 *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled"
                                class="editUnitHeader">Update Unit Physical Damage Deductibles</h5>
                        </div>
                    </div>
                    <div class="row mt-2 mb-2" *ngIf="dao?.vehicleModel?.vehicleDefault?.vinEtched?.enabled">
                        <div class="col-lg-6">
                            <label name="EditlblvinEtchedd" id="EditvinEtched"
                                [class.validationred]="compDeductibleForm.submitted && !dao.vehicleModel.vehicleItem.vinEtched.answer">
                                {{dao?.vehicleModel?.vehicleDefault?.vinEtched?.questionLabel}}
                            </label>
                        </div>
                        <div class="col-lg-6">
                            <div class="p-field-radiobutton">
                                <p-radioButton name="EditVinEtchedYes" value="Yes" label="Yes" class="radio_button"
                                    [required]="dao.vehicleModel.vehicleDefault.vinEtched?.required"
                                    [(ngModel)]="dao.editAllUnits.vinEtched.answer">
                                </p-radioButton>
                                <p-radioButton name="EditVinEtchedNo" class="radio_button" value="No" label="No"
                                    [required]="dao.editAllUnits.vinEtched?.required"
                                    [(ngModel)]="dao.editAllUnits.vinEtched.answer">
                                </p-radioButton>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 mb-2">
                        <div class="col-lg-6">
                            <label *ngIf="dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled">Comprehensive
                                Deductible</label>
                            <label *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled">Comp/OTC
                                Deductible</label>
                            <span class="question_icon">
                                <i class="fa fa-question-circle"
                                    pTooltip="Select a deductible for this optional coverage that helps protect your unit in case of theft, vandalism, glass damage, or damage from hitting an animal,fire and other weather related coverage. Read your policy for a complete listing."
                                    aria-hidden="true"></i>
                            </span>
                        </div>
                        <div class="col-lg-6">
                            <div style="z-index : 5000">
                                <p-dropdown placeholder="Select Comprehensive"
                                    [required]="dao?.vehicleModel?.vehicleDefault?.vehicleComp?.required"
                                    [options]="dao?.vehicleModel?.vehicleDefault?.vehicleComp?.questionOptions"
                                    name="EditvehicleComp" id="EditddvehicleCompID" appendTo="body"
                                    [(ngModel)]="dao.editAllUnits.vehicleComp.answer" #vehicleComp="ngModel">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 mb-2" *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled">
                        <div class="col-lg-6">
                            <label>Collision Deductible</label>
                            <span class="question_icon">
                                <i class="fa fa-question-circle"
                                    pTooltip="This optional coverage, provided by CA 22 22 10 13, states that the Collision Deductible will not apply or be waived for a “loss” to a designated covered auto provided that the operator of the covered auto was not more than 50% at fault or the cause of the accident. In addition, if there was no physical contact with another motor vehicle involved in an accident and the insured offers reasonable evidence that the operator of the covered auto was not more than 50% the cause of the accident or the covered auto was damaged while parked in such a way as not to cause unreasonable risk of damage.  Depending on the type and age of the vehicle and the deductible requested, this additional coverage may result in a premium change for Collision Coverage for the designated vehicle.  The designated vehicle must be licensed or principally garaged in Michigan and listed on the endorsement."
                                    aria-hidden="true"></i>
                            </span>
                        </div>
                        <div class="col-lg-6">
                            <div style="z-index : 5000">
                                <p-dropdown placeholder="Select Collision"
                                    [required]="dao?.vehicleModel?.vehicleDefault?.vehicleColl?.required"
                                    [options]="dao?.vehicleModel?.vehicleDefault?.vehicleColl?.questionOptions"
                                    name="editvehicleColl" id="ddvehicleCollID" appendTo="body"
                                    [(ngModel)]="dao.editAllUnits.vehicleColl.answer" #editvehicleColl="ngModel">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-0 m-0">
                        <div class="col-md-12 p-0">
                            <button type="button" class="btnsuccess float-right" name="btnSaveCompAllUnits"
                                id="btnSaveCompAllUnitsID" title="Save" (click)="updateAllUnits()">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                Update All Units
                            </button>
                        </div>
                    </div>
                </form>
            </p-tabPanel>

            <!-- Collision deductibles -->
            <p-tabPanel *ngIf="dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled">
                <ng-template pTemplate="header">Collision Deductibles</ng-template>
                <form #collisionDeductibleForm="ngForm" style="width:100%">
                    <div class="row">
                        <div class="col-lg-12">
                            <h5 class="editUnitHeader">Update Unit Collision Deductibles
                            </h5>
                        </div>
                    </div>
                    <div class="row mt-2 mb-2"
                        *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.IsHidden && dao?.vehicleModel?.vehicleDefault?.Q_WhichCollCoverage?.enabled">
                        <div class="col-lg-6">
                            <label name="editlblvehicleCollCoverage"
                                [ngClass]="(collisionDeductibleForm.submitted && (dao.editAllUnits.vehicleCollCoverage.answer == null || dao.editAllUnits.vehicleCollCoverage.answer == '')) ? 'validationred' : ''"
                                id="editlblvehicleCollCoverageID">{{dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.questionLabel}}</label>
                        </div>
                        <div class="col-lg-6">
                            <div class="p-field-radiobutton">
                                <p-radioButton name="editIsCollYes" id="editIsCollYesID" value="Yes"
                                    class="radio_button" [(ngModel)]="dao.editAllUnits.vehicleCollCoverage.answer"
                                    (onClick)="EditCollisionOnChange('vehicleCollCoverage')">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="editlblIsCollYes"
                                    id="editlblIsCollYesID">Yes</label>
                                <p-radioButton name="editIsCollNo" id="editIsCollNoID" value="No" class="radio_button"
                                    [(ngModel)]="dao.editAllUnits.vehicleCollCoverage.answer"
                                    (onClick)="EditCollisionOnChange('vehicleCollCoverage')">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="editlblIsCollNo" id="editlblIsCollNoID">No</label>
                            </div>
                        </div>
                    </div>
                    <!-- Which collision coverage do you want?  Standard Collision  or  Broadened Collision -->
                    <div class="row mt-2 mb-2"
                        *ngIf="dao?.editAllUnits?.vehicleCollCoverage?.answer == 'Yes' && dao.vehicleModel.vehicleItem?.broadenedCollision?.enabled">
                        <div class="col-lg-6">
                            <label name="lblQ_WhichCollCoverage"
                                [ngClass]="collisionDeductibleForm.submitted && (dao.editAllUnits.Q_WhichCollCoverage.answer == null || dao.editAllUnits.Q_WhichCollCoverage.answer == '') ? 'validationred' : ''"
                                id="Q_WhichCollCoverageID">{{dao?.vehicleModel?.vehicleDefault?.Q_WhichCollCoverage?.questionLabel}}</label>
                        </div>
                        <div class="col-lg-6">
                            <div class="p-field-radiobutton">
                                <p-radioButton name="editIsQ_WhichCollCoverage" id="editIsQ_WhichCollCoverageID"
                                    value="Yes" class="radio_button"
                                    [(ngModel)]="dao.editAllUnits.Q_WhichCollCoverage.answer" required
                                    (onClick)="EditCollisionOnChange('Q_WhichCollCoverage')">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="lblQ_WhichCollCoverageYes"
                                    id="lblQ_WhichCollCoverageYesID">Standard Collision</label>
                                <p-radioButton name="editIsBC" id="editQ_WhichCollCoverageID" class="radio_button"
                                    value="No" [(ngModel)]="dao.editAllUnits.Q_WhichCollCoverage.answer"
                                    (onClick)="EditCollisionOnChange('Q_WhichCollCoverage')">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="lblQ_WhichCollCoverage"
                                    id="lblQ_WhichCollCoverageID">Broadened Collision</label>
                            </div>
                        </div>
                    </div>

                    <!-- Collision -->
                    <div *ngIf="(dao?.editAllUnits?.vehicleCollCoverage?.answer == 'Yes' 
               && (!dao.vehicleModel.vehicleItem?.broadenedCollision?.enabled || dao.editAllUnits.Q_WhichCollCoverage.answer == 'Yes')) || 
               !dao?.vehicleModel?.vehicleDefault?.Q_WhichCollCoverage?.enabled" class="row mt-2 mb-2">
                        <div class="col-lg-6">
                            <label>Collision Deductible</label>
                            <span class="question_icon">
                                <i class="fa fa-question-circle"
                                    pTooltip="This optional coverage, provided by CA 22 22 10 13, states that the Collision Deductible will not apply or be waived for a “loss” to a designated covered auto provided that the operator of the covered auto was not more than 50% at fault or the cause of the accident. In addition, if there was no physical contact with another motor vehicle involved in an accident and the insured offers reasonable evidence that the operator of the covered auto was not more than 50% the cause of the accident or the covered auto was damaged while parked in such a way as not to cause unreasonable risk of damage.  Depending on the type and age of the vehicle and the deductible requested, this additional coverage may result in a premium change for Collision Coverage for the designated vehicle.  The designated vehicle must be licensed or principally garaged in Michigan and listed on the endorsement."
                                    aria-hidden="true"></i>
                            </span>
                        </div>
                        <div class="col-lg-6">
                            <div
                                class="{{collisionDeductibleForm.submitted && dao?.vehicleModel?.vehicleDefault?.vehicleColl?.required && !editvehicleColl?.valid ? 'validateDrodown' : ''}}">
                                <p-dropdown placeholder="Select Collision"
                                    [required]="dao?.vehicleModel?.vehicleDefault?.vehicleColl?.required"
                                    [options]="dao?.vehicleModel?.vehicleDefault?.vehicleColl?.questionOptions"
                                    name="editvehicleColl" id="ddvehicleCollID" appendTo="body"
                                    [(ngModel)]="dao.editAllUnits.vehicleColl.answer" #editvehicleColl="ngModel">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                    <!-- Broadened Collision -->
                    <div *ngIf="dao?.editAllUnits?.vehicleCollCoverage?.answer == 'Yes' && dao?.vehicleModel?.vehicleItem?.broadenedCollision?.enabled && dao?.editAllUnits?.Q_WhichCollCoverage?.answer == 'No'"
                        class="row mt-2 mb-2">
                        <div class="col-lg-6">
                            <label>{{dao?.vehicleModel?.vehicleItem?.broadenedCollision?.questionLabel}}</label>
                            <span class="question_icon">
                                <i class="fa fa-question-circle"
                                    pTooltip="This optional coverage, provided by CA 22 23 10 13, states that the Collision can be provided if the operator of the designated covered auto was not more than 50% at fault or the cause of the accident.  The coverage is available only on those vehicles for which the insured does not elect to purchase Collision Coverage. Depending on the type and age of the vehicle and the deductible requested, this additional coverage may result in a premium change for Collision Coverage for the designated vehicle.  The designated vehicle must be licensed or principally garaged in Michigan and listed on the endorsement."
                                    aria-hidden="true"></i>
                            </span>
                        </div>
                        <div class="col-lg-6">
                            <div
                                class="{{collisionDeductibleForm.submitted && dao?.vehicleModel?.vehicleDefault?.broadenedCollision?.required && !editbroadenedCollision?.valid ? 'validateDrodown' : ''}}">
                                <p-dropdown placeholder="Select Broadened Collision"
                                    [required]="dao?.vehicleModel?.vehicleDefault?.broadenedCollision?.required"
                                    [options]="dao?.vehicleModel?.vehicleDefault?.broadenedCollision?.questionOptions"
                                    name="editbroadenedCollision" id="ddbroadenedCollisionID" appendTo="body"
                                    [(ngModel)]="dao.editAllUnits.broadenedCollision.answer"
                                    #editbroadenedCollision="ngModel">
                                </p-dropdown>
                            </div>
                        </div>
                    </div>

                    <!-- Limited Collision -->
                    <div class="row mt-2 mb-2"
                        *ngIf="dao?.editAllUnits?.vehicleCollCoverage?.answer == 'No' && dao?.vehicleModel?.vehicleItem?.Q_limitedCollision?.enabled ">
                        <div class="col-lg-6">
                            <label name="lblQ_limitedCollision"
                                [ngClass]="collisionDeductibleForm.submitted && (dao.editAllUnits.Q_limitedCollision.answer == null || dao.editAllUnits.Q_limitedCollision.answer == '') ? 'validationred' : ''"
                                id="lblQ_limitedCollisionID">{{dao?.vehicleModel?.vehicleDefault?.Q_limitedCollision?.questionLabel}}</label>
                        </div>
                        <div class="col-lg-6">
                            <div class="p-field-radiobutton">
                                <p-radioButton name="editIsLCYes" id="editIsLCYesID" value="Yes" class="radio_button"
                                    [(ngModel)]="dao.editAllUnits.Q_limitedCollision.answer" required
                                    (onClick)="EditCollisionOnChange('Q_limitedCollision')">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="lblIsLCYes" id="lblIsLCYesID">Yes</label>
                                <p-radioButton name="editIsLCNo" id="editIsLCNoID" class="radio_button" value="No"
                                    [(ngModel)]="dao.editAllUnits.Q_limitedCollision.answer"
                                    (onClick)="EditCollisionOnChange('Q_limitedCollision')">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="lblIsLCNo" id="lblIsLCNoID">No</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="dao?.vehicleModel?.vehicleItem?.limitedCollision?.enabled && dao?.editAllUnits?.Q_limitedCollision?.answer == 'Yes'"
                        class="row mt-2 mb-2">
                        <div class="col-lg-6">
                            <label>{{dao?.vehicleModel?.vehicleItem?.limitedCollision?.questionLabel}}</label>
                        </div>
                        <div class="col-lg-6">
                            <div
                                class="{{collisionDeductibleForm.submitted && dao?.editAllUnits?.limitedCollision?.required && !editLimitedCollision?.valid ? 'validateDrodown' : ''}}">
                                <p-dropdown placeholder="Select Limited Collision"
                                    [options]="dao?.vehicleModel?.vehicleDefault?.limitedCollision?.questionOptions"
                                    name="editLimitedCollision" id="editlimitedCollisionID" appendTo="body"
                                    [(ngModel)]="dao.editAllUnits.limitedCollision.answer"
                                    #editLimitedCollision="ngModel" required>
                                </p-dropdown>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-0 m-0">
                        <div class="col-md-12 p-0">
                            <button type="submit" class="btnsuccess float-right" name="btnSaveCollAllUnits"
                                id="btnSaveCollAllUnitsID" title="Save" (click)="updateAllUnits()">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                Update All Units
                            </button>
                        </div>
                    </div>
                </form>
            </p-tabPanel>

            <!-- Garaging Information -->

            <p-tabPanel>
                <ng-template pTemplate="header">Garaging Information</ng-template>
                <form #garagingInformationForm="ngForm" style="width:100%">
                    <div class="row">
                        <div class="col-lg-12">
                            <h5 class="editUnitHeader">Update Garaging Information</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <label name="editlblvehicleIsCorrectGaragingAddress"
                                id="editlblvehicleIsCorrectGaragingAddressID"
                                [ngClass]="(garagingInformationForm.submitted && (dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == null || dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer == '')) ? 'validationred' : ''">
                                {{dao?.vehicleModel?.vehicleDefault?.vehicleIsCorrectGaragingAddress?.questionLabel}}</label>
                        </div>
                        <div class="col-lg-6">
                            <div class="p-field-radiobutton">
                                <p-radioButton name="editvehicleIsCorrectGaragingAddressYes"
                                    id="editradvehicleIsCorrectGaragingAddressYesID" value="Yes" class="radio_button"
                                    [(ngModel)]="dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="editlblvehicleIsCorrectGaragingAddressYes"
                                    id="editlblvehicleIsCorrectGaragingAddressYesID">Yes</label>
                                <p-radioButton name="editvehicleIsCorrectGaragingAddressNo"
                                    id="editradvehicleIsCorrectGaragingAddressNoID" class="radio_button" value="No"
                                    [(ngModel)]="dao.editAllUnits.vehicleIsCorrectGaragingAddress.answer">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="editlblvehicleIsCorrectGaragingAddressNo"
                                    id="editlblvehicleIsCorrectGaragingAddressNoID">No</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1" *ngIf="dao?.editAllUnits?.vehicleIsCorrectGaragingAddress?.answer == 'No'">
                        <div class="col-lg-6">
                            <label name="editlblvehiclegaragingaddress" id="editlblvehiclegaragingaddressID">Enter
                                this vehicle's garaging address</label>
                        </div>
                        <div class="col-lg-6">
                            <div>
                                <input
                                    class="{{(garagingInformationForm.submitted && (!editvehicleGarageStreetAddress?.valid || dao.editAllUnits.vehicleGarageStreetAddress.answer == null)) ? 'validate_error_cls' : ''}} form-control"
                                    [(ngModel)]="dao.editAllUnits.vehicleGarageStreetAddress.answer" type="text"
                                    name="edittxtvehicleGarageStreetAddress" id="edittxtvehiclegaragingaddressID"
                                    #editvehicleGarageStreetAddress="ngModel"
                                    [required]="dao?.vehicleModel?.vehicleDefault?.vehicleGarageStreetAddress?.required"
                                    placeholder="Enter Garaging Address"
                                    (keypress)="ValidateAddress($event,dao.editAllUnits.vehicleGarageStreetAddress.answer,'txtvehicleGarageStreetAddress')"
                                    maxlength="40">
                            </div>
                            <div class="row ml-0 mt-1" style="width: 100%;">
                                <div class="col-lg-4 pr-1 col-md-4 col-4 p-0">
                                    <input
                                        class="{{(garagingInformationForm.submitted && (!editvehicleGarageCity?.valid || dao.editAllUnits.vehicleGarageCity.answer == null)) ? 'validate_error_cls' : ''}} form-control"
                                        [(ngModel)]="dao.editAllUnits.vehicleGarageCity.answer" type="text"
                                        name="edittxtvehicleGarageCity" id="edittxtvehicleCityID"
                                        #editvehicleGarageCity="ngModel"
                                        [required]="dao?.vehicleModel?.vehicleDefault?.vehicleGarageCity?.required"
                                        placeholder="Enter City">
                                </div>
                                <div class="col-lg-4 pr-1 col-md-4 col-4 p-0">
                                    <input
                                        class="{{(garagingInformationForm.submitted && (!editvehicleGarageState?.valid || dao.editAllUnits.vehicleGarageState.answer == null)) ? 'validate_error_cls' : ''}} form-control"
                                        [(ngModel)]="dao.editAllUnits.vehicleGarageState.answer" type="text"
                                        name="edittxtvehicleGarageState" id="edittxtvehicleGarageStateID"
                                        #editvehicleGarageState="ngModel" readonly
                                        [required]="dao?.vehicleModel?.vehicleDefault?.vehicleGarageState?.required"
                                        placeholder="Enter State">
                                </div>
                                <div class="col-lg-4 pr-1 col-md-4 col-4 p-0">
                                    <div
                                        class="{{(garagingInformationForm.submitted && !editvehicleGaragePostalCode?.valid) ? 'validate_error_cls' : ''}}">
                                        <p-inputMask [(ngModel)]="dao.editAllUnits.vehicleGaragePostalCode.answer"
                                            mask="99999"
                                            [required]="dao?.vehicleModel?.vehicleDefault?.vehicleGaragePostalCode?.required"
                                            name="editvehicleGaragePostalCode" id="editvehicleGaragePostalCode"
                                            #editvehicleGaragePostalCode="ngModel" placeholder="Zip">
                                        </p-inputMask>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-0 m-2">
                        <div class="col-md-12 p-0">
                            <button type="submit" class="btnsuccess float-right" name="btnSaveGargingAllUnits"
                                id="btnSaveGargingAllUnitsID" title="Save"
                                (click)="garagingInformationForm.valid; updateAllUnits()">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                Update All Units
                            </button>
                        </div>
                    </div>
                </form>
            </p-tabPanel>

            <!-- Custom parts and equipments - Bulk Edit -->

            <p-tabPanel>
                <ng-template pTemplate="header">Custom parts and equipment</ng-template>
                <form #customPartsForm="ngForm" style="width:100%">
                    <div class="row">
                        <div class="col-lg-12">
                            <h5 class="editUnitHeader">Update Custom parts and equipment</h5>
                        </div>
                    </div>
                    <div class="row" *ngIf="dao.vehicleModel.vehicleDefault.vehicleCustomPartsParent.enabled">
                        <div class="col-lg-6">
                            <label
                                [ngClass]="(customPartsForm.submitted && (dao.editAllUnits.vehicleCustomPartsParent.answer == null || dao.editAllUnits.vehicleCustomPartsParent.answer == '')) ? 'validationred' : ''">
                                {{dao.vehicleModel.vehicleDefault.vehicleCustomPartsParent.questionLabel}}</label>
                        </div>
                        <div class="col-lg-6">
                            <div class="p-field-radiobutton">
                                <p-radioButton name="editVehicleCustomPartParentYes"
                                    id="editVehicleCustomPartParentYesID" value="Yes" class="radio_button"
                                    [(ngModel)]="dao.editAllUnits.vehicleCustomPartsParent.answer">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="editlblvehicleCustomPartsParentYes"
                                    id="editlblvehicleCustomPartsParentYesId">Yes</label>
                                <p-radioButton name="editVehicleCustomPartParentNo" id="editVehicleCustomPartParentNoID"
                                    class="radio_button" value="No"
                                    [(ngModel)]="dao.editAllUnits.vehicleCustomPartsParent.answer">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="editlblvehicleCustomPartsParentNo"
                                    id="editlblvehicleCustomPartsParentNoId">No</label>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-0 m-2">
                        <div class="col-md-12 p-0">
                            <button type="submit" class="btnsuccess float-right" name="btnSaveCustomPartsAllUnits"
                                id="btnSaveCustomPartsAllUnitsID" title="Save"
                                (click)="customPartsForm.valid; updateAllUnits()">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                Update All Units
                            </button>
                        </div>
                    </div>
                </form>
            </p-tabPanel>

            <!-- Additional Insured / Loss Payee - Bulk Edit -->

            <p-tabPanel>
                <ng-template pTemplate="header">Additional Insured / Loss Payee</ng-template>
                <form #insuredForm="ngForm" style="width:100%">
                    <div class="row">
                        <div class="col-lg-12">
                            <h5 class="editUnitHeader">Update Additional Insured / Loss Payee</h5>
                        </div>
                    </div>
                    <div class="row" *ngIf="!dao?.vehicleModel?.vehicleDefault?.vehicleAddInsuredOrLossPayee?.IsHidden">
                        <div class="col-lg-6">
                            <label
                                [ngClass]="(insuredForm.submitted && (dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer == null || dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer == '')) ? 'validationred' : ''">Does
                                this unit have an <b>additional insured or loss payee?</b></label>
                        </div>
                        <div class="col-lg-6">
                            <div class="p-field-radiobutton">
                                <p-radioButton name="editIsLossPayee"
                                    (onClick)="dao.editAllUnits.interestList = [];AddInterest('edit')"
                                    id="editradIsLossPayeeYesID" value="Yes" class="radio_button"
                                    [(ngModel)]="dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="editlblIsLossPayeeYes"
                                    id="editlblIsLossPayeeYesID">Yes</label>
                                <p-radioButton name="editIsLossPayee"
                                    (onClick)="dao.editAllUnits.interestList = [];AddInterest('edit')"
                                    id="radIsLossPayeeNo" class="radio_button" value="No"
                                    [(ngModel)]="dao.editAllUnits.vehicleAddInsuredOrLossPayee.answer">
                                </p-radioButton>
                                <label class="radio_button_lbl" name="editlblIsLossPayeeNo"
                                    id="editlblIsLossPayeeNoID">No</label>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="dao?.editAllUnits?.vehicleAddInsuredOrLossPayee?.answer == 'Yes'">
                        <div class="row {{i != 0 ? 'pt-3 interbor' : ''}}"
                            *ngFor="let interest of dao?.editAllUnits?.interestList; let i= index;">
                            <div class="col-lg-6">
                                <label>Select what type of interest:</label>
                                <span class="question_icon">
                                    <i class="fa fa-question-circle"
                                        pTooltip="Select the type of interest that will be entitled to receive insurance benefits and notices along with the insured. Additional insureds receive liability protection while loss payees receive only property damage coverage."
                                        aria-hidden="true"></i>
                                </span>
                            </div>
                            <div class="col-lg-6 {{i != 0 ? 'interesttype' : ''}}">
                                <div
                                    class="{{insuredForm.submitted && !editaddInsuredInterestType?.valid ? 'validateDrodown' : ''}}">
                                    <p-dropdown placeholder="Select Type"
                                        [options]="dao?.vehicleModel?.vehicleDefault?.addInsuredInterestType?.questionOptions"
                                        [required]="dao?.vehicleModel?.vehicleDefault?.addInsuredInterestType?.required"
                                        name="editaddInsuredInterestType_{{i}}" id="editaddInsuredInterestTypeID_{{i}}"
                                        [(ngModel)]="interest.Type" #editaddInsuredInterestType="ngModel">
                                    </p-dropdown>
                                    <span *ngIf="i != 0"><i class="fa fa-trash delete_icon ml-3" title="delete"
                                            name="editremove{{i}}" id="editremoveID{{i}}"
                                            (click)="remove(null,i,'removeEditInterest')"></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 mt-2">
                                <label>Enter the name:</label>
                            </div>
                            <div class="col-lg-6 mt-2">
                                <input
                                    class="{{insuredForm.submitted && (!editadddInsuredName?.valid || interest.Name == null) ? 'validate_error_cls' : ''}} form-control"
                                    [(ngModel)]="interest.Name" type="text"
                                    [required]="dao?.vehicleModel?.vehicleDefault?.addInsuredName?.required"
                                    name="editadddInsuredName_{{i}}" id="editadddInsuredNameID_{{i}}"
                                    #editadddInsuredName="ngModel" placeholder="Enter Name">
                            </div>
                            <div class="col-lg-6 mt-2">
                                <label>Address:</label>
                            </div>
                            <div class="col-lg-6 mt-2">
                                <input
                                    class="{{insuredForm.submitted && (!editaddInsuredStreetAddress?.valid || interest.streetAddress == null) ? 'validate_error_cls' : ''}} form-control"
                                    [(ngModel)]="interest.streetAddress" type="text" required
                                    name="editaddInsuredStreetAddress_{{i}}" name="editaddInsuredStreetAddressID_{{i}}"
                                    #editaddInsuredStreetAddress="ngModel" placeholder="Street Address" maxlength="40">
                                <div class="row ml-0 mt-2" style="margin-left: -14px !important;">
                                    <div class="col-lg-4 col-md-4 col-4" style="padding: 0px 13px !important">
                                        <input
                                            class="{{(insuredForm.submitted  && !editaddInsuredCity?.valid || interest.city == null) ? 'validate_error_cls' : ''}} form-control"
                                            [(ngModel)]="interest.city" type="text" required
                                            name="editaddInsuredCity_{{i}}" id="editaddInsuredCityID_{{i}}"
                                            #editaddInsuredCity="ngModel" placeholder="City">
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-4" style="padding: 0px 13px !important">
                                        <div
                                            class="{{insuredForm.submitted  && !editaddInsuredState?.valid ? 'validateDrodown' : ''}}">
                                            <p-dropdown placeholder="Select State"
                                                [options]="stateSettings.StateQuestionOptions" required
                                                name="editaddInsuredState_{{i}}" id="editaddInsuredStateID_{{i}}"
                                                [(ngModel)]="interest.state" #editaddInsuredState="ngModel" appendTo="body">
                                            </p-dropdown>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-4" style="padding: 0px 13px !important">
                                        <div
                                            class="{{insuredForm.submitted  && !editaddInsuredPostalCode?.valid ? 'validate_error_cls' : ''}}">
                                            <p-inputMask [(ngModel)]="interest.zip" mask="99999" required
                                                name="editaddInsuredPostalCode_{{i}}"
                                                id="editaddInsuredPostalCodeID_{{i}}"
                                                #editaddInsuredPostalCode="ngModel" placeholder="Zip">
                                            </p-inputMask>
                                        </div>
                                    </div>
                                </div>
                                <input
                                    class="{{insuredForm.submitted && !editaddInsuredLoanNumber?.valid ? 'validate_error_cls' : ''}} mt-2 form-control"
                                    [(ngModel)]="interest.loanNo" type="text"
                                    [required]="dao?.vehicleModel?.vehicleDefault?.addInsuredLoanNumber?.required"
                                    name="editaddInsuredLoanNumber_{{i}}" id="editaddInsuredLoanNumberID_{{i}}"
                                    #editaddInsuredLoanNumber="ngModel" placeholder="Loan Number (optional)">
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="text-right mb-3">
                                <a style="color: #115740;" title="Add Another Interest"
                                    (click)="AddInterest('edit')">Add
                                    Another
                                    Interest</a>
                            </div>
                        </div>

                    </div>

                    <div class="row mt-0 m-2">
                        <div class="col-md-12 p-0">
                            <button type="submit" class="btnsuccess float-right" name="btnSaveInsuredLossPayeeAllUnits"
                                id="btnSaveInsuredLossPayeeAllUnitsID" title="Save"
                                (click)="insuredForm.submitted = true;insuredForm.valid && updateAllUnits()">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                Update All Units
                            </button>
                        </div>
                    </div>
                </form>
            </p-tabPanel>
        </p-tabView>
    </p-dialog>

    <ng-template #BulkEditCheckbox>
        <div class="row"
            *ngIf="dao.vehicleModel.vehicleIndex == 0 && !dao.isTrailer && (dao.vehicleModel.VehiclesList.length > 1 || dao.vehicleModel.trailerList.length > 0)">
            <div class="col-lg-12 CopiedTextboxVehicle">
                <div class="row">
                    <div class="row" style="margin-left: 1rem;">
                        <h5>Select the checkbox for data to be copied to all units</h5>
                    </div>
                </div>
                <div class="col-lg-12" style="margin-top: -8px;">
                    <div class="row" style="margin-left: 1rem;">
                        <label>* Copy feature available with Unit 1 only</label>
                    </div>
                </div>
                <div class="col-lg-12" style="margin-top: -8px; margin-bottom: 2px;">
                    <div class="row" style="margin-left: 1rem;">
                        <label>* Use "Edit All Units" button for additional changes</label>
                    </div>
                </div>
                <!-- Radius -->
                <div class="row">
                    <div style="margin-left: 1rem;">
                        <p-checkbox name="bulkedit" id="bulkEditRadius" type="checkbox" binary="true" value="true"
                            [(ngModel)]="dao.VehicleBulkEditRadiusCheckbox" label="Radius Details"
                            [disabled]="dao.IsEditRadiusSaved === true && dao.VehicleBulkEditRadiusCheckbox == true"
                            (onChange)="dao.IsEditRadiusSaved = false;">
                        </p-checkbox>
                    </div>
                </div>
                <!-- Comprehensive deductible -->
                <div class="row">
                    <div style="margin-left: 1rem;"
                        *ngIf="dao?.vehicleModel?.vehicleItem?.vehicleCompOTCCoverage?.answer && !dao?.vehicleModel?.vehicleDefault?.vehicleComp?.IsHidden">
                        <p-checkbox name="bulkedit" id="bulkEditRa" type="checkbox" binary="true" value="true"
                            [(ngModel)]="dao.VehicleBulkEditCompCoverageCheckbox"
                            label="{{dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled ?'Comprehensive Coverage' : 'Comp/OTC and Collision Coverage'}}"
                            [disabled]="dao.IsEditCompDeductibleSaved === true && dao.VehicleBulkEditCompCoverageCheckbox == true"
                            (onChange)="dao.IsEditCompDeductibleSaved = false;">
                        </p-checkbox>
                    </div>
                </div>
                <!-- Collision Deductible -->
                <div class="row" *ngIf="dao?.vehicleModel?.vehicleDefault?.vehicleCollCoverage?.enabled">
                    <div style="margin-left: 1rem;"
                        *ngIf="(dao?.vehicleModel?.vehicleItem?.vehicleCollCoverage?.answer && !dao?.vehicleModel?.vehicleDefault?.vehicleColl?.IsHidden) || (dao?.vehicleModel?.vehicleItem?.broadenedCollision?.enabled && isShowCollisionCoverageCheckBox)">
                        <p-checkbox name="bulkedit" id="bulkEditRa" type="checkbox" binary="true" value="true"
                            [(ngModel)]="dao.VehicleBulkEditCollCoverageCheckbox" label="Collision Coverage"
                            [disabled]="dao.IsEditCollDeductibleSaved === true && dao.VehicleBulkEditCollCoverageCheckbox == true"
                            (onChange)="collisionDedCheckBoxChecked()">
                        </p-checkbox>
                    </div>
                </div>
                <!-- Garaging Information -->
                <div class="row">
                    <div style="margin-left: 1rem;"
                        *ngIf="dao.vehicleModel.vehicleItem.vehicleIsCorrectGaragingAddress.answer == 'No'">
                        <p-checkbox name="bulkedit" id="bulkEditRa" type="checkbox" binary="true" value="true"
                            [(ngModel)]="dao.VehicleBulkEditGaragingCheckbox" label="Garaging Information"
                            [disabled]="dao.IsEditGaragingSaved === true && dao.VehicleBulkEditGaragingCheckbox == true"
                            (onChange)="dao.IsEditGaragingSaved = false;">
                        </p-checkbox>
                    </div>
                </div>
                <!-- Custom parts and equipments -->
                <div class="row">
                    <div style="margin-left: 1rem;"
                        *ngIf="dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer == 'Yes' || dao.vehicleModel.vehicleItem.vehicleCustomPartsParent.answer == 'No'">
                        <p-checkbox name="bulkedit" id="bulkEditRa" type="checkbox" binary="true" value="true"
                            [(ngModel)]="dao.VehicleBulkEditCustomPartsCheckbox" label="Custom parts and equipment"
                            [disabled]="dao.IsEditCustomPartsSaved === true && dao.VehicleBulkEditCustomPartsCheckbox == true"
                            (onChange)="dao.IsEditCustomPartsSaved = false;">
                        </p-checkbox>
                    </div>
                </div>
                <!-- Additional Insure / Loss Payee -->
                <div class="row">
                    <div style="margin-left: 1rem;"
                        *ngIf="dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer == 'Yes' || dao.vehicleModel.vehicleItem.vehicleAddInsuredOrLossPayee.answer == 'No'">
                        <p-checkbox name="bulkedit" id="bulkEditRa" type="checkbox" binary="true" value="true"
                            [(ngModel)]="dao.VehicleBulkEditInsuredLossPayeeCheckbox"
                            label="Additional Insured / Loss Payee"
                            [disabled]="dao.IsEditinsuredLossPayeeSaved === true && dao.VehicleBulkEditInsuredLossPayeeCheckbox == true"
                            (onChange)="dao.IsEditinsuredLossPayeeSaved = false;">
                        </p-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <p-toast position="center" key="ok" (onClose)="onReject('ok')" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1; padding: 3px;;">
                <div class="p-text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <div [innerHTML]="message.summary">{{message.summary}}</div><br />

                    <div class="p-grid p-fluid">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-6">
                            <button type="button" class="warnBtnSuccess" title="OK" name="btnok" id="btnokID"
                                (click)="onReject('ok')">
                                <i class="fa fa-thumbs-up" aria-hidden="true" tooltip="OK"></i>
                                OK
                            </button>
                        </div>
                        <div class="col-sm-3"></div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
    <p-toast position="center" key="vehicle_age_validation" class="validation_toast" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class="p-flex p-flex-column" style="flex: 1">
                <div class="p-text-center">
                    {{message?.data}}
                </div>
                <div class="p-grid p-fluid mt-2">
                    <div class="p-col-6">
                        <button type="button" name="btnPopContinue" id="btnPopContinueID"
                            class="warnBtnSuccess float-right" (click)="skipVehicleAgeValidation()" title="Continue">
                            <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                            Continue
                        </button>
                    </div>
                    <div class="p-col-6">
                        <button type="button"
                            class="warnBtnerror" name="btnPopCancel" id="btnPopCancelID" (click)="cancel()" title="Cancel">
                            <i class="fa fa-thumbs-down" aria-hidden="true"></i>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>
</div>
